import React from 'react';
import { DialogContent } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            width: 600,
            maxWidth: 600,
        },
    },
    prompt: {
        display: 'flex',
        justifyContent: 'center',
        '& > *': {
            textAlign: 'justify',
        },
    },
    view: {
        margin: 0,
        padding: '0 !important',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            maxWidth: 'unset',
        },
    },
}));

export type ModalContentProps = React.PropsWithChildren<{
    type?: 'dialog' | 'prompt' | 'view';
}>;

const ModalContent = ({ children, type = 'dialog' }: ModalContentProps) => {
    const { classes, cx } = useStyles();

    const dialogClasses = cx({
        [classes.dialog]: true,
        [classes.prompt]: type === 'prompt',
        [classes.view]: type === 'view',
    });

    return <DialogContent className={dialogClasses}>{children}</DialogContent>;
};

export default ModalContent;
