import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { useNavigate } from 'react-router-dom';

import useAdminPanelApi from '../modules/dependency-injection/hooks/use-admin-panel-api';

const useIsBackendUp = () => {
    const navigate = useNavigate();
    const { info } = useAdminPanelApi();

    const [isBackendUp, setIsBackendUp] = useState<boolean>(false);

    const check = useAsyncAbortable<string>(
        async () => {
            const request = info.getHeartbeat();

            // To make this work correctly, as in example
            // https://github.com/ferdikoomen/openapi-typescript-codegen/blob/master/docs/canceling-requests.md
            // the library auth needs to actually expose the methods..
            // https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1102
            // setTimeout(() => {
            //     if (!request.isResolved() && !request.isRejected()) {
            //         request.cancel();
            //         navigate('/error/pending-connection');
            //     }
            // }, 5000);

            return request;
        },
        [info],
        {
            onError: () => navigate('/error/api-error'),
            onSuccess: () => setIsBackendUp(true),
        }
    );

    return {
        loading: check.loading,
        status: isBackendUp,
    };
};

export default useIsBackendUp;
