import { Box, Typography } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../components/tag/tag';

export type UserTypeProps = {
    user: UserDto;
};

const UserType = ({ user }: UserTypeProps) => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Typography sx={{ marginRight: (user.externalProfile && '4px') || undefined }}>{user.type}</Typography>

            {user.externalProfile && <Tag text="External IDP" type="DEFAULT" />}
        </Box>
    );
};

export default UserType;
