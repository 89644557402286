import { useContext, useMemo } from 'react';

import DependencyInjectionContext from '../components/dependency-injection-context';

const useDependencyInjection = () => {
    const context = useContext(DependencyInjectionContext);

    if (context === undefined) {
        throw new Error('useDependencyInjection is only usable within DependencyInjectionProvider');
    }

    return useMemo(() => context, [context]);
};

export default useDependencyInjection;
