import { Typography } from '@mui/material';
import { PlayerBatchDto, PlayerBatchReportDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';

import AuthorInformation from '../../../../components/author-information/author-information';

export type PlayerBatchReportHeaderProps = {
    playerBatch: PlayerBatchDto;
    report: PlayerBatchReportDto;
};

const PlayerBatchReportHeader = ({ playerBatch, report }: PlayerBatchReportHeaderProps) => {
    const { workspace } = useWorkspace();

    return (
        <div data-testid="player-batch-report-header">
            <Typography variant="h2">{report.customerName}</Typography>
            <Typography>Project name: {report.name || '-'}</Typography>
            <Typography>Workspace: {workspace.name}</Typography>

            <AuthorInformation<PlayerBatchDto> item={playerBatch} sx={{ marginTop: '12px' }} textSx={{ opacity: 1 }} />
        </div>
    );
};

export default PlayerBatchReportHeader;
