import { Box, Typography } from '@mui/material';

import Tag from '../../../components/tag/tag';

export type UserProfileHeaderProps = {
    externalProfile?: boolean;
};

const UserProfileHeader = ({ externalProfile }: UserProfileHeaderProps) => {
    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ marginRight: '8px' }} variant="h5">
                Profile
            </Typography>

            {externalProfile && <Tag text="External IDP" type="DEFAULT" />}
        </Box>
    );
};

export default UserProfileHeader;
