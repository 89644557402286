import { memo, useCallback, useMemo } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Box, Typography } from '@mui/material';
import {
    BatchPlayerDto,
    CreateBatchPlayerDto,
    CreatePlayerBatchDto,
    EditPlayerBatchDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { ActionGroup, ContextMenu, Icon } from '@zetadisplay/zeta-ui-components';
import { colors } from '@zetadisplay/zeta-ui-components/utils/theme/theme-options';

import { DEFAULT_PLAYER } from '../constants';
import isEditBatchPlayerDto from '../utils/is-edit-batch-player-dto';
import RemovePlayerFromBatchPrompt from './prompts/remove-player-from-batch-prompt';

export type PlayerBatchPlayerHeaderProps<
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
> = {
    addPlayer: (position?: number, player?: CreateBatchPlayerDto) => void;
    index: number;
    player: T;
    removePlayer: UseFieldArrayRemove;
};

const PlayerBatchPlayerHeader = <
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
>({
    addPlayer,
    index,
    player,
    removePlayer,
}: PlayerBatchPlayerHeaderProps<T>) => {
    const name = player.name || `Player ${index + 1}`;
    const title = (player.name && `#${index + 1} ${player.name}`) || `#${index + 1}`;

    const { watch } = useFormContext();

    const failed = player?.status === BatchPlayerDto.status.FAILED;
    const executed = player?.status === BatchPlayerDto.status.EXECUTED;

    const messages = (isEditBatchPlayerDto(player) && player.messages) || [];

    const duplicatePlayer = useCallback(
        (duplicatedPlayerIndex: number) => {
            const data = watch(`batchPlayers.${duplicatedPlayerIndex}`);

            addPlayer(index + 1, {
                ...DEFAULT_PLAYER,
                height: data.height,
                labels: data.labels,
                networkGroup: data.networkGroup,
                width: data.width,
            });
        },
        [addPlayer, index, watch]
    );

    const actions: ActionGroup<T>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => duplicatePlayer(index),
                        label: 'Duplicate',
                        name: 'duplicate',
                    },
                    {
                        handler: () =>
                            NiceModal.show(RemovePlayerFromBatchPrompt, {
                                index,
                                name,
                                removePlayer,
                            }),
                        isDisabled: () => executed,
                        label: 'Remove',
                        name: 'remove',
                    },
                ],
                name: 'player-actions',
            },
        ];
    }, [duplicatePlayer, executed, index, name, removePlayer]);

    return (
        <Box
            component="div"
            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
            data-testid={`player-batch-header-${index}`}
        >
            {(executed || failed) && (
                <Icon
                    color={(failed && colors.ERROR) || colors.SUCCESS}
                    title={(failed && messages?.map((message) => message.message).join(', ')) || 'Executed'}
                    size={18}
                    type={(failed && 'ERROR') || 'SUCCESS'}
                />
            )}

            <Typography
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    padding: ((executed || failed) && '0 4px') || undefined,
                }}
            >
                {title}
            </Typography>

            <ContextMenu actions={actions} horizontal item={player} placement="bottom" />
        </Box>
    );
};

export default memo(PlayerBatchPlayerHeader);
