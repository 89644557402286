import React, { useCallback, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Share } from '@styled-icons/material';
import { ConfigModelsWorkspaceBasic, LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { TemplatesApiShareTemplateRequest } from '@zetadisplay/engage-api-client/api/templates-api';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi } from '@zetadisplay/engage-components/modules/api';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { getTemplateShares } from '@zetadisplay/engage-components/modules/templates/api';
import { useWorkspace, WorkspaceInput } from '@zetadisplay/engage-components/modules/workspaces';
import { handleResponseError } from '@zetadisplay/engage-components/utils/response';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';
import { AxiosError } from 'axios';

import { ListActionOptions } from '../../../../../components/types';
import Workspaces from './workspaces';

export type FormType = Pick<TemplatesApiShareTemplateRequest, 'templateid' | 'workspaceid'> & {
    body: (ConfigModelsWorkspaceBasic | null)[] | null | undefined;
};

export type ShareTemplateModalProps = {
    options: ListActionOptions;
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
} & NiceModalHocProps;

const ShareTemplateModal = NiceModal.create<ShareTemplateModalProps>(({ options, template }) => {
    const api = useApi();
    const { workspace } = useWorkspace();
    const theme: ZetaTheme = useTheme();
    const modal = useModal();
    const [initialShares, setInitialShares] = useState<ConfigModelsWorkspaceBasic[]>([]);

    const methods = useForm<FormType>({
        defaultValues: async () => {
            const shares = await getTemplateShares(template, api);

            setInitialShares(shares.workspaces || []);

            return {
                templateid: template.id,
                workspaceid: template.workspace?.id || '',
                body: shares.workspaces,
            };
        },
    });
    const { formState, control } = methods;
    const { isLoading, isSubmitting } = formState;
    const { fields, append, remove, update } = useFieldArray({
        name: 'body',
        control,
        keyName: 'key',
    });

    const addWorkspace = useCallback(() => {
        append(null);
    }, [append]);

    const onSubmit = useCallback(
        async (data: FormType) => {
            const body: string[] =
                data.body?.filter((item): item is ConfigModelsWorkspaceBasic => item !== null).map(({ id }) => id) ||
                [];

            try {
                await api.templates.shareTemplate({
                    templateid: data.templateid,
                    workspaceid: data.workspaceid,
                    body,
                });

                let message = 'Template has been shared to workspace(s) successfully';

                const unsharedWorkspaces = initialShares.filter((item) => !body.includes(item.id)).map(({ id }) => id);

                if (unsharedWorkspaces.length > 0) {
                    await api.templates.unShareTemplate({
                        templateid: data.templateid,
                        workspaceid: data.workspaceid,
                        body: unsharedWorkspaces,
                    });
                    message = 'Template has been shared to and removed from workspace(s) successfully';
                }

                options.notify(message, [], 'success');

                modal.resolve();
                modal.hide();
            } catch (e) {
                options.notify(handleResponseError(e as AxiosError), [], 'error');
            }
        },
        [api.templates, initialShares, modal, options]
    );

    return (
        <Modal
            actions={{
                buttons: createDefaultButtons({
                    cancel: {
                        disabled: isSubmitting || isLoading,
                        onClick: modal.hide,
                    },
                    submit: {
                        busy: isSubmitting,
                        onClick: methods.handleSubmit(onSubmit),
                        disabled: isSubmitting || isLoading,
                        label: 'Share',
                    },
                }),
            }}
            dark={theme.palette.dark}
            name="share-template"
            title={{ icon: <Share size={24} />, label: 'Template sharing configuration' }}
        >
            {isLoading && <ComponentLoader />}

            {!isLoading && (
                <FormProvider {...methods}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <WorkspaceInput
                                defaultWorkspaces={[workspace]}
                                disabled
                                includeRootWorkspace
                                label="Root workspace"
                                name="externalEntityId"
                                multiple={false}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Workspaces
                                fields={fields}
                                onAddWorkspace={addWorkspace}
                                onRemove={remove}
                                onUpdate={update}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            )}
        </Modal>
    );
});

export default ShareTemplateModal;
