import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { CustomerDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import buildCustomerAbilities from '../customers/auth/build-customer-abilities';
import { buildNewHierarchy } from '../customers/customer-configuration/helper/hierarchy.util';
import getEngageTemplateAbilities from '../engage/engage-templates/auth/get-engage-template-abilities';
import buildUserAbilities from '../users/auth/build-user-abilities';
import getAutomatedUserCreationAbilities from '../users/automated-user-creation/auth/get-automated-user-creation-abilities';
import { AppAbilityType } from './authorization';

export default function defineRulesFor(
    user: UserDto | undefined,
    customers: CustomerDto[] | undefined,
    users: UserDto[] | undefined,
    limitedFeatures: boolean
) {
    const abilityBuilder = new AbilityBuilder<AppAbilityType>(createMongoAbility);
    const { can, rules } = abilityBuilder;

    if (!user) {
        return rules;
    }

    if (users && customers) {
        const customerTreeNodes = buildNewHierarchy(customers);

        // Build Customer Abilities and all other abilities that are based on the customer context
        // TODO Get rid of Customer Application Abilities requirement on Customer context, we could simply send and return the applications as part of the DTO's
        buildCustomerAbilities(user, customerTreeNodes, abilityBuilder, limitedFeatures);

        buildUserAbilities(user, users, customerTreeNodes, abilityBuilder, limitedFeatures);
    }

    const templatesAbility = getEngageTemplateAbilities(limitedFeatures, user);
    const uacAbility = getAutomatedUserCreationAbilities(user);

    if (templatesAbility) {
        can(templatesAbility, 'Templates-Engage');
    }

    if (uacAbility) {
        can(uacAbility, 'UAC');
    }

    return rules;
}

export function buildAbilityFor(
    user: UserDto | undefined,
    customers: CustomerDto[] | undefined,
    users: UserDto[] | undefined,
    limitedFeatures = false
): AppAbilityType {
    // Get rules
    const rules = defineRulesFor(user, customers, users, limitedFeatures);

    // Create a builder that can generate checks
    const builder = new AbilityBuilder<AppAbilityType>(createMongoAbility);
    // Build rule-set
    rules.forEach((e) => {
        if (!e.inverted) {
            builder.can(e.action, e.subject, e.fields);
        } else {
            builder.cannot(e.action, e.subject, e.fields);
        }
    });
    // Build checks
    return builder.build();
}
