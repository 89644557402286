import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Link, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import Action from '../../../modules/authorization/action';
import { Subject } from '../../../modules/authorization/authorization';

const useStyles = makeStyles()((theme) => ({
    root: {
        paddingLeft: 12,
        color: theme.palette.text.primary,
        ':hover': {
            color: theme.palette.primary.main,
        },
    },
    'root--disabled': { color: theme.palette.text.disabled, pointerEvents: 'none' },
    title: { paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 4 },
    'level-main': { height: 40, lineHeight: '40px', paddingBottom: 0, paddingLeft: 24 },
}));

export type NavigationLinkType = {
    auth?: [Action, Subject];
    disabled?: boolean;
    href: string;
    text: string;
};

export type NavigationLinkProps = {
    level?: 'main';
    link: NavigationLinkType;
};

const NavigationLink = ({ level, link }: NavigationLinkProps) => {
    const { classes, cx } = useStyles();
    const theme: ZetaTheme = useTheme();

    return (
        <ListItem className={cx({ [classes.title]: true, [classes['level-main']]: level === 'main' })}>
            <Link
                href={link.href}
                to={link.href}
                component={RouterLink}
                className={cx({
                    [classes.root]: true,
                    [classes['root--disabled']]: link?.disabled,
                })}
                sx={{
                    '&.active': {
                        color: theme.palette.primary.main,
                    },
                }}
            >
                {link.text}
            </Link>
        </ListItem>
    );
};
export default NavigationLink;
