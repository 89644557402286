import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { ConfigModelsAttributeValueDetails } from '@zetadisplay/engage-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { Workspace } from '@zetadisplay/engage-components/models';
import { ApiInterface as EngageApiInterface } from '@zetadisplay/engage-components/modules/api';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { handleResponseError as handleEngageResponseError } from '@zetadisplay/engage-components/utils/response';
import { AxiosError } from 'axios';

import LabelValueForm from '../label-value-form/label-value-form';

export type LabelValueFormModalProps = {
    api: EngageApiInterface;
    labelId: string | number;
    workspace: Workspace;
} & NiceModalHocProps;

const LabelValueFormModal = NiceModal.create(({ api, labelId, workspace }: LabelValueFormModalProps) => {
    const methods = useForm<ConfigModelsAttributeValueDetails>();
    const modal = useModal();
    const notify = useTranslatedSnackbar();

    const onSubmit = useCallback(
        async (formValues: ConfigModelsAttributeValueDetails) => {
            try {
                const response = await api.attributes.createAttributeValue({
                    workspaceid: workspace.id,
                    attributeid: typeof labelId === 'string' ? parseInt(labelId, 10) : labelId,
                    body: formValues,
                });

                notify(`Value "${formValues.value}" has been created`, [], 'success');

                modal.resolve(response);
                modal.hide();
            } catch (e) {
                if (e instanceof AxiosError) {
                    notify(handleEngageResponseError(e), [], 'error');
                } else {
                    throw e;
                }
            }
        },
        [api, labelId, modal, notify, workspace.id]
    );

    return (
        <Modal
            actions={{
                buttons: createDefaultButtons({
                    cancel: {
                        disabled: methods.formState.isSubmitting,
                        onClick: modal.hide,
                    },
                    submit: {
                        busy: methods.formState.isSubmitting,
                        disabled: methods.formState.isSubmitting,
                        onClick: methods.handleSubmit(onSubmit),
                    },
                }),
            }}
            dark
            name="label-value-form"
            title={{ label: 'Create new label value' }}
        >
            <FormProvider {...methods}>
                <LabelValueForm />
            </FormProvider>
        </Modal>
    );
});

export default LabelValueFormModal;
