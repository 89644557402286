import React from 'react';
import { Controller, FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Grid } from '@mui/material';
import {
    BatchPlayerDto,
    CreateBatchPlayerDto,
    CreatePlayerBatchDto,
    EditPlayerBatchDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import {
    ConfigModelsAttributeDetails,
    EndpointsRootNetworkModelsNetworkGroupInfo,
    NetworkModelsAvailablePlayerSubscription,
} from '@zetadisplay/engage-api-client';
import { TextInput } from '@zetadisplay/engage-components';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components/form-provider-wrapper';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { NetworkGroupInput } from '@zetadisplay/engage-components/modules/players/components';
import { Accordion, Alert } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { dequal } from 'dequal';

import { COLUMN_GRID_ITEM_PROPS } from '../constants';
import isEditBatchPlayerDto from '../utils/is-edit-batch-player-dto';
import PlayerBatchPlayerHeader from './player-batch-player-header';
import PlayerLabels from './player-labels';
import PlayerResolution from './player-resolution';
import PlayerSubscription from './player-subscription';

const useStyles = makeStyles()(() => ({
    fields: {
        marginTop: 6,
    },
}));

export type PlayerBatchPlayerProps<
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
> = {
    addPlayer: (position?: number, player?: CreateBatchPlayerDto) => void;
    index: number;
    networkGroups: DiscriminatedEntity<EndpointsRootNetworkModelsNetworkGroupInfo>[];
    onLicenseChange: (index: number, license: string) => void;
    player: T;
    removePlayer: UseFieldArrayRemove;
    reservedSubscriptions: Record<number, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>;
    subscriptions: NetworkModelsAvailablePlayerSubscription[] | undefined;
    workspaceLabels: DiscriminatedEntity<ConfigModelsAttributeDetails>[];
};

const PlayerBatchPlayer = <
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
>({
    addPlayer,
    index,
    networkGroups,
    onLicenseChange,
    player,
    removePlayer,
    reservedSubscriptions,
    setValue,
    subscriptions,
    workspaceLabels,
}: PlayerBatchPlayerProps<T>) => {
    const { classes } = useStyles();

    const defaultNetworkGroup = networkGroups.find((group) => group.id === player.networkGroup);

    const executed = player.status === BatchPlayerDto.status.EXECUTED;
    const messages = (isEditBatchPlayerDto(player) && player.messages) || [];

    return (
        <Accordion
            defaultExpanded={!isEditBatchPlayerDto(player)}
            renderSummary={() => (
                <PlayerBatchPlayerHeader
                    addPlayer={addPlayer}
                    index={index}
                    player={player}
                    removePlayer={removePlayer}
                />
            )}
        >
            {player.status === BatchPlayerDto.status.FAILED && messages.length > 0 && (
                <Alert messages={messages.map((item) => item.message)} severity="error" />
            )}

            <div className={classes.fields} data-testid={`player-batch-${index}`}>
                <Grid container spacing={3}>
                    {/* First column */}
                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextInput
                                    disabled={executed}
                                    defaultLabel="Player name"
                                    defaultValue={player.name || ''}
                                    name={`batchPlayers.${index}.name`}
                                    required
                                    rules={{
                                        required: 'common.validation.required',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    defaultValue={player.networkGroup || null}
                                    name={`batchPlayers.${index}.networkGroup`}
                                    render={({ field: { onChange } }) => (
                                        // Extra wrapper to isolate field from main form
                                        // Not using the input directly as our DTO's require just the id number
                                        <FormProviderWrapper>
                                            <NetworkGroupInput
                                                defaultValue={defaultNetworkGroup}
                                                disabled={executed}
                                                name="wrapped-input"
                                                onChangeCallback={(group) => onChange(group?.id || null)}
                                                values={networkGroups}
                                            />
                                        </FormProviderWrapper>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Second column */}
                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <Grid container spacing={2}>
                            <PlayerResolution<T> index={index} player={player} setValue={setValue} />

                            <Grid item xs={12}>
                                <PlayerSubscription
                                    disabled={executed}
                                    index={index}
                                    onLicenseChange={onLicenseChange}
                                    reservedSubscriptions={reservedSubscriptions}
                                    subscriptions={subscriptions}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Third column */}
                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <PlayerLabels disabled={executed} index={index} workspaceLabels={workspaceLabels} />
                    </Grid>
                </Grid>
            </div>
        </Accordion>
    );
};

export default React.memo(PlayerBatchPlayer, dequal);
