import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TextInput } from '@zetadisplay/engage-components';
import { RolesInput } from '@zetadisplay/engage-components/modules/users/components';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

const { MEDIUMGRAY, ORANGE } = themeOptions.colors;

type UacConfigurationEngageRoleProps = {
    canRemove: boolean;
    enabled: boolean;
    index: number;
    onRemove: UseFieldArrayRemove;
};

const UacConfigurationEngageRole = ({ canRemove, enabled, index, onRemove }: UacConfigurationEngageRoleProps) => {
    const theme: ZetaTheme = useTheme();

    return (
        <>
            <Grid item xs={5} data-testid={`engage-role--${index}--value`}>
                <TextInput
                    defaultLabel="Value"
                    disabled={!enabled}
                    name={`engageRoles.${index}.value`}
                    required={enabled}
                    rules={{
                        required: (enabled && 'common.validation.required') || undefined,
                    }}
                />
            </Grid>

            <Grid item xs={5} data-testid={`engage-role--${index}--role`}>
                <RolesInput
                    disabled={!enabled}
                    LabelProps={{
                        backgroundColor: (theme.palette.dark && theme.palette.background.content) || undefined,
                    }}
                    name={`engageRoles.${index}.roleId`}
                    required={enabled}
                />
            </Grid>

            <Grid item xs={2}>
                <IconButton
                    data-testid={`remove-engage-role--${index}`}
                    disabled={!canRemove}
                    onClick={() => onRemove(index)}
                >
                    <Icon color={(!canRemove && MEDIUMGRAY) || ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </>
    );
};

export default UacConfigurationEngageRole;
