import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { RoleDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput, SelectOption, TextInput } from '@zetadisplay/engage-components';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { MEDIUMGRAY, ORANGE } = themeOptions.colors;

type UacConfigurationAdminPanelRoleProps = {
    canRemove: boolean;
    enabled: boolean;
    index: number;
    loading: boolean;
    onRemove: UseFieldArrayRemove;
    roles: RoleDto[] | undefined;
};

const UacConfigurationAdminPanelRole = ({
    canRemove,
    enabled,
    index,
    loading,
    onRemove,
    roles,
}: UacConfigurationAdminPanelRoleProps) => {
    const roleOptions: SelectOption[] =
        roles?.map((role) => ({
            id: role.id,
            name: role.name,
            value: role.id,
        })) || [];

    return (
        <>
            <Grid item xs={5} data-testid={`admin-panel-roles--${index}--value`}>
                <TextInput
                    defaultLabel="Value"
                    disabled={!enabled}
                    name={`adminPanelRoles.${index}.value`}
                    required={enabled}
                    rules={{
                        required: (enabled && 'common.validation.required') || undefined,
                    }}
                />
            </Grid>

            <Grid item xs={5} data-testid={`admin-panel-roles--${index}--role`}>
                <SelectInput
                    disabled={!enabled}
                    fullWidth
                    name={`adminPanelRoles.${index}.role`}
                    label="Role"
                    loading={loading || roleOptions.length === 0}
                    options={roleOptions}
                    required={enabled}
                />
            </Grid>

            <Grid item xs={2}>
                <IconButton
                    data-testid={`remove-admin-panel-role--${index}`}
                    disabled={!canRemove}
                    onClick={() => onRemove(index)}
                >
                    <Icon color={(!canRemove && MEDIUMGRAY) || ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </>
    );
};

export default UacConfigurationAdminPanelRole;
