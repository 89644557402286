import { useAsyncAbortable } from 'react-async-hook';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';

const useUser = (id?: string) => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    return useAsyncAbortable<UserDto | undefined>(
        async () => {
            if (!id) {
                return undefined;
            }

            return api.users.getUserById(id).then((data) => {
                return data;
            });
        },
        [api.users, id],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
        }
    );
};

export default useUser;
