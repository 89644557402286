import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { NetworkModelsPlayersPlayerLocation } from '@zetadisplay/engage-api-client';
import { SelectInput, TextInput } from '@zetadisplay/engage-components';
import { useCountries, useTimeZones } from '@zetadisplay/engage-components/hooks';
import { LocationInput } from '@zetadisplay/engage-components/modules/players/components';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

export type PlayerLocationProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    updateLocationInputs: (arg: NetworkModelsPlayersPlayerLocation) => void;
    values: LocationDto;
};

const PlayerLocation = ({ control, updateLocationInputs, values }: PlayerLocationProps) => {
    const t = useTranslation();
    const theme: ZetaTheme = useTheme();

    const countries = useCountries();
    const timezones = useTimeZones();

    const location = useWatch({ control, name: 'location' });

    const readOnly = (location?.id > 0 && true) || false;

    return (
        <Grid container spacing={2} data-testid="player-location">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px' }} variant="h5">
                    Location
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <LocationInput
                    control={control}
                    name="location"
                    defaultValue={values || undefined}
                    onChangeCallback={updateLocationInputs}
                    placeholder="Search for existing location"
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Zipcode"
                            defaultValue={values?.zipcode || ''}
                            disabled={readOnly}
                            name="location.zipcode"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="City"
                            defaultValue={values?.city || ''}
                            disabled={readOnly}
                            name="location.city"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <SelectInput
                    control={control}
                    defaultValue={values?.countryId || ''}
                    disabled={!countries.result || readOnly}
                    fullWidth
                    label={t.trans('common.form.input.country')}
                    LabelProps={{
                        backgroundColor: theme.palette.background.content,
                    }}
                    loading={countries.loading}
                    name="location.countryId"
                    options={
                        countries?.result?.items.map((value) => ({
                            id: value.id,
                            name: value.name ?? '',
                        })) || []
                    }
                    renderSelectItem
                    required
                    rules={{
                        required: 'common.validation.required',
                        min: 1,
                    }}
                    selectItemValue={0}
                    testId="select-country"
                />
            </Grid>

            <Grid item xs={12}>
                <SelectInput
                    control={control}
                    defaultValue={values?.timezoneId || ''}
                    disabled={!timezones.result}
                    fullWidth
                    label={t.trans('common.form.input.timezone')}
                    LabelProps={{
                        backgroundColor: theme.palette.background.content,
                    }}
                    loading={timezones.loading}
                    name="location.timezoneId"
                    options={
                        timezones?.result?.items
                            .sort((left, right) => {
                                if (left.bias < right.bias) {
                                    return 1;
                                }

                                if (left.bias > right.bias) {
                                    return -1;
                                }

                                return 0;
                            })
                            .map((value) => ({
                                id: value.id,
                                name: value.daylightName ?? '',
                            })) || []
                    }
                    renderSelectItem
                    required
                    rules={{
                        required: 'common.validation.required',
                        min: 1,
                    }}
                    selectItemValue={0}
                    testId="select-timezone"
                />
            </Grid>
        </Grid>
    );
};

export default PlayerLocation;
