import React, { useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';

import createUacApi from '../utils/create-uac-api';
import AutomatedUserCreationContext from './automated-user-creation-context';

type Props = React.PropsWithChildren<{
    accessToken?: string;
    apiConfig: OpenAPIConfig;
}>;

const AutomatedUserCreationProvider = ({ accessToken, apiConfig, children }: Props) => {
    const { authState } = useOktaAuth();

    const values = useMemo(
        () => ({
            api: createUacApi({ ...apiConfig, TOKEN: accessToken || authState?.accessToken?.accessToken }),
        }),
        [accessToken, apiConfig, authState?.accessToken?.accessToken]
    );

    return <AutomatedUserCreationContext.Provider value={values}>{children}</AutomatedUserCreationContext.Provider>;
};

export default AutomatedUserCreationProvider;
