import { Grid } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { Accordion } from '@zetadisplay/zeta-ui-components';

type Props = {
    edit: boolean;
};
const UacConfigurationProfile = ({ edit }: Props) => {
    return (
        <Accordion defaultExpanded expandable={false} name="uac-configuration-profile" title="Profile">
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <TextInput
                        defaultLabel="Name"
                        disabled={edit}
                        name="id"
                        required
                        rules={{
                            required: 'common.validation.required',
                        }}
                    />
                </Grid>
            </Grid>
        </Accordion>
    );
};

export default UacConfigurationProfile;
