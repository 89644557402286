import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { Delete, Edit } from '@styled-icons/material';
import { MetaScriptContainerC } from '@zetadisplay/connect-userautocreation-api-client/models/MetaScriptContainerC';
import { ActionGroup, ContextMenu } from '@zetadisplay/zeta-ui-components';

import { ListActionOptions } from '../../../../components/types';
import { ACCESS_CONFIGURATION_BASE_PATH, AUTOMATED_USER_CREATION_PATH } from '../../../../routing/constants';
import DeleteUacPrompt from './prompts/delete-uac-prompt';

type Props = {
    item: MetaScriptContainerC;
    options: ListActionOptions;
};

const UacListActions = ({ item, options }: Props) => {
    const history = useNavigate();

    const actions: ActionGroup<MetaScriptContainerC>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () =>
                            history(
                                `/${ACCESS_CONFIGURATION_BASE_PATH}/${AUTOMATED_USER_CREATION_PATH}/${item.id}/edit`
                            ),
                        icon: Edit,
                        label: 'Edit',
                        name: 'edit-uac-configuration',
                    },
                    {
                        handler: () =>
                            NiceModal.show(DeleteUacPrompt, {
                                script: item,
                                options,
                            }),
                        icon: Delete,
                        label: 'Delete',
                        name: 'delete-uac-configuration',
                    },
                ],
                label: 'Actions',
                name: 'actions',
            },
        ];
    }, [history, item, options]);

    return (
        <Box component="div" sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center' }}>
            <ContextMenu<MetaScriptContainerC> actions={actions} item={item} placement="bottom" />
        </Box>
    );
};

export default UacListActions;
