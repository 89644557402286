import { useAsyncAbortable } from 'react-async-hook';
import { RoleDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import getCustomerRoles from '../api/get-customer-roles';

export type UseCustomerRolesReturnType = {
    data: RoleDto[] | undefined;
    isLoading: boolean;
};

const useCustomerRoles = (customerId?: string): UseCustomerRolesReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const roles = useAsyncAbortable<RoleDto[] | undefined>(
        async () => {
            if (!customerId) {
                return undefined;
            }

            return getCustomerRoles(customerId, api);
        },
        [api, customerId],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    return {
        data: roles.result,
        isLoading: roles.loading,
    };
};

export default useCustomerRoles;
