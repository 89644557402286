import { useCallback, useEffect, useMemo, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { ApplicationDto, UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';
import { useInterval } from 'usehooks-ts';

import { ListActionOptions } from '../../../components/types';
import { APP_NAME } from '../../../config/contants';
import useAuthorization from '../../authorization/hooks/use-auth';
import useCustomerContext from '../../customers/hooks/use-customer-context';
import useUserBatches from '../hooks/use-user-batches';
import CreateNewUserBatchModal, { CreateNewUserBatchModalProps } from './components/create-new-user-batch-modal';
import UserBatchList from './components/user-batch-list';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

const UserImports = () => {
    useDocumentTitle(APP_NAME, ['Users', 'User imports']);
    const { classes } = useStyles();
    const context = useAuthorization();
    const snackbar = useTranslatedSnackbar();

    const [supportedApplications, setSupportedApplications] = useState<ApplicationDto[] | null>(null);
    const { customer } = useCustomerContext();
    const userBatches = useUserBatches(customer, supportedApplications || undefined);

    const options: ListActionOptions = useMemo(
        () => ({
            notify: snackbar,
        }),
        [snackbar]
    );

    useEffect(() => {
        if (supportedApplications === null && customer.applications) {
            setSupportedApplications(
                customer.applications.filter((application) =>
                    [ApplicationDto.externalSystem.ENGAGE, ApplicationDto.externalSystem.ENGAGE_PLUS].includes(
                        application.externalSystem
                    )
                )
            );
        }
    }, [customer.applications, supportedApplications]);

    useInterval(() => {
        userBatches.refresh().then(() => null);
    }, 10000);

    const onFabClick = useCallback(() => {
        if (supportedApplications) {
            NiceModal.show<UserBatchDto, CreateNewUserBatchModalProps>(CreateNewUserBatchModal, {
                applications: supportedApplications || [],
                options,
            }).then(() => userBatches.refresh());
        }
    }, [options, supportedApplications, userBatches]);

    if (supportedApplications === null) {
        return (
            <Box data-testid="player-creation-tool-loader">
                <ComponentLoader />
            </Box>
        );
    }

    return (
        <Box component="section" className={classes.root} data-testid="user-imports">
            <Fab
                aria-label="create-user-batch"
                color="primary"
                disabled={!context.can('create', 'User')}
                onClick={onFabClick}
                data-testid="create-user-batch"
                sx={{ position: 'absolute', top: '-98px', right: '20px' }}
            >
                <AddIcon />
            </Fab>

            <UserBatchList error={userBatches.error} isLoading={userBatches.isLoading} userBatches={userBatches.data} />
        </Box>
    );
};

export default UserImports;
