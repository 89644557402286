import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ExtendedPlayerDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import PlayerBatchReportPlayerLabels from './player-batch-report-player-labels';

export type PlayerBatchReportPlayersProps = {
    players: ExtendedPlayerDto[];
};

const COLUMNS: ColumnType[] = [
    { label: 'Player ID' },
    { label: 'Player name' },
    { label: 'Resolution' },
    { label: 'Registration code' },
    { label: 'Labels & Values' },
];

const PlayerBatchReportPlayers = ({ players }: PlayerBatchReportPlayersProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead columns={COLUMNS} />

                <TableBody>
                    {players.map((player) => (
                        <TableRow key={player.id}>
                            <TableCell>
                                <Typography>{player.playerId}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{player.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    {player.width}x{player.height}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{player.registrationCode}</Typography>
                            </TableCell>
                            <TableCell>
                                <PlayerBatchReportPlayerLabels labels={player.labels} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PlayerBatchReportPlayers;
