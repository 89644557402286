import React from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { Box, Grid, IconButton } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import ApplicationIconSwitch from '../../../applications/application-icon-switch/application-icon-switch';
import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';

const { MEDIUMGRAY, ORANGE } = themeOptions.colors;

type UacConfigurationAdminPanelApplicationAccessProps = {
    canRemove: boolean;
    field: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'applicationAccesses', 'key'>;
    index: number;
    onRemove: UseFieldArrayRemove;
};

const UacConfigurationAdminPanelApplicationAccess = ({
    canRemove,
    field,
    index,
    onRemove,
}: UacConfigurationAdminPanelApplicationAccessProps) => {
    return (
        <>
            <Grid item xs={5} data-testid={`admin-panel-applicationAccesses--${index}--value`}>
                <TextInput
                    defaultLabel="Value"
                    name={`applicationAccesses.${index}.value`}
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>

            <Grid item xs={5} data-testid={`admin-panel-applicationAccesses--${index}--applications`}>
                {field.applications.map((item, appIndex) => (
                    <Box
                        key={item.application.id}
                        data-testid={`admin-panel-applicationAccesses--${index}--application--${appIndex}`}
                        sx={{ display: 'inline-flex' }}
                    >
                        <ApplicationIconSwitch
                            defaultChecked={item.enabled}
                            id={item.application.id}
                            name={`applicationAccesses.${index}.applications.${appIndex}.enabled`}
                        />
                    </Box>
                ))}
            </Grid>

            <Grid item xs={2}>
                <IconButton
                    data-testid={`remove-admin-panel-applicationAccess--${index}`}
                    disabled={!canRemove}
                    onClick={() => onRemove(index)}
                >
                    <Icon color={(!canRemove && MEDIUMGRAY) || ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </>
    );
};

export default UacConfigurationAdminPanelApplicationAccess;
