import { useContext, useMemo } from 'react';

import AutomatedUserCreationContext from '../context/automated-user-creation-context';

const useAutomatedUserCreation = () => {
    const context = useContext(AutomatedUserCreationContext);

    if (context === undefined) {
        throw new Error('useAutomatedUserCreation is only usable within AutomatedUserCreationProvider');
    }

    return useMemo(() => context, [context]);
};

export default useAutomatedUserCreation;
