import React from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@zetadisplay/zeta-ui-components';

import { DEFAULT_ENGAGE_ROLE, UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationEngageRole from './uac-configuration-engage-role';

type UacConfigurationEngageRolesProps = {
    enabled: boolean;
    fields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'engageRoles', 'key'>[];
    onAdd: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'engageRoles'>;
    onRemove: UseFieldArrayRemove;
};
const UacConfigurationEngageRoles = ({ enabled, fields, onAdd, onRemove }: UacConfigurationEngageRolesProps) => {
    return (
        <Box data-testid="uac-configuration-engage-roles" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
                Engage roles
            </Typography>

            <Grid container spacing={3} sx={{ mb: 1 }}>
                {fields.map((field, index, array) => (
                    <UacConfigurationEngageRole
                        key={field.key}
                        canRemove={array.length > 1}
                        enabled={enabled}
                        index={index}
                        onRemove={onRemove}
                    />
                ))}
            </Grid>

            <Button disabled={!enabled} name="add-another-engage-role" onClick={() => onAdd(DEFAULT_ENGAGE_ROLE)}>
                Add another rule +
            </Button>
        </Box>
    );
};

export default UacConfigurationEngageRoles;
