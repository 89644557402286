import { useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';

import { ColumnType } from '../../../../../components/table/table';
import TableHead from '../../../../../components/table/table-head';
import TableRowSkeleton from '../../../../../components/table/table-row-skeleton';
import { ListActionOptions } from '../../../../../components/types';
import TemplateListRow from './template-list-row';

const COLUMNS: ColumnType[] = [
    { label: '', sx: { width: '25px' } },
    { label: 'Name' },
    { label: 'Type', sx: { width: '20%' } },
    { label: 'Last updated', sx: { width: '30%' } },
    { label: 'Actions', sx: { width: '10%', textAlign: 'center' } },
];

type TemplateListProps = {
    data: DiscriminatedEntity<LibraryModelsTemplateRelations>[];
    isLoading: boolean;
};

const TemplateList = ({ data, isLoading }: TemplateListProps) => {
    const snackbar = useTranslatedSnackbar();

    const options: ListActionOptions = useMemo(
        () => ({
            notify: snackbar,
        }),
        [snackbar]
    );

    const renderTemplates = () => {
        if (data?.length === 0 || !data) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No templates found
                    </TableCell>
                </TableRow>
            );
        }

        return data.map((template) => {
            return <TemplateListRow options={options} template={template} key={template.id} />;
        });
    };

    return (
        <Box component="section" data-testid="template-list">
            <Typography variant="h2">Templates</Typography>

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && data.length === 0 && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}
                        {(!isLoading || data.length > 0) && renderTemplates()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TemplateList;
