import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { Delete } from '@styled-icons/material';
import { MetaScriptContainerC } from '@zetadisplay/connect-userautocreation-api-client/models/MetaScriptContainerC';

import ConfirmPrompt from '../../../../../components/modals/confirm-prompt';
import { ListActionOptions } from '../../../../../components/types';
import deleteUacAction from '../../actions/delete-uac-action';
import useUacApi from '../../hooks/use-uac-api';

type DeleteUacPromptProps = {
    script: MetaScriptContainerC;
    options: ListActionOptions;
};

const DeleteUacPrompt = NiceModal.create<DeleteUacPromptProps>(({ script, options }) => {
    const api = useUacApi();

    return (
        <ConfirmPrompt<boolean | void>
            action={() => deleteUacAction(api, script, options)}
            name="delete-uac-prompt"
            submit={{
                label: 'common.action.delete',
                name: 'delete',
            }}
            title={{ icon: <Delete size={24} />, label: `Delete UAC configuration ${script.id}?` }}
        >
            <Typography>
                Please confirm that you want to permanently delete UAC configuration &quot;{script.id}&quot;.
            </Typography>
        </ConfirmPrompt>
    );
});

export default DeleteUacPrompt;
