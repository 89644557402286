import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { LockOpen } from '@styled-icons/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import activateCustomerAction from '../../actions/activate-customer-action';
import { CustomersOptions } from '../../customers';

type DeactivateCustomerPromptProps = {
    customer: CustomerDto;
    options: CustomersOptions;
};

const ActivateCustomerPrompt = NiceModal.create<DeactivateCustomerPromptProps>(({ customer, options }) => {
    const api = useAdminPanelApi();

    return (
        <ConfirmPrompt<CustomerDto | void>
            action={() => activateCustomerAction(api, customer, options)}
            name="activate-customer-prompt"
            submit={{
                label: 'Activate',
                name: 'activate',
            }}
            title={{ icon: <LockOpen size={24} />, label: `Activate customer ${customer.name}?` }}
        >
            <Typography>Please confirm that you want to activate customer &quot;{customer.name}&quot;.</Typography>
        </ConfirmPrompt>
    );
});

export default ActivateCustomerPrompt;
