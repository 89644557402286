import { useAsyncAbortable } from 'react-async-hook';
import { ApplicationDto, CustomerDto, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../../dependency-injection/utils/handle-admin-panel-response-error';

export type UsePlayerBatchesReturnType = {
    data: PlayerBatchDto[] | undefined;
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<PlayerBatchDto[] | undefined>;
};

const usePlayerBatches = (customer: CustomerDto, application?: ApplicationDto): UsePlayerBatchesReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const playerBatches = useAsyncAbortable<PlayerBatchDto[] | undefined>(
        async () => {
            if (!customer || !application) {
                return undefined;
            }

            return api.playerBatch.getPlayerBatches(customer.id, application.id).then((data) => data);
        },
        [api.playerBatch, application, customer],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    return {
        data: playerBatches.result,
        error: playerBatches.error,
        isLoading: playerBatches.loading,
        refresh: playerBatches.execute,
    };
};

export default usePlayerBatches;
