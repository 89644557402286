import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

const PLACEHOLDER_CUSTOMER: CustomerDto = {
    id: '',
    createdAt: '',
    updatedAt: '',
    name: '',
    logoUrl: '',
    contact: '',
    phoneNumber: '',
    language: '',
    parentCustomerId: '',
    applications: [],
    roles: [],
};

export default PLACEHOLDER_CUSTOMER;
