import { Box } from '@mui/material';
import { TextInput } from '@zetadisplay/engage-components';

const LabelValueForm = () => {
    return (
        <Box>
            <TextInput defaultLabel="Value" name="value" />
        </Box>
    );
};

export default LabelValueForm;
