import React from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import env from '@beam-australia/react-env';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import pkg from '../package.json';
import App from './app';
// import reportWebVitals from './reportWebVitals';

const release = env('APP_VERSION') || pkg.version;
const version = process.env.NODE_ENV === 'development' ? '.DEV' : release;

Sentry.init({
    dsn: env('SENTRY_DSN'),
    environment: env('STAGE') || 'development',
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
    ],
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
        new Sentry.Replay(),
    ],
    release: `adminpanel@${version}`,
    replaysSessionSampleRate: ['development', 'staging'].includes(env('STAGE')) ? 1.0 : 0, // Disabled in production
    replaysOnErrorSampleRate: ['development', 'staging'].includes(env('STAGE')) ? 1.0 : 0, // Disabled in production
    tracesSampleRate: env('STAGE') === 'development' ? 1.0 : 0.5,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
