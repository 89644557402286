import { useAsyncAbortable } from 'react-async-hook';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import getCustomerApplications from '../api/get-customer-applications';

export type UseCustomerApplicationsReturnType = {
    data: ApplicationDto[] | undefined;
    isLoading: boolean;
};

const useCustomerApplications = (customerId?: string): UseCustomerApplicationsReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const applications = useAsyncAbortable<ApplicationDto[] | undefined>(
        async () => {
            if (!customerId) {
                return undefined;
            }

            return getCustomerApplications(customerId, api);
        },
        [api, customerId],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    return {
        data: applications.result,
        isLoading: applications.loading,
    };
};

export default useCustomerApplications;
