import { useCallback, useMemo, useState } from 'react';
import { Control, UseFormSetError } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    CreateCustomerUserDto,
    EditCustomerUserDto,
    UserDto,
    UserValidationDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput, SelectOption, TextInput } from '@zetadisplay/engage-components';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { validateEmail } from '@zetadisplay/engage-components/utils/form/validators';
import { Accordion } from '@zetadisplay/zeta-ui-components';
import { makeStyles, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';
import { useDebouncedCallback } from 'use-debounce';

import useCurrentUser from '../../authorization/hooks/use-current-user';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import validateUserEmail from '../api/validate-user-email';
import { languageOptions } from '../constants';
import { USER_CONFIG_FORM_VALUES } from './types';
import UserProfileHeader from './user-profile-header';

const useStyles = makeStyles()(() => ({
    root: {
        padding: '0 24px 0 6px',
    },
}));

const USER_TYPE_OPTIONS: SelectOption[] = [
    { id: UserDto.type.ADMINISTRATOR, name: 'Administrator' },
    { id: UserDto.type.ZETA_DISPLAY, name: 'ZetaDisplay' },
    { id: UserDto.type.USER, name: 'User' },
];

export type UserProfileProps = {
    addCustomer?: (data?: CreateCustomerUserDto | EditCustomerUserDto) => void;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    defaultValues: {
        username?: string;
        firstName?: string;
        lastName?: string;
        phoneNumber?: string;
        type?: string;
        language?: string;
    };
    externalProfile?: boolean;
    setError: UseFormSetError<USER_CONFIG_FORM_VALUES>;
    setValue: UseFormSetValue<USER_CONFIG_FORM_VALUES>;
};

const UserProfile = ({
    addCustomer,
    control,
    defaultValues,
    externalProfile,
    setError,
    setValue,
}: UserProfileProps) => {
    const { classes } = useStyles();
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const currentUser = useCurrentUser();
    const theme: ZetaTheme = useTheme();

    const [validating, setValidating] = useState(false);
    const [validationResult, setValidationResult] = useState<string | boolean | null>(null);
    const [storedEmail, setStoredEmail] = useState(defaultValues?.username);

    const checkEmail = useCallback(
        async (email: string) => {
            if (storedEmail === email) {
                return validationResult;
            }

            setStoredEmail(email);
            setValidating(true);
            snackbar(`Validating username ${email}, please wait..`, undefined, 'info', 5000);

            let checkResult: boolean | string = true;

            const validation = await validateUserEmail(email, api);

            if (validation.result === UserValidationDto.result.UNAVAILABLE) {
                const errorMessage = 'User is not available';
                snackbar(validation?.message?.message || errorMessage, undefined, 'error', 10000);

                checkResult = validation?.message?.message || errorMessage;
                setError('user.username', { message: checkResult });
            }

            if (validation.result === UserValidationDto.result.AVAILABLE) {
                const { data = {} } = validation;
                const { customerUser, engage, okta } = data;

                if (!okta && !engage) {
                    snackbar(validation?.message?.message || 'Username is available', undefined, 'info');
                }

                if (okta) {
                    const { firstName, lastName, mobilePhone } = okta;

                    snackbar(
                        validation?.message?.message || 'A new user can be created, profile exists in Okta',
                        undefined,
                        'info'
                    );
                    setValue('user.firstName', firstName || '', { shouldDirty: true, shouldValidate: true });
                    setValue('user.lastName', lastName || '', { shouldDirty: true, shouldValidate: true });
                    setValue('user.phoneNumber', mobilePhone || '', { shouldDirty: true, shouldValidate: true });
                }

                if (addCustomer && customerUser) {
                    snackbar('Mandatory customer access with Engage configuration was pre-filled', undefined, 'info');
                    addCustomer(customerUser);
                }
            }

            if (validation.result === UserValidationDto.result.INVALID) {
                const message = validation?.message?.message || 'Unable to create user';
                snackbar(message, undefined, 'error', 10000);
                setError('user.username', { message });
            }

            setValidationResult(checkResult);
            setValidating(false);
            return checkResult;
        },
        [addCustomer, api, setError, setValue, snackbar, storedEmail, validationResult]
    );

    const debouncedCheckEmail = useDebouncedCallback(checkEmail, 250);

    const userTypeOptions: SelectOption[] = useMemo(() => {
        if (!currentUser || currentUser.type !== UserDto.type.ADMINISTRATOR) {
            return USER_TYPE_OPTIONS.filter((type) => type.id === UserDto.type.USER);
        }

        return USER_TYPE_OPTIONS;
    }, [currentUser]);

    return (
        <Accordion defaultExpanded renderSummary={() => <UserProfileHeader externalProfile={externalProfile} />}>
            <div className={classes.root} data-testid="user-profile">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Username"
                            defaultValue={defaultValues.username || ''}
                            disabled={
                                (defaultValues.username && defaultValues?.username?.length > 0) ||
                                externalProfile ||
                                validating ||
                                false
                            }
                            name="user.username"
                            required
                            rules={{
                                validate: {
                                    required: (value: string) => value.length > 0 || 'common.validation.required',
                                    validateEmail: (value: string) => validateEmail(value) || 'common.validation.email',
                                    debouncedCheckEmail,
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Phone number"
                            defaultValue={defaultValues.phoneNumber || ''}
                            disabled={validating || externalProfile}
                            name="user.phoneNumber"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="First name"
                            defaultValue={defaultValues.firstName || ''}
                            disabled={validating || externalProfile}
                            name="user.firstName"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Last name"
                            defaultValue={defaultValues.lastName || ''}
                            disabled={validating || externalProfile}
                            name="user.lastName"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <SelectInput
                            control={control}
                            defaultValue={defaultValues.type}
                            disabled={validating || userTypeOptions.length === 1}
                            fullWidth
                            label="User type"
                            LabelProps={{
                                backgroundColor:
                                    (theme.palette.dark && theme.palette.background.content) ||
                                    theme.palette.background.main,
                            }}
                            name="user.type"
                            options={userTypeOptions}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <SelectInput
                            control={control}
                            defaultValue={defaultValues.language}
                            fullWidth
                            label="Language"
                            LabelProps={{
                                backgroundColor:
                                    (theme.palette.dark && theme.palette.background.content) ||
                                    theme.palette.background.main,
                            }}
                            disabled={validating}
                            name="user.language"
                            options={languageOptions}
                        />
                    </Grid>
                </Grid>
            </div>
        </Accordion>
    );
};

export default UserProfile;
