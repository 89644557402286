import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

type CreatedUpdateDetails = Pick<PlayerBatchDto, 'createdAt' | 'updatedAt' | 'createdBy' | 'updatedBy'>;

type AuthorInformationProps<T extends CreatedUpdateDetails> = {
    item: T;
    sx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
};
const AuthorInformation = <T extends CreatedUpdateDetails>({ item, sx, textSx }: AuthorInformationProps<T>) => {
    const theme = useTheme();

    const formatDetails = useCallback(
        (author: T['updatedBy'] | T['createdBy'], date: T['updatedAt'] | T['createdAt']) => {
            const dateObj = new Date(date);
            const authorString = (author && `${author.firstName} ${author.lastName}`) || 'N/A';

            return `${authorString} ${dateObj.toLocaleString()}`;
        },
        []
    );

    return (
        <Box
            data-testid="author-information"
            sx={
                sx || {
                    marginTop: 24,
                    [theme.breakpoints.up('lg')]: {
                        marginTop: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    },
                }
            }
        >
            <Typography sx={textSx || { fontSize: '11px', opacity: 0.6 }}>
                Created: {formatDetails(item.createdBy, item.createdAt)}
            </Typography>
            <Typography sx={textSx || { fontSize: '11px', opacity: 0.6 }}>
                Modified: {formatDetails(item.updatedBy, item.updatedAt)}
            </Typography>
        </Box>
    );
};

export default AuthorInformation;
