import { useAsyncAbortable } from 'react-async-hook';
import { MetaScriptContainerC } from '@zetadisplay/connect-userautocreation-api-client/models/MetaScriptContainerC';

import useResults from '../../../../hooks/use-results';
import getResultData from '../../../../utils/get-result-data';
import { useUacDeletedEventListener } from '../events/uac-deleted-event';
import useUACApi from './use-uac-api';

const useUACConfigs = () => {
    const api = useUACApi();

    const { results, removeResult, setResults } = useResults<MetaScriptContainerC>('uac-configs');

    const scripts = useAsyncAbortable<MetaScriptContainerC[]>(async () => {
        return api.script.scriptList().then((data) => setResults(data));
    }, [api.script, setResults]);

    useUacDeletedEventListener((data: MetaScriptContainerC) => {
        removeResult(data);
    });

    return {
        data: getResultData(results),
        error: scripts.error,
        isLoading: scripts.loading,
        refresh: scripts.execute,
    };
};

export default useUACConfigs;
