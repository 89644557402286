import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { Box } from '@mui/material';
import {
    ApplicationDto,
    CustomerDto,
    EditApplicationDto,
    EditCustomerDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { dequal } from 'dequal';

import AuthorInformation from '../../../components/author-information/author-information';
import FormActions from '../../../components/form/form-actions';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import { APPLICATIONS } from '../../applications/application';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import editCustomerAction from './actions/edit-customer-action';
import CustomerApplications from './customer-applications';
import { CustomerApplicationFormData, CustomerConfigurationOptions } from './customer-configuration';
import CustomerProfile from './customer-profile';

export type EDIT_CUSTOMER_FORM_VALUES = {
    customer: EditCustomerDto;
    applications: CustomerApplicationFormData<EditApplicationDto>[];
};

export type EditCustomerProps = {
    customer: CustomerDto;
};

const applicationsToDTO = (applications: ApplicationDto[]): CustomerApplicationFormData<EditApplicationDto>[] => {
    return APPLICATIONS.map((application) => {
        const object = applications.find((app) => app.externalSystem === application.id);

        return {
            data: {
                enabled: object?.enabled || false,
                externalSystem: application.id,
                externalLinkType: object?.externalLinkType || application.externalLinkType || '',
                externalEntityId: object?.externalEntityId || '',
                externalEntityName: object?.externalEntityName || '',
            },
            object,
        };
    });
};

const EditCustomer = ({ customer }: EditCustomerProps) => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const navigate = useNavigate();

    const methods = useForm<EDIT_CUSTOMER_FORM_VALUES>({
        defaultValues: (() => {
            return {
                customer: {
                    name: customer.name,
                    logoUrl: customer.logoUrl,
                    contact: customer.contact,
                    phoneNumber: customer.phoneNumber,
                    language: customer.language,
                    parentCustomerId: customer.parentCustomerId || '',
                },
                applications: applicationsToDTO(customer.applications || []),
            };
        })(),
    });
    const { control, formState, handleSubmit, reset, watch } = methods;

    const { isSubmitting } = formState;

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<EDIT_CUSTOMER_FORM_VALUES>(
        'ap.customer',
        customer.id
    );

    const [hasInitialized, setHasInitialized] = useState(false);

    const values = watch();
    const { applications } = values;

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/customers`);
        });
    }, [defuse, navigate]);

    const options: CustomerConfigurationOptions<CustomerDto> = {
        callback: actionsCallback,
        customer,
        notify: snackbar,
    };

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(values, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...values, ...preservedValues });
        }

        setHasInitialized(true);
    }, [hasInitialized, preservedValues, reset, snackbar, values]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => setPreservedValues(value as EDIT_CUSTOMER_FORM_VALUES));
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const onSubmit: SubmitHandler<EDIT_CUSTOMER_FORM_VALUES> = (data) => editCustomerAction(api, data, options);

    const renderExtraContent = useCallback(() => <AuthorInformation<CustomerDto> item={customer} />, [customer]);

    return (
        <Box component="div" data-testid="edit-customer">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <DevTool control={control} placement="top-right" /> */}

                    <CustomerProfile control={control} customer={customer} defaultValues={customer} />

                    <CustomerApplications<EDIT_CUSTOMER_FORM_VALUES> applications={applications} control={control} />

                    <FormActions
                        callback={actionsCallback}
                        extraContent={renderExtraContent}
                        name="customer-actions"
                        isSubmitting={isSubmitting}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default EditCustomer;
