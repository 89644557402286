import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { LockOpen } from '@styled-icons/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';
import { ListActionOptions } from '../../../../components/types';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import activateUserAction from '../../actions/activate-user-action';

type ActivateUserPromptProps = {
    user: UserDto;
    options: ListActionOptions;
};

const ActivateUserPrompt = NiceModal.create<ActivateUserPromptProps>(({ user, options }) => {
    const api = useAdminPanelApi();

    return (
        <ConfirmPrompt<UserDto | void>
            action={() => activateUserAction(api, user, options)}
            name="activate-user-prompt"
            submit={{
                label: 'Activate',
                name: 'activate',
            }}
            title={{ icon: <LockOpen size={24} />, label: `Activate user ${user.username}?` }}
        >
            <Typography>Please confirm that you want to activate user &quot;{user.username}&quot;.</Typography>
        </ConfirmPrompt>
    );
});

export default ActivateUserPrompt;
