import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APP_NAME } from '../../../config/contants';
import { SOFTWARE_CONFIG_PLAYER_CREATION_PATH, SOFTWARE_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import useAuthorization from '../../authorization/hooks/use-auth';
import useCustomerContext from '../../customers/hooks/use-customer-context';
import PlayerBatchList from './components/player-batch-list/player-batch-list';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

const EngagePlayers = () => {
    useDocumentTitle(APP_NAME, ['Engage', 'Players']);
    const { classes } = useStyles();
    const context = useAuthorization();

    const navigate = useNavigate();
    const { customer } = useCustomerContext();

    const onFabClick = useCallback(() => {
        navigate(`/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}/create`);
    }, [navigate]);

    return (
        <Box component="section" className={classes.root} data-testid="engage-players">
            <Fab
                aria-label="create-player-batch"
                color="primary"
                disabled={!context.can('create', 'PlayerBatch-Engage', customer.id)}
                onClick={onFabClick}
                data-testid="create-player-batch"
                sx={{ position: 'absolute', top: '-98px', right: '20px' }}
            >
                <AddIcon />
            </Fab>

            <PlayerBatchList customer={customer} />
        </Box>
    );
};

export default EngagePlayers;
