import {
    ContactDto,
    CreateBatchPlayerDto,
    LocationDto,
    PlayerBatchDto,
} from '@zetadisplay/connect-adminpanel-api-client';

export const DEFAULT_CONTACT: ContactDto = {
    id: undefined,
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
};

export const DEFAULT_LOCATION: LocationDto = {
    id: undefined,
    address: '',
    zipcode: '',
    city: '',
    countryId: 0,
    timezoneId: 0,
};

export const DEFAULT_PLAYER: CreateBatchPlayerDto = {
    labels: [],
    license: '',
    name: '',
    width: 1920,
    height: 1080,
};

export const FORM_DEFAULT_VALUES = {
    batchPlayers: [],
    contact: DEFAULT_CONTACT,
    externalEntityId: '',
    location: DEFAULT_LOCATION,
    name: '',
    status: PlayerBatchDto.status.DRAFT,
};

export const COLUMN_GRID_ITEM_PROPS = {
    item: true,
    xs: 12,
    sm: 6,
    md: 4,
};
