import { AdminPanelClient, ApiError, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { PlayerCreationToolOptions } from '../player-creation-tool';

const deletePlayerBatchAction = async (
    api: AdminPanelClient,
    playerBatch: PlayerBatchDto,
    options: PlayerCreationToolOptions
): Promise<boolean | void> => {
    if (!options.customer) {
        options.notify('Customer must be set', [], 'error');
        return false;
    }
    if (!options.application) {
        options.notify('Application must be set', [], 'error');
        return false;
    }

    return api.playerBatch
        .deletePlayerBatchById(options.customer.id, options.application.id, playerBatch.id)
        .then(() => {
            options.notify(`Batch has been deleted successfully`, [], 'success');

            if (options.callback) {
                options.callback();
            }
            return true;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default deletePlayerBatchAction;
