import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { Delete, Edit, LockOpen, LockPerson, Password } from '@styled-icons/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { ActionGroup, ContextMenu } from '@zetadisplay/zeta-ui-components';

import { ListActionOptions } from '../../../../components/types';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../../routing/constants';
import useAuthorization from '../../../authorization/hooks/use-auth';
import useCurrentUser from '../../../authorization/hooks/use-current-user';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../../dependency-injection/utils/handle-admin-panel-response-error';
import resetPassword from '../../api/reset-password';
import ActivateUserPrompt from '../prompts/activate-user-prompt';
import DeactivateUserPrompt from '../prompts/deactivate-user-prompt';
import DeleteUserPrompt from '../prompts/delete-user-prompt';

export type UserListActionsProps = {
    options: ListActionOptions;
    user: UserDto;
};

const UserListActions = ({ options, user }: UserListActionsProps) => {
    const authorization = useAuthorization();
    const history = useNavigate();
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const currentUser = useCurrentUser();

    const canEditSelf = user.type === UserDto.type.ADMINISTRATOR || currentUser?.id !== user.id;

    const canEdit = canEditSelf && authorization.can('update', 'User', user.id);
    const canDeactivate = canEditSelf && authorization.can('update', 'User', user.id);
    const canDelete = canEditSelf && authorization.can('delete', 'User', user.id);
    const canResetPassword = authorization.can('update', 'User', user.id);

    const deactivated = (user.deletedAt && user.deletedAt.length > 0) || false;

    const actions: ActionGroup<UserDto>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => history(`/${ACCESS_CONFIGURATION_BASE_PATH}/users/${user.id}/edit`),
                        icon: Edit,
                        isDisabled: () => deactivated || !canEdit,
                        label: 'Edit',
                        name: 'edit-user',
                    },
                    {
                        handler: () =>
                            NiceModal.show(DeactivateUserPrompt, {
                                user,
                                options,
                            }),
                        icon: LockPerson,
                        isDisabled: () => deactivated || !canDeactivate,
                        isVisible: () => !deactivated,
                        label: 'Deactivate',
                        name: 'deactivate-user',
                    },
                    {
                        handler: () =>
                            NiceModal.show(ActivateUserPrompt, {
                                user,
                                options,
                            }),
                        icon: LockOpen,
                        isDisabled: () => !deactivated || !canDeactivate,
                        isVisible: () => deactivated,
                        label: 'Activate',
                        name: 'activate-user',
                    },
                    {
                        handler: () =>
                            NiceModal.show(DeleteUserPrompt, {
                                user,
                                options,
                            }),
                        icon: Delete,
                        isDisabled: () => !deactivated || !canDelete,
                        isVisible: () => deactivated,
                        label: 'Delete',
                        name: 'delete-user',
                    },
                    {
                        handler: async (item) => {
                            await resetPassword(item, api)
                                .then(() =>
                                    snackbar(
                                        `Password reset request for ${item.username} was sent successfully`,
                                        undefined,
                                        'success'
                                    )
                                )
                                .catch((error) =>
                                    snackbar(
                                        `Password reset request for ${
                                            item.username
                                        } failed: ${handleAdminPanelResponseError(error)}`,
                                        undefined,
                                        'error'
                                    )
                                );
                        },
                        icon: Password,
                        isDisabled: (item) => !canResetPassword || ((!item || item.externalProfile) && true) || false,
                        isVisible: () => !deactivated,
                        label: 'Reset password',
                        name: 'reset-password',
                    },
                ],
                name: 'user-actions',
            },
        ];
    }, [api, canDeactivate, canDelete, canEdit, canResetPassword, deactivated, history, options, snackbar, user]);

    return (
        <Box component="div" sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center' }}>
            <ContextMenu<UserDto> actions={actions} item={user} placement="bottom" />
        </Box>
    );
};

export default UserListActions;
