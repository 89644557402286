import { useCallback, useMemo } from 'react';
import { SortDown, SortUp } from '@styled-icons/boxicons-regular';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ActionGroup } from '@zetadisplay/zeta-ui-components';
import { useSessionStorage } from '@zetadisplay/zeta-ui-components/hooks';

export type SortBy = 'username' | 'updatedAt' | 'status';
export type Order = 'asc' | 'desc';
export type Sort = { sortBy: SortBy; order: Order } | null;

const getIcon = (sortBy: SortBy, currentSort: Sort) =>
    (currentSort?.sortBy === sortBy && currentSort?.order === 'asc' && SortDown) || SortUp;

const useSorting = () => {
    const [sort, setSort] = useSessionStorage<Sort>('admin-panel.users.sorting', null);

    const setSorting = useCallback(
        (sortBy: SortBy) => {
            if (sort?.sortBy === sortBy && sort?.order === 'asc') {
                setSort({ sortBy, order: 'desc' });
            } else {
                setSort({ sortBy, order: 'asc' });
            }
        },
        [setSort, sort?.order, sort?.sortBy]
    );

    const sortingOptions: ActionGroup<UserDto[]>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => setSorting('username'),
                        icon: getIcon('username', sort),
                        label: 'Sort by username',
                        name: 'sort-by-username',
                    },

                    {
                        handler: () => setSorting('updatedAt'),
                        icon: getIcon('updatedAt', sort),
                        label: 'Sort by modified',
                        name: 'sort-by-modified',
                    },
                    {
                        handler: () => setSorting('status'),
                        icon: getIcon('status', sort),
                        label: 'Sort by status',
                        name: 'sort-by-status',
                    },
                    {
                        handler: () => {
                            setSort(null);
                        },
                        isDisabled: () => !sort,
                        label: 'Clear sorting',
                        name: 'clear-sorting',
                    },
                ],
                name: 'users-sorting',
            },
        ];
    }, [setSort, setSorting, sort]);

    return { sort, sortingOptions };
};

export default useSorting;
