import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectOption } from '@zetadisplay/engage-components';

// eslint-disable-next-line import/prefer-default-export
export const languageOptions: SelectOption[] = [
    { id: UserDto.language.DE_DE, name: 'German' },
    { id: UserDto.language.EN_US, name: 'English (US)' },
    { id: UserDto.language.FI_FI, name: 'Finnish' },
    { id: UserDto.language.NB_NO, name: 'Norwegian' },
    { id: UserDto.language.NL_NL, name: 'Dutch' },
    { id: UserDto.language.SV_SE, name: 'Swedish' },
];
