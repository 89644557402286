import { ApplicationDto, CustomerDto, RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

const getUserCustomerRoles = (user: UserDto, target: CustomerDto, customers: CustomerDto[]) => {
    if (user.type === UserDto.type.ADMINISTRATOR) {
        return undefined;
    }

    let roles: RoleDto[] | undefined;

    let currentCustomer = customers?.find((item) => item.id === target.id);

    while (currentCustomer && !roles) {
        const customerUser = user.customers.filter(
            // eslint-disable-next-line no-loop-func
            (item) => currentCustomer && item.customer.id === currentCustomer.id
        )[0];

        if (
            customerUser?.applications?.some(
                (aac) => aac.application.externalSystem === ApplicationDto.externalSystem.ADMIN_PANEL
            )
        ) {
            roles = customerUser.roles;
        }
        // No CustomerRoles, go to parent
        // eslint-disable-next-line no-loop-func
        currentCustomer = customers.find((item) => currentCustomer && item.id === currentCustomer.parentCustomer?.id);
    }

    return roles;
};

export default getUserCustomerRoles;
