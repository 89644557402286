import { AdminPanelClient, ApiError, EditPlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { PlayerCreationToolOptions } from '../player-creation-tool';
import validateContact from './validate-contact';

const editPlayerBatchAction = async <T extends EditPlayerBatchDto>(
    api: AdminPanelClient,
    data: T,
    options: PlayerCreationToolOptions
) => {
    try {
        if (!options.customer) {
            options.notify('Customer must be set', [], 'error');
            return;
        }
        if (!options.application) {
            options.notify('Application must be set', [], 'error');
            return;
        }
        if (!options.playerBatch) {
            options.notify('Batch must be set', [], 'error');
            return;
        }

        const { contact, ...rest } = data;

        const body = {
            ...rest,
            contact: (validateContact(contact) && contact) || undefined,
        };

        await api.playerBatch.editPlayerBatchById(
            options.customer.id,
            options.application.id,
            options.playerBatch.id,
            body
        );

        options.notify(`Batch has been updated successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default editPlayerBatchAction;
