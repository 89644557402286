import React from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Button, ComponentLoader } from '@zetadisplay/zeta-ui-components';

import useCustomers from '../../../customers/hooks/use-customers';
import { DEFAULT_CUSTOMER, UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationAdminPanelCustomer from './uac-configuration-admin-panel-customer';

type UacConfigurationAdminPanelCustomersProps = {
    fields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'customers', 'key'>[];
    onAdd: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'customers'>;
    onRemove: UseFieldArrayRemove;
};
const UacConfigurationAdminPanelCustomers = ({ onAdd, fields, onRemove }: UacConfigurationAdminPanelCustomersProps) => {
    const customers = useCustomers();

    if (customers.isLoading) {
        return <ComponentLoader />;
    }

    return (
        <Box data-testid="uac-configuration-admin-panel-customers" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
                Customer
            </Typography>

            <Grid container spacing={3} sx={{ mb: 1 }}>
                {fields.map((field, index, array) => (
                    <UacConfigurationAdminPanelCustomer
                        key={field.key}
                        canRemove={array.length > 1}
                        customers={customers.data}
                        data={field}
                        index={index}
                        onRemove={onRemove}
                    />
                ))}
            </Grid>

            <Button name="add-another-customer" onClick={() => onAdd(DEFAULT_CUSTOMER)}>
                Add another rule +
            </Button>
        </Box>
    );
};

export default UacConfigurationAdminPanelCustomers;
