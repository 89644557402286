import { useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import TableRowSkeleton from '../../../../components/table/table-row-skeleton';
import { ListActionOptions } from '../../../../components/types';
import useUACScripts from '../hooks/use-uac-configs';
import UacListActions from './uac-list-actions';

const COLUMNS: ColumnType[] = [
    { label: 'Name' },
    { label: 'Last updated' },
    { label: 'Author' },
    { label: 'Actions', sx: { textAlign: 'center', width: '100px' } },
];

const UACList = () => {
    const snackbar = useTranslatedSnackbar();
    const { data, error, isLoading } = useUACScripts();

    const options: ListActionOptions = useMemo(
        () => ({
            notify: snackbar,
        }),
        [snackbar]
    );

    const renderItems = () => {
        if (data?.length === 0 || error) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No items found
                    </TableCell>
                </TableRow>
            );
        }

        return data?.map((item) => {
            const lastUpdated = new Date(item.updatedAt);

            return (
                <TableRow key={item.id} data-testid={`uac-row-${item.id}`}>
                    <TableCell>{item.id}</TableCell>

                    <TableCell data-testid="last-updated">{lastUpdated.toLocaleString()}</TableCell>

                    <TableCell>{item.updatedBy}</TableCell>

                    <TableCell>
                        <UacListActions item={item} options={options} />
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Box component="section" data-testid="uac-list">
            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}

                        {!isLoading && renderItems()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UACList;
