import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components';
import { Workspace } from '@zetadisplay/engage-components/models';
import { useWorkspace, useWorkspaceContent, WorkspaceInput } from '@zetadisplay/engage-components/modules/workspaces';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import CustomerInput from '../../../customers/components/customer-input/customer-input';
import useCustomerContext from '../../../customers/hooks/use-customer-context';
import { COLUMN_GRID_ITEM_PROPS } from '../constants';

const useStyles = makeStyles()(() => ({
    root: {
        marginTop: 6,
    },
}));

export type PlayerBatchContextProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    customer: CustomerDto;
    initialWorkspaceId?: string;
    onWorkspaceChange: (arg: Workspace) => void;
};

const PlayerBatchContext = ({ control, customer, initialWorkspaceId, onWorkspaceChange }: PlayerBatchContextProps) => {
    const { classes } = useStyles();
    const { customers } = useCustomerContext();

    const { workspace } = useWorkspace();

    const initialWorkspace = useWorkspaceContent(initialWorkspaceId);

    return (
        <div
            className={classes.root}
            data-testid={`player-batch-context${(initialWorkspace.loading && '-loading') || ''}`}
        >
            <Grid container spacing={3}>
                <Grid {...COLUMN_GRID_ITEM_PROPS}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {/* Input wrapped on own provider as we do not want to include this on request payloads */}
                            <FormProviderWrapper>
                                <CustomerInput
                                    control={control}
                                    defaultValue={customer}
                                    disabled
                                    fullWidth
                                    loading={false}
                                    name="customer"
                                    options={customers}
                                />
                            </FormProviderWrapper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid {...COLUMN_GRID_ITEM_PROPS}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} data-testid="workspace-input-container">
                            <Controller
                                control={control}
                                name="externalEntityId"
                                render={({ field: { onChange } }) => {
                                    return (
                                        <WorkspaceInput
                                            defaultWorkspaces={[
                                                (initialWorkspaceId && initialWorkspace.result) || workspace,
                                            ]}
                                            includeRootWorkspace
                                            label="Parent workspace"
                                            loading={initialWorkspace.loading}
                                            name="externalEntityId"
                                            multiple={false}
                                            onChange={(data) => {
                                                onChange(data[0].id);
                                                onWorkspaceChange(data[0]);
                                            }}
                                            required
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default PlayerBatchContext;
