import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useModal } from '@ebay/nice-modal-react';

import Modal from './modal';
import { createDefaultButtons, ModalActionButton } from './modal-actions';
import { ModalTitleProps } from './modal-title';

export type ConfirmPromptProps<T> = React.PropsWithChildren<{
    action: () => Promise<T>;
    name: string;
    submit?: Partial<Pick<ModalActionButton, 'label' | 'name'>>;
    title?: ModalTitleProps;
}>;

const ConfirmPrompt = <T,>({
    action,
    children,
    name,
    submit = {
        label: 'common.action.save',
        name: 'save',
    },
    title,
}: ConfirmPromptProps<T>) => {
    const modal = useModal();

    const callback = useAsyncCallback(async () => {
        const response = await action();
        if (response === undefined) {
            return;
        }

        modal.resolve(response);
        modal.hide();
    });

    return (
        <Modal
            actions={{
                buttons: createDefaultButtons({
                    cancel: { disabled: callback.loading, onClick: modal.hide },
                    submit: {
                        busy: callback.loading,
                        disabled: callback.loading,
                        label: submit.label,
                        name: submit.name,
                        onClick: () => callback.execute(),
                    },
                }),
            }}
            name={name}
            title={title}
        >
            {children}
        </Modal>
    );
};

export default ConfirmPrompt;
