import { AdminPanelClient, ApiError } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { emitUserUpdatedEvent } from '../../events/user-updated-event';
import { EDIT_USER_FORM_VALUES } from '../edit-user';
import { UserConfigurationOptions } from '../user-configuration';

const editUserAction = async <T extends EDIT_USER_FORM_VALUES>(
    api: AdminPanelClient,
    data: T,
    options: UserConfigurationOptions
) => {
    try {
        if (!options?.user?.id) {
            return;
        }

        const user = await api.users.editUserById(options.user.id, data.user);

        emitUserUpdatedEvent(user);

        options.notify(`User "${options.user.username}" has been updated successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default editUserAction;
