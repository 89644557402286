import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

export type UserBatchReportActionsProps = {
    closeCallback: () => void;
};

const useStyles = makeStyles()(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
}));
const UserBatchReportActions = ({ closeCallback }: UserBatchReportActionsProps) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.root, 'no-printing')} data-testid="player-batch-report-actions">
            <Button kind="primaryAction" label="Close" onClick={closeCallback} />
        </div>
    );
};

export default UserBatchReportActions;
