import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';

type FormActionsProps = {
    callback: () => void;
    extraContent?: () => JSX.Element;
    name: string;
    isSubmitting: boolean;
};
const FormActions = ({ callback, extraContent, name, isSubmitting }: FormActionsProps) => {
    const t = useTranslation();

    return (
        <Box sx={{ position: 'relative' }} data-testid="form-actions">
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '24px',
                }}
                data-testid={name}
            >
                <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={callback} />

                <Button
                    busy={isSubmitting}
                    disabled={isSubmitting}
                    kind="primaryAction"
                    label={t.trans('common.action.save')}
                    type="submit"
                />

                {extraContent && extraContent()}
            </Box>
        </Box>
    );
};

export default FormActions;
