import { UacContainerProfile } from '@zetadisplay/connect-userautocreation-api-client/models/UacContainerProfile';

import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';

const convertFormDataToPayload = (data: UAC_CONFIGURATION_FORM_VALUES): UacContainerProfile => {
    const customers: Record<string, string> = {};
    const applications: Record<string, string> = {};
    const adminpanelRoles: Record<string, string> = {};
    const engageWorkspaces: Record<string, string> = {};
    const engageRoles: Record<string, string> = {};

    data.customers.forEach((item) => {
        if (item.customer) {
            customers[item.value] = item.customer.id;
        }
    });

    data.applicationAccesses.forEach((applicationAccess) => {
        applications[applicationAccess.value] = applicationAccess.applications
            .filter((item) => item.enabled)
            .map((item) => item.application.name)
            .join(', ');
    });

    data.adminPanelRoles.forEach((adminPanelRole) => {
        if (adminPanelRole.role) {
            adminpanelRoles[adminPanelRole.value] = adminPanelRole.role;
        }
    });

    data.engageAccesses.forEach((engageAccess) => {
        if (engageAccess.workspace) {
            engageWorkspaces[engageAccess.value] = engageAccess.workspace.id;
        }
    });

    data.engageRoles.forEach((engageRole) => {
        if (engageRole.roleId) {
            engageRoles[engageRole.value] = `${engageRole.roleId}`;
        }
    });

    return {
        uacProfile: {
            id: data.id,
            adminpanelCustomer: {
                customer: customers,
            },
            adminpanelRole: adminpanelRoles,
            applicationAccess: {
                applications,
            },
            engageAccess: {
                workspaces: engageWorkspaces,
            },
            engageRole: {
                roles: engageRoles,
            },
        },
    };
};

export default convertFormDataToPayload;
