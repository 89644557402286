import React, { useCallback, useEffect } from 'react';
import { Workspace } from '@zetadisplay/engage-components/models';
import {
    useWorkspaceContent,
    useWorkspaceLayouts,
    useWorkspaceSettings,
    WorkspaceProvider,
} from '@zetadisplay/engage-components/modules/workspaces';
import { useLocalStorage } from '@zetadisplay/zeta-ui-components/hooks';

import EngageLoader from '../engage-loader/engage-loader';

export type EngageWorkspaceWrapperProps = {
    defaultWorkspace?: Workspace;
    workspaceId: string;
};

const EngageWorkspaceWrapper = ({
    children,
    defaultWorkspace,
    workspaceId,
}: React.PropsWithChildren<EngageWorkspaceWrapperProps>) => {
    const [storedValue, setStoredValue] = useLocalStorage('engage.options.workspace', workspaceId);

    const workspace = useWorkspaceContent(workspaceId);
    const workspaceSettings = useWorkspaceSettings(workspaceId);
    const workspaceLayouts = useWorkspaceLayouts(workspaceId);

    /**
     * WorkspaceProvider persists previous value in local storage. This is problematic from AP perspective as
     * here workspace context can change during user's session.
     *
     * This solution is a workaround/solution for the problem, at least while this use case is not addressed in
     * Engage-Components. Could be permanent solution also :)
     */
    useEffect(() => {
        if (storedValue !== workspaceId) {
            setStoredValue(workspaceId);
        }
    }, [setStoredValue, storedValue, workspaceId]);

    const renderLoader = useCallback((text: string) => <EngageLoader text={text} />, []);

    if (workspace.loading || workspaceLayouts.loading || workspaceSettings.loading || storedValue !== workspaceId) {
        return renderLoader('Loading workspace..');
    }

    return (
        <WorkspaceProvider
            initialWorkspace={defaultWorkspace || workspace.result}
            initialWorkspaceLayouts={workspaceLayouts.result}
            initialWorkspaceSettings={workspaceSettings.result}
            fetchWorkspaceAsynchronously={false}
            renderLoader={() => renderLoader('Initialising workspace..')}
            showLoading={false}
        >
            {children}
        </WorkspaceProvider>
    );
};

export default EngageWorkspaceWrapper;
