import React, { JSXElementConstructor } from 'react';
import { useOutlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
        padding: 32,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
}));

export type ErrorWrapperProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout: JSXElementConstructor<any>;
    LayoutContainerProps: DefaultLayoutContainerProps;
};

const ErrorWrapper = ({ layout: Layout, LayoutContainerProps }: ErrorWrapperProps) => {
    const { classes } = useStyles();

    const outlet = useOutlet();

    return (
        <Layout dark={false} LayoutContainerProps={LayoutContainerProps}>
            <Box component="article" className={classes.root}>
                {outlet}
            </Box>
        </Layout>
    );
};

export default ErrorWrapper;
