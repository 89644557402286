import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';
import { UACClient } from '@zetadisplay/connect-userautocreation-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { UacConfigurationOptions } from '../automated-user-creation';
import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import convertFormDataToPayload from '../utils/convert-form-data-to-payload';

const persistUacAction = async <T extends UAC_CONFIGURATION_FORM_VALUES>(
    api: UACClient,
    data: T,
    options: UacConfigurationOptions,
    edit: boolean
) => {
    try {
        const result = await api.script.scriptUploadPost(data.id, convertFormDataToPayload(data));

        if (result.statusCode === 200) {
            options.notify(`UAC configuration been ${(edit && 'updated') || 'created'} successfully`, [], 'success');

            if (options.callback) {
                options.callback();
            }
        } else {
            options.notify(`Persisting UAC configuration failed`, [], 'error');
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default persistUacAction;
