import { useAsyncAbortable } from 'react-async-hook';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';

const useCurrentUser = (defaultUser?: UserDto) => {
    const api = useAdminPanelApi();

    const snackbar = useTranslatedSnackbar();

    return useAsyncAbortable(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (abortSignal) => {
            if (defaultUser) {
                return defaultUser;
            }

            return api.userProfile.getProfile().then((data) => {
                return data;
            });
        },
        [api.userProfile, defaultUser],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
        }
    );
};

export default useCurrentUser;
