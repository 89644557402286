import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';

import handleAdminPanelResponseError from './handle-admin-panel-response-error';

type SnackbarFunc = (
    message: string,
    params: string[],
    type: 'error' | 'info' | 'success',
    autoHideDuration: number | null
) => void;

const handleResponseError = async (error: ApiError, notify: SnackbarFunc) => {
    const messages = handleAdminPanelResponseError(error);

    if (Array.isArray(messages)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const message of messages) {
            notify(message, [], 'error', 10000);
        }
    } else {
        notify(messages, [], 'error', 10000);
    }
};

export default handleResponseError;
