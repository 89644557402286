import React, { useCallback } from 'react';
import { SubWorkspaceProvider } from '@zetadisplay/engage-components/modules/workspaces';

import EngageLoader from '../engage-loader/engage-loader';
import { EngageWorkspaceWrapperProps } from './engage-workspace-wrapper';

const EngageSubWorkspaceWrapper = ({ children, workspaceId }: React.PropsWithChildren<EngageWorkspaceWrapperProps>) => {
    const renderLoader = useCallback(() => <EngageLoader text="Initialising workspace.." />, []);

    return (
        <SubWorkspaceProvider subWorkspaceId={workspaceId} renderLoader={renderLoader}>
            {children}
        </SubWorkspaceProvider>
    );
};

export default EngageSubWorkspaceWrapper;
