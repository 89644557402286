import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { ApplicationDto, CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { SearchFilterOption } from '@zetadisplay/zeta-ui-components';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';

import useResults from '../../../hooks/use-results';
import getResultData from '../../../utils/get-result-data';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import { useCustomerCreatedEventListener } from '../events/customer-created-event';
import { useCustomerRemovedEventListener } from '../events/customer-removed-event';
import { useCustomerSearchEventListener } from '../events/customer-search-event';
import { useCustomerUpdatedEventListener } from '../events/customer-updated-event';
import { CustomersSearchFilters } from './use-customers-search-filters';

export type UseCustomersReturnType = {
    data: CustomerDto[];
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<CustomerDto[] | undefined>;
};

export type CustomerSearchState = {
    id?: string[];
    externalSystem?: ApplicationDto.externalSystem[];
    parentCustomerId?: SearchFilterOption<string>[] | undefined;
    search?: string;
    status?: 'active' | 'disabled' | undefined;
};

const initialState = {
    id: [],
    externalSystem: [],
    parentCustomerId: [],
    search: '',
    status: undefined,
};

const useCustomers = (
    customerSearchState?: CustomerSearchState | undefined,
    disabled = false,
    listenSearchEvent = true,
    domain: string | undefined = undefined
): UseCustomersReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const storedValues = (domain && sessionStorage.getItem(domain)) || null;

    const [queryParams, setQueryParams] = useState<CustomerSearchState>(
        customerSearchState || (storedValues && JSON.parse(storedValues)) || initialState
    );

    const { results, removeResult, setResult, setResults } = useResults<CustomerDto>(JSON.stringify(queryParams));

    const customers = useAsyncAbortable<CustomerDto[] | undefined>(
        async () => {
            if (disabled) {
                return undefined;
            }

            return api.customers
                .getCustomers(
                    queryParams.id,
                    queryParams.externalSystem,
                    queryParams.parentCustomerId?.map((item) => item.value),
                    queryParams.search,
                    queryParams.status
                )
                .then((data) => setResults(data));
        },
        [
            api.customers,
            disabled,
            queryParams.externalSystem,
            queryParams.id,
            queryParams.parentCustomerId,
            queryParams.search,
            queryParams.status,
            setResults,
        ],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    useCustomerCreatedEventListener((data: CustomerDto) => {
        setResult(data);
    });

    useCustomerUpdatedEventListener((data: CustomerDto) => {
        setResult(data);
    });

    useCustomerRemovedEventListener((data: CustomerDto) => {
        removeResult(data);
    });

    useCustomerSearchEventListener((data: SearchState<CustomersSearchFilters>) => {
        if (listenSearchEvent) {
            setQueryParams(data as CustomerSearchState);
        }
    });

    return {
        data: getResultData(results),
        error: customers.error,
        isLoading: customers.loading,
        refresh: customers.execute,
    };
};

export default useCustomers;
