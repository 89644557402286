import { useAsyncAbortable } from 'react-async-hook';
import { ApplicationDto, CustomerDto, UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';

export type UseUsersBatchesReturnType = {
    data: UserBatchDto[] | undefined;
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<UserBatchDto[] | undefined>;
};

const useUserBatches = (customer: CustomerDto, applications?: ApplicationDto[]): UseUsersBatchesReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const userBatches = useAsyncAbortable<UserBatchDto[] | undefined>(
        async () => {
            if (!customer || !applications) {
                return undefined;
            }

            return api.userBatch
                .getUserBatches(
                    customer.id,
                    applications.map((application) => application.id)
                )
                .then((data) => data);
        },
        [api.userBatch, applications, customer],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    return {
        data: userBatches.result,
        error: userBatches.error,
        isLoading: userBatches.loading,
        refresh: userBatches.execute,
    };
};

export default useUserBatches;
