import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { makeStyles, themeOptions, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../../routing/constants';

const { ORANGE } = themeOptions.colors;
const ICON_SIZE = 18;

const useStyles = makeStyles()(() => ({
    actions: {
        alignItems: 'baseline',
        display: 'flex',
    },
}));

export type UserBatchListActionsProps = {
    userBatch: UserBatchDto;
};

const UserBatchListActions = ({ userBatch }: UserBatchListActionsProps) => {
    const { classes } = useStyles();
    const theme: ZetaTheme = useTheme();

    const isDisabled =
        userBatch.batchUsers.length === 0 ||
        [UserBatchDto.status.PROCESSING, UserBatchDto.status.PENDING].includes(userBatch.status);

    return (
        <Box component="div" className={classes.actions}>
            <IconButton
                to={`/${ACCESS_CONFIGURATION_BASE_PATH}/user-imports/${userBatch.id}/report`}
                disabled={isDisabled}
                component={RouterLink}
                data-testid="report-link"
                size="small"
                title="Report"
            >
                <Icon
                    color={(isDisabled && theme.palette.background.disabled) || ORANGE}
                    type="REPORT"
                    size={ICON_SIZE}
                />
            </IconButton>
        </Box>
    );
};

export default UserBatchListActions;
