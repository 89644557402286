import { useMemo } from 'react';
import { Box } from '@mui/material';
import { BatchUserDTO } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../../components/tag/tag';

export type BatchUserStatusProps = {
    batchUser: BatchUserDTO;
};

const BatchUserStatus = ({ batchUser }: BatchUserStatusProps) => {
    const type = useMemo(() => {
        switch (batchUser.status) {
            case BatchUserDTO.status.FAILED:
                return 'RED';
            default:
                return 'GREEN';
        }
    }, [batchUser.status]);

    return (
        <Box>
            <Tag text={batchUser.status} type={type} />
        </Box>
    );
};

export default BatchUserStatus;
