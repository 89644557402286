import React, { JSXElementConstructor } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { ConditionalWrapper } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import CustomerProvider from '../../modules/customers/context/customer-provider';
import { CustomerSearchState } from '../../modules/customers/hooks/use-customers';
import uacConfig from '../../modules/users/automated-user-creation/config/uac-config';
import AutomatedUserCreationProvider from '../../modules/users/automated-user-creation/context/automated-user-creation-provider';
import ViewHeader from './view-header';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
        padding: 18,
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
    },
}));

export type ContentWrapperProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: JSXElementConstructor<any>;
    customerSearchState?: CustomerSearchState;
    title: string;
    useAutomatedUserCreationContext?: boolean;
    useCustomerContext?: boolean;
    useCustomerContextChanger?: boolean;
};

const ContentWrapper = ({
    component: Component,
    customerSearchState,
    title,
    useAutomatedUserCreationContext = false,
    useCustomerContext = false,
    useCustomerContextChanger = false,
}: ContentWrapperProps) => {
    const { classes } = useStyles();

    return (
        <Box component="article" className={classes.root}>
            <ViewHeader customerContextChanger={useCustomerContextChanger} title={title} />

            <ConditionalWrapper
                condition={useCustomerContext}
                render={(children) => (
                    <CustomerProvider customerSearchState={customerSearchState}>{children}</CustomerProvider>
                )}
            >
                <ConditionalWrapper
                    condition={useAutomatedUserCreationContext}
                    render={(children) => (
                        <AutomatedUserCreationProvider apiConfig={uacConfig}>
                            <NiceModal.Provider>{children}</NiceModal.Provider>
                        </AutomatedUserCreationProvider>
                    )}
                >
                    <Component />
                </ConditionalWrapper>
            </ConditionalWrapper>
        </Box>
    );
};

export default ContentWrapper;
