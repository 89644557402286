import { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomerDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput, SelectOption, TextInput } from '@zetadisplay/engage-components';
import { Accordion } from '@zetadisplay/zeta-ui-components';
import { makeStyles, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import useAuthorization from '../../authorization/hooks/use-auth';
import useCurrentUser from '../../authorization/hooks/use-current-user';
import useCustomers from '../hooks/use-customers';

const useStyles = makeStyles()(() => ({
    root: {
        padding: '0 24px 0 6px',
    },
}));

export type CustomerProfileProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    customer?: CustomerDto;
    defaultValues: {
        name?: string;
        logoUrl?: string;
        contact?: string;
        phoneNumber?: string;
        language?: string;
        parentCustomerId?: string;
    };
};

export type CustomerTreeNode = CustomerDto & {
    children: CustomerTreeNode[];
    depth: number;
};

export const treeNodeSort = (a: CustomerTreeNode, b: CustomerTreeNode) => {
    if (a.name < b.name) return -1;
    return a.name > b.name ? 1 : 0;
};

/**
 * Customer hierarchy tree to listable flat list with depths.
 *
 * @param tree
 * @returns
 */
export const treeToSelector = (tree: CustomerTreeNode[]): CustomerTreeNode[] => {
    const list: CustomerTreeNode[] = [];
    let depth = 0;
    const traverseNodes = (node: CustomerTreeNode) => {
        depth += 1;
        list.push({ ...node, depth });
        node.children.forEach((childNode) => {
            traverseNodes(childNode);
        });
        node.children = node.children.sort(treeNodeSort);
        depth -= 1;
    };
    tree.forEach((e) => traverseNodes(e));

    return list;
};

const CustomerProfile = ({ control, customer, defaultValues }: CustomerProfileProps) => {
    const { classes } = useStyles();
    const customers = useCustomers();
    const context = useAuthorization();
    const theme: ZetaTheme = useTheme();
    const user = useCurrentUser();

    const languageOptions: SelectOption[] = [
        { id: 'en-US', name: 'English (US)' },
        { id: 'fi-FI', name: 'Finnish' },
    ];

    const parentOptions = useMemo(() => {
        // If customer has parent which is not available in the list, user is modifying a customer whose parent is not
        // available to him - we need to use only this one and prevent user from changing it.
        if (customer?.parentCustomerId && !customers.data?.find((c) => c.id === customer.parentCustomerId)) {
            return [{ name: customer.parentCustomer?.name, id: customer.parentCustomerId, disabled: true }];
        }

        const data =
            (user.type === UserDto.type.ADMINISTRATOR && [{ name: 'No parent', id: '' }, ...(customers.data || [])]) ||
            customers.data ||
            [];

        return (
            data
                .filter((item) => context.can('read', 'Customer', item.id))
                .filter((item) => item.id !== customer?.id)
                .map((item) => {
                    return {
                        name: item.name,
                        id: item.id || '',
                    };
                }) || []
        );
    }, [customer, customers.data, user.type, context]);

    return (
        <Accordion defaultExpanded title="Profile">
            <div className={classes.root} data-testid="customer-profile">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Name"
                            defaultValue={defaultValues.name || ''}
                            name="customer.name"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Logo URL"
                            defaultValue={defaultValues.logoUrl || ''}
                            name="customer.logoUrl"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Contact person"
                            defaultValue={defaultValues.contact || ''}
                            name="customer.contact"
                            required
                            rules={{
                                required: 'common.validation.required',
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Contact phone number"
                            defaultValue={defaultValues.phoneNumber || ''}
                            name="customer.phoneNumber"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <SelectInput
                            control={control}
                            defaultValue={defaultValues.language || 'en-US'}
                            fullWidth
                            label="Language"
                            LabelProps={{
                                backgroundColor:
                                    (theme.palette.dark && theme.palette.background.content) ||
                                    theme.palette.background.main,
                            }}
                            name="customer.language"
                            options={languageOptions}
                        />
                    </Grid>

                    <Grid item xs={6} data-testid="parent-customer-container">
                        <SelectInput
                            control={control}
                            defaultValue=""
                            fullWidth
                            label="Parent customer"
                            LabelProps={{
                                backgroundColor:
                                    (theme.palette.dark && theme.palette.background.content) ||
                                    theme.palette.background.main,
                            }}
                            loading={customers.isLoading}
                            name="customer.parentCustomerId"
                            options={parentOptions}
                            readOnly={parentOptions.length === 1}
                            required={user.type !== UserDto.type.ADMINISTRATOR}
                            rules={{
                                required:
                                    (user.type !== UserDto.type.ADMINISTRATOR && 'common.validation.required') || false,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </Accordion>
    );
};
export default CustomerProfile;
