import { AbilityBuilder } from '@casl/ability';
import { CustomerDto, RoleDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import getApplicationsAbilities from '../../applications/get-applications-abilities';
import { ALL_ACTION_STRINGS } from '../../authorization/action';
import { AppAbilityType } from '../../authorization/authorization';
import getEngagePlayersAbilities from '../../engage/engage-players/auth/get-engage-players-abilities';
import { CustomerTreeNode } from '../customer-configuration/customer-profile';
import { traverseCustomersBottomsUp } from '../customer-configuration/helper/hierarchy.iterators';
import {
    flattenHierarchy,
    getAdminPanelRolesFromClosestConfiguration,
} from '../customer-configuration/helper/hierarchy.util';
import getCustomersAbilities from './get-customers-abilities';

/**
 * Build a Permission-set for all Customers the User can access
 * @param user
 * @param customers
 * @param limitedFeatures
 * @param param2
 */
const buildCustomerAbilities = (
    user: UserDto,
    customers: CustomerTreeNode[],
    { can, cannot }: AbilityBuilder<AppAbilityType>,
    limitedFeatures: boolean
) => {
    const customerIdMap = flattenHierarchy(customers);

    function addAbility(customer: CustomerDto): void {
        const currentCustomer = customerIdMap.get(customer.id);

        if (currentCustomer !== undefined) {
            // Handle Admin and continue
            if (user.type === UserDto.type.ADMINISTRATOR) {
                can(getCustomersAbilities(user, [], limitedFeatures), 'Customer', currentCustomer.id);
                can(getApplicationsAbilities(user, [], limitedFeatures), 'Application', currentCustomer.id);
                can(getEngagePlayersAbilities(user, [], limitedFeatures), 'PlayerBatch-Engage', currentCustomer.id);
                return;
            }

            // For non-admin users our initial premise is to inherit permissions from root node down the hierarchy
            // In case there are roles in between, those will be applied on the current customer node
            const roles: RoleDto[] | undefined = getAdminPanelRolesFromClosestConfiguration(
                currentCustomer,
                [...customerIdMap.values()],
                user.customers
            );

            if (!roles) {
                cannot(ALL_ACTION_STRINGS, 'Customer', currentCustomer.id);
                cannot(ALL_ACTION_STRINGS, 'Application', currentCustomer.id);
                cannot(ALL_ACTION_STRINGS, 'PlayerBatch-Engage', currentCustomer.id);
                return;
            }

            // User has a Role for current Customer
            const permissions = roles.map((role) => {
                return [...role.permissions];
            });
            const permissionList = permissions.reduce((acc, val) => {
                return [...acc, ...val];
            }, []);

            // Translate to Customer Actions & other actions that are based on the customer context
            const customerAbilities = getCustomersAbilities(user, permissionList, limitedFeatures);
            const engagePlayersAbilities = getEngagePlayersAbilities(user, permissionList, limitedFeatures);
            const applicationsAbilities = getApplicationsAbilities(user, permissionList, limitedFeatures);

            if (customerAbilities) {
                can(customerAbilities, 'Customer', currentCustomer.id);
            } else {
                cannot(ALL_ACTION_STRINGS, 'Customer', currentCustomer.id);
            }

            if (applicationsAbilities) {
                can(applicationsAbilities, 'Application', currentCustomer.id);
            } else {
                cannot(ALL_ACTION_STRINGS, 'Application', currentCustomer.id);
            }

            if (engagePlayersAbilities) {
                can(engagePlayersAbilities, 'PlayerBatch-Engage', currentCustomer.id);
            } else {
                cannot(ALL_ACTION_STRINGS, 'PlayerBatch-Engage', currentCustomer.id);
            }
        }
    }

    customers.map((c) => traverseCustomersBottomsUp(c, addAbility));
};

export default buildCustomerAbilities;
