import { Box, Chip, Skeleton, Typography } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useTemplateShares } from '@zetadisplay/engage-components/modules/templates/hooks';

type TemplateInformationSharesProps = {
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
};
const TemplateInformationShares = ({ template }: TemplateInformationSharesProps) => {
    const shares = useTemplateShares(template);

    return (
        <Box sx={{ width: '250px' }}>
            <Typography sx={{ mb: 0.5 }} variant="h6">
                Shared to
            </Typography>

            {shares.loading && <Skeleton data-testid="skeleton" />}

            {shares.error && <Typography variant="body2">Unable to fetch information</Typography>}

            {shares.result?.workspaces?.length === 0 && !shares.loading && (
                <Typography>Template is not shared</Typography>
            )}

            {shares.result?.workspaces &&
                shares.result?.workspaces?.length > 0 &&
                !shares.loading &&
                shares.result.workspaces.map((workspace) => (
                    <Chip key={workspace.id} label={workspace.name} sx={{ mb: '6px' }} variant="outlined" />
                ))}
        </Box>
    );
};

export default TemplateInformationShares;
