import {
    AdminPanelClient,
    ApiError,
    CreateApplicationDto,
    CustomerDto,
    EditApplicationDto,
} from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { emitCustomerUpdatedEvent } from '../../events/customer-updated-event';
import { CustomerApplicationFormData, CustomerConfigurationOptions } from '../customer-configuration';
import { EDIT_CUSTOMER_FORM_VALUES } from '../edit-customer';

const editCustomerAction = async <T extends EDIT_CUSTOMER_FORM_VALUES>(
    api: AdminPanelClient,
    data: T,
    options: CustomerConfigurationOptions<CustomerDto>
) => {
    try {
        if (!options?.customer?.id) {
            return;
        }

        const customer = await api.customers.editCustomerById(options.customer.id, data.customer);

        // Filter only those Applications which has been enabled while editing the customer and are new
        // Convert the data from EditApplicationDto to CreateApplicationDto
        const createApplicationDtos: CreateApplicationDto[] = data.applications
            .filter((application) => application.data?.enabled === true && application?.object?.id === undefined)
            .map((application) => ({
                enabled: true,
                externalSystem: application.data.externalSystem,
                externalLinkType: application.data.externalLinkType,
                externalEntityName: application.data.externalEntityName,
                externalEntityId: application.data.externalEntityId,
                customerId: options.customer.id,
            }));

        const createApplicationPromises = createApplicationDtos.map((application) => {
            return api.applications.createApplication(options.customer.id, application);
        });

        await Promise.all(createApplicationPromises);

        const editApplicationDtos: CustomerApplicationFormData<EditApplicationDto>[] = data.applications.filter(
            (application) => application?.object?.id !== undefined
        );

        const editApplicationPromises = editApplicationDtos.map((application) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore application.object.id will be se here because previously we filtered out those which are new
            return api.applications.editApplicationById(options.customer.id, application.object.id, application.data);
        });

        await Promise.all(editApplicationPromises);

        emitCustomerUpdatedEvent(customer);

        options.notify(`Customer "${options.customer.name}" has been updated successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default editCustomerAction;
