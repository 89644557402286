import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Box } from '@mui/material';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Accordion, Alert } from '@zetadisplay/zeta-ui-components';
import { dequal } from 'dequal';

import EngageDependencyInjection from '../../../dependency-injection/components/engage-dependency-injection';
import EngageWorkspaceWrapper from '../../../engage/components/engage-workspace-wrapper/engage-workspace-wrapper';
import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationEngageRoles from './uac-configuration-engage-roles';
import UacConfigurationEngageWorkspaces from './uac-configuration-engage-workspaces';

type UacConfigurationEngageProps = {
    addEngageAccess: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'engageAccesses'>;
    addEngageRole: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'engageRoles'>;
    engageAccessFields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'engageAccesses', 'key'>[];
    engageRoleFields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'engageRoles', 'key'>[];
    removeEngageAccess: UseFieldArrayRemove;
    removeEngageRole: UseFieldArrayRemove;
    values: UAC_CONFIGURATION_FORM_VALUES;
};

const UacConfigurationEngage = ({
    addEngageAccess,
    addEngageRole,
    engageAccessFields,
    engageRoleFields,
    removeEngageAccess,
    removeEngageRole,
    values,
}: UacConfigurationEngageProps) => {
    const [engage, setEngage] = useState<ApplicationDto | undefined>(undefined);

    useEffect(() => {
        const customer =
            (values.customers &&
                values.customers.find((item) =>
                    item.customer?.applications?.find(
                        (application) =>
                            application.externalSystem === ApplicationDto.externalSystem.ENGAGE &&
                            application.enabled === true
                    )
                )) ||
            null;

        const customersEngage =
            (customer &&
                customer.customer?.applications?.find(
                    (application) =>
                        application.externalSystem === ApplicationDto.externalSystem.ENGAGE &&
                        application.enabled === true
                )) ||
            undefined;

        if (customersEngage && !dequal(engage, customersEngage)) {
            setEngage(customersEngage);
        }
    }, [values, engage]);

    const enabled = values.applicationAccesses.some((item) =>
        item.applications.some((app) => app.application.id === ApplicationDto.externalSystem.ENGAGE && app.enabled)
    );

    return (
        <Accordion defaultExpanded title="Engage">
            <Box sx={{ maxWidth: '100%' }} data-testid="uac-configuration-engage">
                {!engage && (
                    <Alert
                        dismissible={false}
                        messages={[
                            ' Please select first a customer with an enabled Engage integration to have workspaces and roles available',
                        ]}
                        severity="info"
                    />
                )}

                {engage && (
                    <EngageDependencyInjection>
                        <EngageWorkspaceWrapper workspaceId={engage.externalEntityId as string}>
                            <UacConfigurationEngageWorkspaces
                                enabled={enabled}
                                fields={engageAccessFields}
                                onAdd={addEngageAccess}
                                onRemove={removeEngageAccess}
                            />

                            <UacConfigurationEngageRoles
                                fields={engageRoleFields}
                                enabled={enabled}
                                onAdd={addEngageRole}
                                onRemove={removeEngageRole}
                            />
                        </EngageWorkspaceWrapper>
                    </EngageDependencyInjection>
                )}
            </Box>
        </Accordion>
    );
};

export default UacConfigurationEngage;
