import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SwitchInput } from '@zetadisplay/engage-components';
import {
    ApplicationAdminPanelPruned,
    ApplicationContentEditorPruned,
    ApplicationControlPruned,
    ApplicationEngagePlusPruned,
    ApplicationEngagePruned,
} from '@zetadisplay/zeta-ui-components/assets';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    root: {
        width: 65,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(28px)',
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: '#fff',
            width: 32,
            height: 32,
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: '24px 24px',
            },
        },
    },
    'root--AdminPanel': {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${ApplicationAdminPanelPruned})`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            '&:before': {
                backgroundImage: `url(${ApplicationAdminPanelPruned})`,
            },
        },
    },
    'root--ContentEditor': {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${ApplicationContentEditorPruned})`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            '&:before': {
                backgroundImage: `url(${ApplicationContentEditorPruned})`,
            },
        },
    },
    'root--Engage': {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${ApplicationEngagePruned})`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            '&:before': {
                backgroundImage: `url(${ApplicationEngagePruned})`,
            },
        },
    },
    'root--EngagePlus': {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${ApplicationEngagePlusPruned})`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            '&:before': {
                backgroundImage: `url(${ApplicationEngagePlusPruned})`,
            },
        },
    },
    'root--Control': {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '& .MuiSwitch-thumb:before': {
                    backgroundImage: `url(${ApplicationControlPruned})`,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            '&:before': {
                backgroundImage: `url(${ApplicationControlPruned})`,
            },
        },
    },
}));

type ApplicationIconSwitchProps = {
    defaultChecked: boolean;
    id: ApplicationDto['externalSystem'];
    name: string;
};

const ApplicationIconSwitch = ({ defaultChecked, id, name }: ApplicationIconSwitchProps) => {
    const { classes, cx } = useStyles();

    return (
        <SwitchInput
            name={name}
            defaultChecked={defaultChecked}
            SwitchComponentProps={{
                className: cx({
                    [classes.root]: true,
                    [classes['root--AdminPanel']]: id === ApplicationDto.externalSystem.ADMIN_PANEL,
                    [classes['root--ContentEditor']]: id === ApplicationDto.externalSystem.CONTENT_EDITOR,
                    [classes['root--Control']]: id === ApplicationDto.externalSystem.CONTROL,
                    [classes['root--Engage']]: id === ApplicationDto.externalSystem.ENGAGE,
                    [classes['root--EngagePlus']]: id === ApplicationDto.externalSystem.ENGAGE_PLUS,
                }),
            }}
        />
    );
};

export default ApplicationIconSwitch;
