import React from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { TextInput } from '@zetadisplay/engage-components';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import CustomerInput from '../../../customers/components/customer-input/customer-input';
import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';

const { MEDIUMGRAY, ORANGE } = themeOptions.colors;

type UacConfigurationAdminPanelCustomerProps = {
    canRemove: boolean;
    customers: CustomerDto[];
    data: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'customers', 'key'>;
    index: number;
    onRemove: UseFieldArrayRemove;
};

const UacConfigurationAdminPanelCustomer = ({
    canRemove,
    customers,
    data,
    index,
    onRemove,
}: UacConfigurationAdminPanelCustomerProps) => {
    return (
        <>
            <Grid item xs={5} data-testid={`admin-panel-customer--${index}--value`}>
                <TextInput
                    defaultLabel="Value"
                    name={`customers.${index}.value`}
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>

            <Grid item xs={5} data-testid={`admin-panel-customer--${index}--customer`}>
                <CustomerInput
                    defaultValue={data.customer}
                    loading={false}
                    name={`customers.${index}.customer`}
                    options={customers}
                    required
                />
            </Grid>

            <Grid item xs={2}>
                <IconButton
                    data-testid={`remove-admin-panel-customer--${index}`}
                    disabled={!canRemove}
                    onClick={() => onRemove(index)}
                >
                    <Icon color={(!canRemove && MEDIUMGRAY) || ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </>
    );
};

export default UacConfigurationAdminPanelCustomer;
