import React from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@zetadisplay/zeta-ui-components';

import { DEFAULT_APPLICATION_ACCESS, UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationAdminPanelApplicationAccess from './uac-configuration-admin-panel-application-access';

type UacConfigurationAdminPanelApplicationAccessesProps = {
    fields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'applicationAccesses', 'key'>[];
    onAdd: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'applicationAccesses'>;
    onRemove: UseFieldArrayRemove;
};

const UacConfigurationAdminPanelApplicationAccesses = ({
    fields,
    onAdd,
    onRemove,
}: UacConfigurationAdminPanelApplicationAccessesProps) => {
    return (
        <Box data-testid="uac-configuration-admin-panel-application-accesses" sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
                Application accesses
            </Typography>

            <Grid container spacing={3} sx={{ mb: 1 }}>
                {fields.map((field, index, array) => (
                    <UacConfigurationAdminPanelApplicationAccess
                        key={field.key}
                        canRemove={array.length > 1}
                        field={field}
                        index={index}
                        onRemove={onRemove}
                    />
                ))}
            </Grid>

            <Button name="add-another-application-access" onClick={() => onAdd(DEFAULT_APPLICATION_ACCESS)}>
                Add another rule +
            </Button>
        </Box>
    );
};

export default UacConfigurationAdminPanelApplicationAccesses;
