import { UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Box, IconButton, Typography } from '@mui/material';
import {
    ApplicationDto,
    CreateApplicationAccessConfigurationDto,
    CustomerDto,
    RoleDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { EditApplicationAccessConfigurationDto } from '@zetadisplay/connect-adminpanel-api-client/models/EditApplicationAccessConfigurationDto';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import useCurrentUser from '../../authorization/hooks/use-current-user';
import getUserCustomerRoles from '../utils/get-user-customer-roles';
import RemoveCustomerPrompt from './prompts/remove-customer-prompt';

const { ORANGE, LIGHTGRAY } = themeOptions.colors;
export type UserCustomerHeaderProps = {
    customer?: CustomerDto;
    customers: CustomerDto[];
    index: number;
    remove: UseFieldArrayRemove;
};

const UserCustomerHeader = ({ customer, customers, index, remove }: UserCustomerHeaderProps) => {
    const { getValues } = useFormContext();
    const title = `Customer ${index + 1}`;

    const values = getValues();

    const currentUser = useCurrentUser();
    const userRoles = getUserCustomerRoles(currentUser, values.user?.customerUsers[index].customer, customers);

    const adminPanelConfiguration = values.user?.customerUsers[index]?.applicationAccessConfigurations?.find(
        (aac: CreateApplicationAccessConfigurationDto | EditApplicationAccessConfigurationDto) =>
            aac.application.externalSystem === ApplicationDto.externalSystem.ADMIN_PANEL
    );

    const canDelete =
        currentUser.type === UserDto.type.ADMINISTRATOR ||
        !adminPanelConfiguration?.accessConfiguration ||
        adminPanelConfiguration?.accessConfiguration?.roles.length === 0 ||
        (adminPanelConfiguration?.accessConfiguration &&
            (adminPanelConfiguration.accessConfiguration.roles.length === 0 ||
                (userRoles &&
                    userRoles.some((userRole) =>
                        adminPanelConfiguration.accessConfiguration.roles.every(
                            (role: RoleDto) => !role || role.ranking >= userRole.ranking
                        )
                    ))));

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Typography
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                }}
                variant="h5"
            >
                {title}
            </Typography>

            <IconButton
                disabled={!canDelete}
                onClick={(event) => {
                    event.stopPropagation();
                    return NiceModal.show(RemoveCustomerPrompt, {
                        customer,
                        index,
                        remove,
                    });
                }}
                size="small"
            >
                <Icon color={(canDelete && ORANGE) || LIGHTGRAY} type="DELETE" size={14} />
            </IconButton>
        </Box>
    );
};

export default UserCustomerHeader;
