import React from 'react';
import { Slide, SlideProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition = (props: SlideProps & { children?: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => {
    return <Slide direction="down" {...props} ref={ref} />;
};

const ModalTransition = React.forwardRef(Transition);

export default ModalTransition;
