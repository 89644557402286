import React from 'react';
import { Control, FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Box } from '@mui/material';
import { CreateCustomerUserDto, EditCustomerUserDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Button, ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import useCustomers from '../../customers/hooks/use-customers';
import { CREATE_USER_FORM_VALUES } from './create-user';
import { EDIT_USER_FORM_VALUES } from './edit-user';
import UserCustomer from './user-customer';

const useStyles = makeStyles()(() => ({
    button: {
        marginTop: 6,
    },
}));

export type UserCustomersProps<T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES> = {
    addCustomer: (data?: CreateCustomerUserDto | EditCustomerUserDto) => void;
    control?: Control<T>;
    fields:
        | FieldArrayWithId<CREATE_USER_FORM_VALUES, 'user.customerUsers', 'key'>[]
        | FieldArrayWithId<EDIT_USER_FORM_VALUES, 'user.customerUsers', 'key'>[];
    remove: UseFieldArrayRemove;
    setValue: UseFormSetValue<CREATE_USER_FORM_VALUES> | UseFormSetValue<EDIT_USER_FORM_VALUES>;
    update:
        | UseFieldArrayUpdate<CREATE_USER_FORM_VALUES, 'user.customerUsers'>
        | UseFieldArrayUpdate<EDIT_USER_FORM_VALUES, 'user.customerUsers'>;
    user?: UserDto;
    values: CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES;
};

const UserCustomers = <T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES>({
    addCustomer,
    control,
    fields,
    remove,
    setValue,
    update,
    user,
    values,
}: UserCustomersProps<T>) => {
    const { data, isLoading } = useCustomers();
    const { classes } = useStyles();

    if (isLoading || data === undefined) {
        return <ComponentLoader />;
    }

    return (
        <Box component="div" data-testid="user-customers">
            {fields.map((customerUser, index) => (
                <UserCustomer<T>
                    key={customerUser.key}
                    control={control}
                    // customer users are passed from values due to field array state issues, as it tries
                    // to reduce re-rendering but our nested application accesses need up-to-date data
                    customerUsers={values.user.customerUsers}
                    customers={data || []}
                    index={index}
                    remove={remove}
                    setValue={setValue}
                    update={update}
                    user={user}
                />
            ))}

            <Button className={classes.button} name="add-another-customer" onClick={() => addCustomer()}>
                {(fields.length > 0 && 'Add another customer +') || 'Add access to customer +'}
            </Button>
        </Box>
    );
};

export default UserCustomers;
