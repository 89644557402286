import { useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { LocationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useCountries, useTimeZones } from '@zetadisplay/engage-components/hooks';

export type PlayerBatchReportLocationProps = {
    location: LocationDto;
};

const PlayerBatchReportLocation = ({ location }: PlayerBatchReportLocationProps) => {
    const countries = useCountries();
    const timezones = useTimeZones();

    const country = useMemo(() => {
        if (countries.loading || !countries.result) {
            return undefined;
        }

        return countries.result.items.find((item) => item.id === location.countryId);
    }, [countries.loading, countries.result, location.countryId]);

    const timezone = useMemo(() => {
        if (timezones.loading || !timezones.result) {
            return undefined;
        }

        return timezones.result.items.find((item) => item.id === location.timezoneId);
    }, [location.timezoneId, timezones.loading, timezones.result]);

    return (
        <div>
            <Typography variant="h3">Location</Typography>
            <Typography>Address: {location.address}</Typography>
            <Typography>Zipcode: {location.zipcode}</Typography>
            <Typography>City: {location.city}</Typography>

            {!country && <Skeleton />}
            {country && <Typography>Country: {country.name}</Typography>}

            {!timezone && <Skeleton />}
            {timezone && <Typography>Timezone: {timezone.daylightName}</Typography>}
        </div>
    );
};

export default PlayerBatchReportLocation;
