import React from 'react';
import { FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { TemplatesApiShareTemplateRequest } from '@zetadisplay/engage-api-client/api/templates-api';
import { Button } from '@zetadisplay/zeta-ui-components';

import { FormType } from './share-template-modal';
import WorkspaceField from './workspace-field';

type WorkspacesProps = {
    fields: FieldArrayWithId<
        TemplatesApiShareTemplateRequest & { body: { id: string | undefined }[] },
        'body',
        'key'
    >[];
    onAddWorkspace: () => void;
    onRemove: UseFieldArrayRemove;
    onUpdate: UseFieldArrayUpdate<FormType, 'body'>;
};

const Workspaces = ({ fields, onAddWorkspace, onRemove, onUpdate }: WorkspacesProps) => {
    return (
        <Box data-testid="engage-workspace-access">
            <Typography variant="h5" sx={{ marginBottom: '12px' }}>
                Workspaces
            </Typography>

            {fields.map(({ id, key }, index) => (
                <WorkspaceField
                    key={key}
                    index={index}
                    onRemoveWorkspace={onRemove}
                    onSelectWorkspace={onUpdate}
                    workspaceId={id}
                />
            ))}

            <Box>
                <Button
                    disabled={fields.some(({ id }) => !id)}
                    name="add-another-workspace"
                    onClick={() => onAddWorkspace()}
                >
                    Add another workspace +
                </Button>
            </Box>
        </Box>
    );
};

export default Workspaces;
