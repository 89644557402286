import env from '@beam-australia/react-env';
import { OktaAuthOptions } from '@okta/okta-auth-js';

const clientRoot = env('CLIENT_ROOT') || 'https://adminpanel.local';
const deploymentPath = process.env.PUBLIC_URL || '/';
const redirectUri = `${deploymentPath}/login/callback`.replace(/\/\/+/g, '/');

const oktaConfig: OktaAuthOptions = {
    issuer: env('AUTH_AUTHORITY') || 'https://zetadisplay-test.okta.com/oauth2/aus3uzribu1uJtzga417',
    clientId: env('CLIENT_ID') || '0oa3xegk4i2uN1z5V417',
    redirectUri: `${clientRoot}${redirectUri}`,
    scopes: ['email', 'openid', 'profile', 'offline_access', 'groups'],
    pkce: true,
    devMode: false,
    tokenManager: {
        autoRenew: true,
    },
};

export { clientRoot };
export default oktaConfig;
