import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { EditUserProfileDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { Alert } from '@zetadisplay/zeta-ui-components';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import UserProfileFormFields from './user-profile-form-fields';

export type UserProfileModalProps = {
    externalProfile?: boolean;
} & NiceModalHocProps;

const UserProfileModal = NiceModal.create<UserProfileModalProps>(({ externalProfile = false }) => {
    const api = useAdminPanelApi();
    const modal = useModal();
    const methods = useForm<EditUserProfileDto>({
        defaultValues: () =>
            api.userProfile.getProfile().then((data) => ({
                firstName: data?.firstName || '',
                lastName: data?.lastName || '',
                language: data.language,
                phoneNumber: data.phoneNumber,
                username: data.username,
            })),
    });
    const snackbar = useTranslatedSnackbar();

    const { isLoading } = methods.formState;

    const onSubmit = useCallback(
        async (formValues: EditUserProfileDto) => {
            try {
                const response = await api.userProfile.editProfile(formValues);
                snackbar('Profile has been updated', [], 'success');

                modal.resolve(response);
                modal.hide();
            } catch (e) {
                if (e instanceof Error) {
                    snackbar(handleAdminPanelResponseError(e), [], 'error');
                } else {
                    throw e;
                }
            }
        },
        [api.userProfile, modal, snackbar]
    );

    return (
        <Modal
            actions={{
                buttons: createDefaultButtons({
                    cancel: {
                        disabled: methods.formState.isSubmitting,
                        onClick: modal.hide,
                    },
                    submit: {
                        busy: methods.formState.isSubmitting,
                        disabled: externalProfile || methods.formState.isSubmitting || !methods.formState.isValid,
                        onClick: methods.handleSubmit(onSubmit),
                    },
                }),
            }}
            dark
            keepMounted={false}
            name="user-profile"
            title={{ label: 'Profile' }}
        >
            <FormProvider {...methods}>
                {externalProfile && (
                    <Box sx={{ marginBottom: 2 }}>
                        <Alert
                            dismissible={false}
                            messages={['User profile is managed by external identity provider and can not be updated']}
                            severity="warning"
                        />
                    </Box>
                )}
                <UserProfileFormFields isLoading={isLoading} />
            </FormProvider>
        </Modal>
    );
});

export default UserProfileModal;
