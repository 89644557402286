import React, { useCallback, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NetworkModelsAvailablePlayerSubscription } from '@zetadisplay/engage-api-client';
import { SelectInput, SelectOption } from '@zetadisplay/engage-components';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

export type PlayerSubscriptionProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    disabled: boolean;
    index: number;
    onLicenseChange: (index: number, license: string) => void;
    reservedSubscriptions: Record<number, string>;
    subscriptions: NetworkModelsAvailablePlayerSubscription[] | undefined;
};

const PlayerSubscription = ({
    control,
    disabled,
    index,
    onLicenseChange,
    reservedSubscriptions,
    subscriptions,
}: PlayerSubscriptionProps) => {
    const theme: ZetaTheme = useTheme();

    const availableSubscriptions: SelectOption[] = useMemo(() => {
        return (
            subscriptions?.map((value) => ({
                disabled:
                    (Object.values(reservedSubscriptions).includes(value.id) &&
                        reservedSubscriptions[index] !== value.id &&
                        true) ||
                    false,
                id: value.id,
                name: value.name,
            })) || [{ id: 0, name: '' }]
        );
    }, [index, reservedSubscriptions, subscriptions]);

    const onChangeCallback = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            onLicenseChange(index, event.target.value as string);
        },
        [index, onLicenseChange]
    );

    return (
        <SelectInput
            control={control}
            defaultValue=""
            disabled={disabled || !subscriptions || subscriptions.length === 0}
            fullWidth
            label="License"
            LabelProps={{
                backgroundColor:
                    (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
            }}
            loading={!subscriptions}
            name={`batchPlayers.${index}.license`}
            onChangeCallback={onChangeCallback}
            options={availableSubscriptions}
            renderSelectItem
            required
            rules={{
                required: 'common.validation.required',
            }}
            testId="select-license"
        />
    );
};

export default PlayerSubscription;
