import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import Fab from '../../components/fab/fab';
import { APP_NAME } from '../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../routing/constants';
import useAuthorization from '../authorization/hooks/use-auth';
import CustomerList from './components/customer-list/customer-list';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

export type CustomersOptions = {
    notify: (message: string, params: string[], type: 'error' | 'info' | 'success') => void;
};

const Customers = () => {
    useDocumentTitle(APP_NAME, ['Customers']);
    const { classes } = useStyles();
    const context = useAuthorization();

    const navigate = useNavigate();

    const onFabClick = useCallback(() => {
        navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/customers/create`);
    }, [navigate]);

    return (
        <Box component="section" className={classes.root} data-testid="customers">
            <Fab action={onFabClick} disabled={!context.can('manage', 'Customer')} name="add-customer" />

            <CustomerList />
        </Box>
    );
};

export default Customers;
