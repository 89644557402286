import NiceModal from '@ebay/nice-modal-react';
import { Box, Typography } from '@mui/material';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APP_NAME } from '../../../config/contants';
import useCustomerContext from '../../customers/hooks/use-customer-context';
import EngageDependencyInjection from '../../dependency-injection/components/engage-dependency-injection';
import EngageWorkspaceWrapper from '../components/engage-workspace-wrapper/engage-workspace-wrapper';
import EngageTemplatesView from './engage-templates-view';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

const EngageTemplates = () => {
    useDocumentTitle(APP_NAME, ['Engage', 'Templates']);
    const { classes } = useStyles();

    const { customer } = useCustomerContext();

    const engageApplication = customer.applications?.find(
        (application) => application.externalSystem === ApplicationDto.externalSystem.ENGAGE
    );

    const applicationIsValid =
        engageApplication?.externalEntityId &&
        engageApplication.externalEntityId?.length > 0 &&
        engageApplication.enabled;

    return (
        <Box component="section" className={classes.root} data-testid="engage-templates">
            {!applicationIsValid && <Typography>Application is not valid</Typography>}

            {applicationIsValid && (
                <EngageDependencyInjection>
                    <EngageWorkspaceWrapper workspaceId={engageApplication.externalEntityId as string}>
                        {/* NiceModal.Provider is required to be inside Engage providers */}
                        <NiceModal.Provider>
                            <EngageTemplatesView />
                        </NiceModal.Provider>
                    </EngageWorkspaceWrapper>
                </EngageDependencyInjection>
            )}
        </Box>
    );
};

export default EngageTemplates;
