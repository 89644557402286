import React from 'react';
import { Control } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Box, Typography } from '@mui/material';
import {
    CreateCustomerUserDto,
    CustomerDto,
    EditCustomerUserDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { Alert, ComponentLoader } from '@zetadisplay/zeta-ui-components';

import useCustomerApplications from '../../customers/hooks/use-customer-applications';
import { CREATE_USER_FORM_VALUES } from './create-user';
import { EDIT_USER_FORM_VALUES } from './edit-user';
import UserApplication from './user-application';

export type UserApplicationsProps<T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES> = {
    control?: Control<T>;
    customers: CustomerDto[];
    customerUsers: CreateCustomerUserDto[] | EditCustomerUserDto[];
    index: number;
    setValue: UseFormSetValue<CREATE_USER_FORM_VALUES> | UseFormSetValue<EDIT_USER_FORM_VALUES>;
    user?: UserDto;
};

const UserApplications = <T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES>({
    control,
    customers,
    customerUsers,
    index,
    setValue,
    user,
}: UserApplicationsProps<T>) => {
    const customerUser = customerUsers[index];
    const { data, isLoading } = useCustomerApplications(customerUser.customer.id);

    if (isLoading && !data) {
        return <ComponentLoader />;
    }

    return (
        <Box component="div" data-testid="user-applications">
            <Typography variant="h6">Applications</Typography>

            {(!data || data?.length === 0) && (
                <Box sx={{ paddingTop: '6px' }}>
                    <>
                        {!data && <Typography>Select customer to see application options</Typography>}

                        {data?.length === 0 && (
                            <Alert
                                messages={[`${customerUser.customer.name} does not have any configured applications.`]}
                                severity="info"
                            />
                        )}
                    </>
                </Box>
            )}

            {data?.map((application, applicationIndex) => {
                return (
                    <UserApplication
                        key={application.id}
                        application={application}
                        applicationIndex={applicationIndex}
                        control={control}
                        customerApplications={data}
                        customerUser={customerUser}
                        customerUserIndex={index}
                        customers={customers}
                        setValue={setValue}
                        user={user}
                    />
                );
            })}
        </Box>
    );
};

export default UserApplications;
