import { useState } from 'react';
import { Control } from 'react-hook-form';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ApplicationDto, CreateApplicationDto, EditApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { Application } from '../../applications/application';
import EngageSettings from './application-settings/engage-settings/engage-settings';
import { CREATE_CUSTOMER_FORM_VALUES } from './create-customer';
import { EDIT_CUSTOMER_FORM_VALUES } from './edit-customer';

const useStyles = makeStyles()(() => ({
    dialogTitle: {
        paddingLeft: 62,
        textTransform: 'initial',
    },
}));

export type CustomerApplicationProps<T extends CREATE_CUSTOMER_FORM_VALUES | EDIT_CUSTOMER_FORM_VALUES> = {
    application: CreateApplicationDto | EditApplicationDto;
    canConfigure: boolean;
    config: Application;
    control?: Control<T>;
    index: number;
};

const CustomerApplication = <T extends CREATE_CUSTOMER_FORM_VALUES | EDIT_CUSTOMER_FORM_VALUES>({
    application,
    canConfigure,
    config,
    control,
    index,
}: CustomerApplicationProps<T>) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const renderSettings = () => {
        switch (config.id) {
            case ApplicationDto.externalSystem.ENGAGE:
                return <EngageSettings application={application} control={control} index={index} />;
            default:
                return <div>Not supported yet</div>;
        }
    };

    return (
        <section data-testid={`customer-application-${config.id}`}>
            <Button
                disabled={!canConfigure}
                name="configure-application"
                onClick={() => setOpen((state) => !state)}
                size="small"
                sx={{
                    minWidth: 24,
                }}
            >
                <SettingsIcon />
            </Button>

            <Dialog data-testid={`customer-application-settings-${config.id}`} fullWidth maxWidth="sm" open={open}>
                <DialogTitle className={classes.dialogTitle}>Application configuration - {config.name}</DialogTitle>
                <DialogContent>{renderSettings()}</DialogContent>
                <DialogActions>
                    <Button
                        kind="primaryAction"
                        label={t.trans('common.action.continue')}
                        onClick={() => setOpen(false)}
                    />
                </DialogActions>
            </Dialog>
        </section>
    );
};

export default CustomerApplication;
