import { Skeleton, TableCell, TableRow } from '@mui/material';

type TableRowSkeletonProps = {
    rows: number;
    cells: number;
};

const TableRowSkeleton = ({ rows, cells }: TableRowSkeletonProps) => {
    return (
        <>
            {[...Array(rows)].map((row, rowIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={`skeleton-row-${rowIndex}`} data-testid={`skeleton-row-${rowIndex}`}>
                    {[...Array(cells)].map((cell, cellIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <TableCell key={`skeleton-row-${rowIndex}-cell-${cellIndex}`} data-testid="skeleton">
                            <Skeleton />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    );
};

export default TableRowSkeleton;
