import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

type Errors = ApiError | Error;

const isApiError = (error: Errors): error is ApiError => (error as ApiError).body !== undefined;

const handleAdminPanelResponseError = (error: Errors): string => {
    return (isApiError(error) && error?.body?.message) || error.message || DEFAULT_ERROR_MESSAGE;
};

export default handleAdminPanelResponseError;
