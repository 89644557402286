import React from 'react';
import { AuthInfo } from '@zetadisplay/engage-components/modules/auth';
import { Theme } from '@zetadisplay/zeta-ui-components';

const CallbackErrorComponent = ({ error }: { error: Error }) => {
    return (
        <Theme>
            <AuthInfo
                action={() => {
                    window.location.href = process.env.REACT_APP_CLIENT_ROOT || '/';
                }}
                actionText="Back to Login page"
                text={error.message}
            />
        </Theme>
    );
};

export default CallbackErrorComponent;
