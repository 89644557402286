import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Alert } from '@zetadisplay/zeta-ui-components';

import getSeverityForAlert from '../../../../utils/get-severity-for-alert/get-severity-for-alert';
import UserBatchListActions from './user-batch-list.actions';
import UserBatchStatus from './user-batch-status';

type UserBatchListRowProps = {
    userBatch: UserBatchDto;
};
const UserBatchListRow = ({ userBatch }: UserBatchListRowProps) => {
    const [open, setOpen] = useState(false);

    const lastUpdated = new Date(userBatch.updatedAt);

    const hasMessages = userBatch.messages?.length > 0 || false;

    const tableCellSx = {
        borderBottom: (hasMessages && 'none') || undefined,
    };

    return (
        <>
            <TableRow data-testid={`user-batch-row-${userBatch.id}`}>
                <TableCell sx={{ ...tableCellSx, padding: '6px' }}>
                    <IconButton
                        aria-label="expand row"
                        data-testid="expander"
                        disabled={!hasMessages}
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell sx={tableCellSx}>{userBatch.application.externalSystem}</TableCell>

                <TableCell sx={tableCellSx}>
                    <UserBatchStatus userBatch={userBatch} />
                </TableCell>

                <TableCell data-testid="user-count" sx={tableCellSx}>
                    {userBatch.batchUsers.length}
                </TableCell>

                <TableCell sx={tableCellSx}>{userBatch.createdBy?.username}</TableCell>

                <TableCell data-testid="last-updated" sx={tableCellSx}>
                    {lastUpdated.toLocaleString()}
                </TableCell>

                <TableCell sx={tableCellSx}>
                    <UserBatchListActions userBatch={userBatch} />
                </TableCell>
            </TableRow>

            {hasMessages && (
                <TableRow data-testid={`user-batch-row-messages-${userBatch.id}`}>
                    <TableCell colSpan={7} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {userBatch.messages.map((message) => (
                                <Alert
                                    key={message.message}
                                    dismissible={false}
                                    messages={[message.message]}
                                    severity={getSeverityForAlert(message.type)}
                                />
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default UserBatchListRow;
