import { Control } from 'react-hook-form';
import { Accordion } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APPLICATIONS } from '../../applications/application';
import ApplicationSwitch from '../../applications/application-switch/application-switch';
import useCurrentUser from '../../authorization/hooks/use-current-user';
import { CREATE_CUSTOMER_FORM_VALUES } from './create-customer';
import CustomerApplication from './customer-application';
import { CustomerApplicationFormData } from './customer-configuration';
import { EDIT_CUSTOMER_FORM_VALUES } from './edit-customer';

const useStyles = makeStyles()(() => ({
    root: {
        padding: '0 24px 0 6px',
    },
}));

export type CustomerApplicationsProps<T extends CREATE_CUSTOMER_FORM_VALUES | EDIT_CUSTOMER_FORM_VALUES> = {
    applications: CustomerApplicationFormData[];
    control?: Control<T>;
};

const CustomerApplications = <T extends CREATE_CUSTOMER_FORM_VALUES | EDIT_CUSTOMER_FORM_VALUES>({
    applications,
    control,
}: CustomerApplicationsProps<T>) => {
    const { classes } = useStyles();
    const user = useCurrentUser();

    return (
        <Accordion defaultExpanded title="Applications">
            <div className={classes.root} data-testid="customer-applications">
                {Object.values(applications).map((item, index) => {
                    const application = item.data;

                    const APPLICATION_CONFIG = APPLICATIONS.filter((app) => app.id === application.externalSystem)[0];

                    // Check if application is enabled - either a boolean flag or a callback function which checks
                    // that a pre-condition applies. Some applications are disabled as integration is not done yet
                    // or there is a pre-condition that must be met before the application can be enabled.
                    const isApplicationAvailable =
                        typeof APPLICATION_CONFIG.enabled === 'boolean'
                            ? APPLICATION_CONFIG.enabled
                            : APPLICATION_CONFIG.enabled(user);

                    // Customer application entity can be enabled even if user does not have access to toggle it on/off.
                    // For example users type can prevent from making changes, but we still want to show the application
                    // is set for customer
                    const isCustomerApplicationEnabled: boolean = application.enabled || false;

                    // User can configure application settings when
                    // - application is enabled
                    // - application is configurable in general (not all apps have settings)
                    // - application is enabled for customer - switch is toggled on
                    const userCanConfigure: boolean =
                        isApplicationAvailable && APPLICATION_CONFIG.configurable && isCustomerApplicationEnabled;

                    return (
                        <ApplicationSwitch
                            key={application.externalSystem}
                            config={APPLICATION_CONFIG}
                            defaultChecked={isCustomerApplicationEnabled}
                            disabled={!isApplicationAvailable}
                            control={control}
                            index={index}
                            name={`applications.${index}.data.enabled`}
                            renderConfigurationComponent={() => (
                                <CustomerApplication
                                    application={application}
                                    canConfigure={userCanConfigure}
                                    config={APPLICATION_CONFIG}
                                    control={control}
                                    index={index}
                                />
                            )}
                        />
                    );
                })}
            </div>
        </Accordion>
    );
};

export default CustomerApplications;
