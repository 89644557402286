import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';

const NotFound = () => {
    return (
        <Box sx={{ mt: 14 }}>
            <Box sx={{ margin: '0 auto', width: '600px' }}>
                <Typography variant="h1" sx={{ mb: 2 }}>
                    Not found
                </Typography>

                <Typography sx={{ mb: 3 }}>Content you are looking for does not exist or is not available.</Typography>

                <Link href="/" to="/" component={RouterLink} sx={{ color: '#333', textDecoration: 'underline' }}>
                    Go to Dashboard
                </Link>
            </Box>
        </Box>
    );
};

export default NotFound;
