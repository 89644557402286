import React, { JSXElementConstructor, useEffect } from 'react';
import { useOutlet } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';
import { Loader } from '@zetadisplay/zeta-ui-components';
import { useLocalStorage } from '@zetadisplay/zeta-ui-components/hooks';

import apiConfig from '../../config/apiConfig';
import { AuthorizationProvider } from '../../modules/authorization/context/authorization-provider';
import DependencyInjectionProvider from '../../modules/dependency-injection/components/dependency-injection-provider';
import ProductFruitsWrapper from '../../utils/product-fruits/product-fruits-wrapper';
import Header from './header';
import Navigation from './navigation/navigation';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout: JSXElementConstructor<any>;
    LayoutContainerProps: DefaultLayoutContainerProps;
}

const LayoutViewWrapper = ({ layout: Layout, LayoutContainerProps }: Props) => {
    const [darkMode, setDarkMode] = useLocalStorage<boolean>('admin-panel.options.dark-mode', true);

    const { oktaAuth, authState } = useOktaAuth();
    // Outlet is used in combination with react-router nested routes
    const outlet = useOutlet();

    /**
     * This effect handles auth state,
     * as in https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
     */
    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState?.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth, authState?.isAuthenticated, authState]);

    if (!authState || !authState?.isAuthenticated) {
        return <Loader />;
    }

    return (
        <Layout dark={darkMode} LayoutContainerProps={LayoutContainerProps}>
            <Box sx={{ backgroundColor: 'background.contentSecondary', minHeight: '100vh' }}>
                <DependencyInjectionProvider
                    accessToken={authState?.accessToken?.accessToken}
                    apiConfig={apiConfig.adminPanelApiConfig}
                >
                    <AuthorizationProvider>
                        <NiceModal.Provider>
                            <ProductFruitsWrapper>
                                <Header darkMode={darkMode} setDarkMode={setDarkMode} />

                                <Box sx={{ display: 'flex' }}>
                                    <Navigation />

                                    {outlet}
                                </Box>
                            </ProductFruitsWrapper>
                        </NiceModal.Provider>
                    </AuthorizationProvider>
                </DependencyInjectionProvider>
            </Box>
        </Layout>
    );
};

export default LayoutViewWrapper;
