import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import { ListActionOptions } from '../../../components/types';
import useUser from '../hooks/use-user';
import CreateUser from './create-user';
import EditUser from './edit-user';

export type UserConfigurationOptions = ListActionOptions & {
    callback?: () => void;
    user?: UserDto;
};

const UserConfiguration = () => {
    const { userId } = useParams();

    const user = useUser(userId);

    if ((userId && !user?.result) || user.loading) {
        return <ComponentLoader />;
    }

    return (
        <Box component="section" data-testid="user-configuration">
            {!userId && <CreateUser />}

            {user?.result && <EditUser user={user.result} />}
        </Box>
    );
};

export default UserConfiguration;
