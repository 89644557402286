import React from 'react';
import { Control } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { HiddenInput } from '@zetadisplay/engage-components';
import { Workspace } from '@zetadisplay/engage-components/models';
import { useWorkspaceContent, WorkspaceInput } from '@zetadisplay/engage-components/modules/workspaces';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const { MEDIUMGRAY, ORANGE } = themeOptions.colors;

export type WorkspaceAccessProps = {
    accessConfigurationIndex: number;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    canRemove: boolean;
    customerUserIndex: number;
    formKey: number;
    index: number;
    loading?: boolean;
    onAddWorkspace: (index: number, key: number, value: string | null) => void;
    onRemoveWorkspace: (index: number, key: number) => void;
    workspaceId?: string;
};

const WorkspaceAccess = ({
    accessConfigurationIndex,
    canRemove,
    control,
    customerUserIndex,
    formKey,
    index,
    loading,
    onAddWorkspace,
    onRemoveWorkspace,
    workspaceId,
}: WorkspaceAccessProps) => {
    const workspace = useWorkspaceContent(workspaceId);

    return (
        <Grid container spacing={3} sx={{ marginBottom: '12px' }} data-testid={`workspace-${index}`}>
            <Grid item xs={10} md={4}>
                <HiddenInput
                    control={control}
                    defaultValue=""
                    name={`user.customerUsers.${customerUserIndex}.applicationAccessConfigurations.${accessConfigurationIndex}.accessConfiguration.workspaces.${index}`}
                />
                <WorkspaceInput
                    defaultWorkspaces={(workspace.result && [workspace.result]) || []}
                    includeRootWorkspace
                    label="Workspace"
                    loading={loading || workspace.loading}
                    multiple={false}
                    onChange={(ws: Workspace[]) => onAddWorkspace(index, formKey, ws[0]?.id || null)}
                    required
                />
            </Grid>

            <Grid item xs={2} md={8}>
                <IconButton disabled={!canRemove} onClick={() => onRemoveWorkspace(index, formKey)}>
                    <Icon color={(!canRemove && MEDIUMGRAY) || ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default WorkspaceAccess;
