import { useMemo } from 'react';
import { Box } from '@mui/material';
import { PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../../components/tag/tag';

export type PlayerBatchStatusProps = {
    playerBatch: PlayerBatchDto;
};

const PlayerBatchStatus = ({ playerBatch }: PlayerBatchStatusProps) => {
    const type = useMemo(() => {
        switch (playerBatch.status) {
            case PlayerBatchDto.status.PROCESSING:
                return 'YELLOW';
            case PlayerBatchDto.status.EXECUTED:
                return 'GREEN';
            case PlayerBatchDto.status.FAILED:
                return 'RED';
            case PlayerBatchDto.status.READY:
            default:
                return 'DEFAULT';
        }
    }, [playerBatch.status]);

    return (
        <Box>
            <Tag text={playerBatch.status} type={type} />
        </Box>
    );
};

export default PlayerBatchStatus;
