import { useCallback } from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';

type RemoveCustomerPromptProps = {
    customer?: CustomerDto;
    index: number;
    remove: UseFieldArrayRemove;
};

const RemoveCustomerPrompt = NiceModal.create<RemoveCustomerPromptProps>(({ customer, index, remove }) => {
    const action = useCallback(async () => {
        remove(index);
        return Promise.resolve(true);
    }, [index, remove]);

    return (
        <ConfirmPrompt<boolean>
            action={() => action()}
            name="remove-customer-prompt"
            submit={{
                label: 'common.action.remove',
                name: 'remove',
            }}
            title={{ label: `Remove customer${(customer && ` ${customer?.name}`) || ''}?` }}
        >
            {customer?.name && (
                <Typography>Are you sure to remove user access to &quot;{customer?.name}&quot;.</Typography>
            )}

            {!customer?.name && <Typography>Are you sure to remove user access to customer?</Typography>}
        </ConfirmPrompt>
    );
});

export default RemoveCustomerPrompt;
