import env from '@beam-australia/react-env';
import { OpenAPIConfig } from '@zetadisplay/connect-userautocreation-api-client';

const uacConfig: OpenAPIConfig = {
    BASE: env('UAC_API_BASE') || 'https://userautocreation.azurewebsites.net',
    VERSION: 'v1',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'omit',
};

export default uacConfig;
