import React, { useMemo } from 'react';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Alert, Loader } from '@zetadisplay/zeta-ui-components';

import useIsBackendUp from '../../../hooks/use-is-backend-up';
import useCustomers from '../../customers/hooks/use-customers';
import useIsEngageUp from '../../engage/hooks/use-is-engage-up';
import useUsers from '../../users/hooks/use-users';
import { buildAbilityFor } from '../config';
import { AbilityContext } from './ability-context';

export type AbilityProviderProps = React.PropsWithChildren<{
    currentUser: UserDto;
}>;

export const AbilityProvider = ({ children, currentUser }: AbilityProviderProps) => {
    const engageIsUp = useIsEngageUp();
    const backendCheck = useIsBackendUp();

    const customers = useCustomers(undefined, !backendCheck.status, false);
    const users = useUsers(!backendCheck.status, false);

    const values = useMemo(
        () => buildAbilityFor(currentUser, customers.data, users.data, !engageIsUp.result),
        [currentUser, customers.data, engageIsUp.result, users.data]
    );

    if (engageIsUp.loading || backendCheck.loading) {
        return <Loader />;
    }

    return (
        <AbilityContext.Provider value={values}>
            {!engageIsUp.result && (
                <Alert
                    dismissible
                    messages={[
                        'Due to technical problems, some features are temporarily limited or disabled. Please try again later.',
                    ]}
                    severity="warning"
                />
            )}
            {children}
        </AbilityContext.Provider>
    );
};
