import React, { useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import env from '@beam-australia/react-env';
import NiceModal from '@ebay/nice-modal-react';
import { Box, IconButton, Link } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { DarkMode, LightMode } from '@styled-icons/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import {
    ActionGroup,
    ApplicationAccessMenu,
    ApplicationListApplicationConfigurations,
    Thumbnail,
    UserMenu,
} from '@zetadisplay/zeta-ui-components';
import { ApplicationAdminPanel } from '@zetadisplay/zeta-ui-components/assets';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import useCurrentUser from '../../modules/authorization/hooks/use-current-user';
import useAdminPanelApi from '../../modules/dependency-injection/hooks/use-admin-panel-api';
import UserProfileModal, { UserProfileModalProps } from '../../modules/user-profile/components/user-profile-modal';
import resetPassword from '../../modules/users/api/reset-password';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: (theme.palette.dark && '#030303') || '#fff',
        display: 'flex',
        height: 84,
        marginBottom: 41,
        padding: '0 14px',
    },
    logo: {
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: 8,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const appConfigs: ApplicationListApplicationConfigurations = {
    AdminPanel: {
        href: process.env.PUBLIC_URL,
    },
    ContentEditor: {
        href: env('CONTENT_EDITOR_URI') || 'https://secure.zetadisplay.com/editor',
    },
    Engage: {
        href: env('ENGAGE_UI_URI') || 'https://engage.zetadisplay.com',
    },
};

type HeaderProps = {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
};

const Header = ({ darkMode, setDarkMode }: HeaderProps) => {
    const { classes, cx } = useStyles();

    const { oktaAuth } = useOktaAuth();
    const currentUser = useCurrentUser();
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const logout = useCallback(
        async () =>
            oktaAuth.signOut({
                postLogoutRedirectUri: process.env.PUBLIC_URL,
                revokeAccessToken: true,
                revokeRefreshToken: true,
            }),
        [oktaAuth]
    );

    const actions: ActionGroup<UserDto | undefined>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => null,
                        isDisabled: () => true,
                        label: (currentUser && `${currentUser.firstName} ${currentUser.lastName}`) || '',
                        name: 'user-info',
                    },
                ],
                name: 'header',
            },
            {
                actions: [
                    {
                        handler: (user) =>
                            NiceModal.show<UserDto, UserProfileModalProps>(UserProfileModal, {
                                externalProfile: user?.externalProfile,
                            }),
                        label: 'Profile',
                        name: 'user-profile',
                    },
                    {
                        handler: async (user) => {
                            if (!user) {
                                return;
                            }

                            await resetPassword(user, api)
                                .then(() =>
                                    snackbar('Password reset request was sent successfully', undefined, 'success')
                                )
                                .catch((error) =>
                                    snackbar(`Password reset request failed: ${error.message}`, undefined, 'error')
                                );
                        },
                        isDisabled: (user) => ((!user || user.externalProfile) && true) || false,
                        label: 'Reset password',
                        name: 'reset-password',
                    },
                    { label: 'Sign out', name: 'sign-out', handler: logout },
                ],
                name: 'actions',
            },
        ];
    }, [currentUser, logout, api, snackbar]);

    const iconColor = useMemo(() => (darkMode ? '#fff' : '#000'), [darkMode]);

    return (
        <header className={cx(classes.root, 'no-printing')} data-testid="header">
            <Link href="/" to="/" component={RouterLink}>
                <div className={classes.logo}>
                    <Thumbnail alt="AdminPanel" size={40} source={ApplicationAdminPanel} />
                </div>
            </Link>

            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', paddingRight: '22px' }}>
                <IconButton data-testid="button-dark-mode" onClick={() => setDarkMode(!darkMode)}>
                    {(darkMode && <LightMode color={iconColor} size={24} />) || (
                        <DarkMode color={iconColor} size={24} />
                    )}
                </IconButton>

                <ApplicationAccessMenu applicationConfigurations={appConfigs} />

                <UserMenu<UserDto | undefined> actions={actions} user={currentUser} />
            </Box>
        </header>
    );
};

export default Header;
