import { Typography } from '@mui/material';
import { ContactDto } from '@zetadisplay/connect-adminpanel-api-client';

export type PlayerBatchReportContactProps = {
    contact: ContactDto | null;
};

const PlayerBatchReportContact = ({ contact }: PlayerBatchReportContactProps) => {
    return (
        <div data-testid="player-batch-report-contact">
            <Typography variant="h3">Contact</Typography>
            <Typography>Email: {contact?.email || '-'}</Typography>
            <Typography>First name: {contact?.firstName || '-'}</Typography>
            <Typography>Last name: {contact?.lastName || '-'}</Typography>
            <Typography>Telephone: {contact?.telephone || '-'}</Typography>
        </div>
    );
};

export default PlayerBatchReportContact;
