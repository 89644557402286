import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ConfigModelsAttributeValueBasic, ConfigModelsAttributeValueDetails } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useApi as useEngageApi } from '@zetadisplay/engage-components/modules/api';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { useAttributeWithValues } from '@zetadisplay/engage-components/modules/players/hooks';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { Autocomplete, Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import LabelValueFormModal, { LabelValueFormModalProps } from '../../components/modals/label-value-form-modal';

const { ORANGE } = themeOptions.colors;

export type PlayerLabelValueProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
    disabled: boolean;
    index: number;
    onRemove: (arg: number) => void;
    playerIndex: number;
    values: Record<string, string | number>;
};

const PlayerLabelValue = ({ control, disabled, index, onRemove, playerIndex, values }: PlayerLabelValueProps) => {
    const { labelId } = values;
    const theme: ZetaTheme = useTheme();

    const { hasRole } = useUserInformation();
    const { workspace } = useWorkspace();
    const api = useEngageApi();

    const attributeWithValues = useAttributeWithValues(labelId as number);

    const showCreateLabelValueForm = useCallback(() => {
        NiceModal.show<ConfigModelsAttributeValueDetails, LabelValueFormModalProps>(LabelValueFormModal, {
            api,
            workspace,
            labelId: values.labelId,
        }).then(() => attributeWithValues.execute());
    }, [api, attributeWithValues, values.labelId, workspace]);

    const options = useMemo(() => {
        return attributeWithValues.result?.attributeValues || [];
    }, [attributeWithValues.result?.attributeValues]);

    return (
        <Grid
            container
            alignItems="center"
            data-testid={`value-for-label-${labelId}`}
            spacing={2}
            sx={{ marginBottom: 2 }}
        >
            <Grid item xs={4}>
                {(attributeWithValues.loading && <Skeleton variant="text" />) || (
                    <Typography title={attributeWithValues.result?.name || '-'} noWrap>
                        {attributeWithValues.result?.name || '-'}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={5} data-testid="select-label-value">
                <Controller
                    control={control}
                    render={({ field: { onChange, value, ...props } }) => (
                        <Autocomplete<DiscriminatedEntity<ConfigModelsAttributeValueBasic>, false, false, false>
                            {...props}
                            ref={null}
                            autoSelect={false}
                            blurOnSelect
                            disabled={
                                disabled ||
                                !attributeWithValues.result?.attributeValues ||
                                attributeWithValues.result?.attributeValues.length === 0
                            }
                            fullWidth
                            getOptionLabel={(option) => {
                                return option?.value;
                            }}
                            isOptionEqualToValue={(option, _value) => {
                                return option.id === _value.id;
                            }}
                            label="Value"
                            loading={attributeWithValues.loading}
                            name={`autocomplete-for-batchPlayers.${playerIndex}.labels.${index}.valueId`}
                            onChange={(event, _value) => {
                                onChange(_value?.id || null);
                            }}
                            options={options}
                            placeholder="Select value"
                            variant="outlined"
                            value={options.find((item) => item?.id === value) || null}
                        />
                    )}
                    name={`batchPlayers.${playerIndex}.labels.${index}.valueId`}
                />
            </Grid>

            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                <IconButton
                    data-testid="add-value"
                    disabled={!hasRole(['ENGAGE_LABEL_MANAGEMENT'])}
                    onClick={showCreateLabelValueForm}
                    size="small"
                    title="Create a new label value"
                >
                    <Icon
                        color={
                            ((!hasRole(['ENGAGE_LABEL_MANAGEMENT']) || attributeWithValues.loading) &&
                                theme.palette.background.disabled) ||
                            ORANGE
                        }
                        type="ADD"
                        size={20}
                    />
                </IconButton>

                <IconButton
                    data-testid="remove-label"
                    disabled={disabled}
                    onClick={() => onRemove(index)}
                    size="small"
                    title="Remove value"
                >
                    <Icon
                        color={
                            ((disabled || attributeWithValues.loading) && theme.palette.background.disabled) || ORANGE
                        }
                        type="DELETE"
                        size={14}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default PlayerLabelValue;
