import { TableCell, TableHead as MuiTableHead, TableRow, Typography } from '@mui/material';

import { ColumnType } from './table';

export type TableHeadProps = {
    columns: ColumnType[];
};
const TableHead = ({ columns }: TableHeadProps) => {
    return (
        <MuiTableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell key={`th_${column.label}`} sx={(column?.sx && { ...column.sx }) || {}}>
                        <Typography noWrap title={column.label} sx={{ fontWeight: 600 }}>
                            {column.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </MuiTableHead>
    );
};

export default TableHead;
