import { AdminPanelClient, ApiError, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ListActionOptions } from '../../../components/types';
import handleResponseError from '../../dependency-injection/utils/handle-response-error';
import { emitUserUpdatedEvent } from '../events/user-updated-event';

const deactivateUserAction = async (
    api: AdminPanelClient,
    user: UserDto,
    options: ListActionOptions
): Promise<UserDto | void> => {
    return api.users
        .deactivateUser(user.id)
        .then((result) => {
            options.notify(`User "${user.username}" has been deactivated successfully`, [], 'success');

            emitUserUpdatedEvent(result);

            return result;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default deactivateUserAction;
