import React, { useCallback, useState } from 'react';
import { Control } from 'react-hook-form';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
    ApplicationDto,
    CreateCustomerUserDto,
    CustomerDto,
    EditCustomerUserDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { Application } from '../../applications/application';
import EngageDependencyInjection from '../../dependency-injection/components/engage-dependency-injection';
import EngageWorkspaceWrapper from '../../engage/components/engage-workspace-wrapper/engage-workspace-wrapper';
import AdminPanelSettings from './application-settings/adminpanel-settings/adminpanel-settings';
import EngageSettings from './application-settings/engage-settings/engage-settings';
import { CREATE_USER_FORM_VALUES } from './create-user';
import { EDIT_USER_FORM_VALUES } from './edit-user';

const useStyles = makeStyles()(() => ({
    dialogTitle: {
        paddingLeft: 62,
        textTransform: 'uppercase',
    },
}));

export type UserApplicationSettingsProps<T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES> = {
    application: ApplicationDto;
    accessConfigurationIndex: number | undefined;
    config: Application;
    control?: Control<T>;
    customerUser: CreateCustomerUserDto | EditCustomerUserDto;
    customerUserIndex: number;
    customers: CustomerDto[];
    enabled: boolean;
    user?: UserDto;
};

const UserApplicationSettings = <T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES>({
    application,
    accessConfigurationIndex = -1,
    control,
    config,
    customerUser,
    customerUserIndex,
    customers,
    enabled,
    user,
}: UserApplicationSettingsProps<T>) => {
    const { classes } = useStyles();
    const t = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    const renderSettings = useCallback(() => {
        switch (config.id) {
            case ApplicationDto.externalSystem.ADMIN_PANEL:
                return (
                    <AdminPanelSettings
                        application={application}
                        accessConfigurationIndex={accessConfigurationIndex}
                        control={control}
                        customerUserIndex={customerUserIndex}
                        customers={customers}
                    />
                );
            case ApplicationDto.externalSystem.ENGAGE:
                if (accessConfigurationIndex < 0) {
                    return null;
                }

                // TODO Add UUID validation to ensure externalEntityId actually is valid Engage workspace
                if (!application.externalEntityId || application.externalEntityId.length === 0) {
                    return <div>Engage application is not configured properly</div>;
                }

                return (
                    <EngageDependencyInjection>
                        <EngageWorkspaceWrapper workspaceId={application.externalEntityId as string}>
                            <EngageSettings
                                application={application}
                                accessConfigurationIndex={accessConfigurationIndex}
                                control={control}
                                customerUser={customerUser}
                                customerUserIndex={customerUserIndex}
                                user={user}
                            />
                        </EngageWorkspaceWrapper>
                    </EngageDependencyInjection>
                );
            default:
                return <div>Application config is not supported</div>;
        }
    }, [config.id, application, accessConfigurationIndex, control, customerUserIndex, customers, customerUser, user]);

    return (
        <section data-testid={`user-application-settings-${config.id}`}>
            <Button
                disabled={!enabled}
                name="configure-application"
                onClick={() => setOpen((state) => !state)}
                size="small"
                sx={{
                    minWidth: 24,
                }}
            >
                <SettingsIcon />
            </Button>

            {enabled && accessConfigurationIndex >= 0 && (
                <Dialog
                    data-testid={`customer-application-settings-${config.id}`}
                    fullWidth
                    keepMounted={false}
                    maxWidth="md"
                    open={open}
                >
                    <DialogTitle className={classes.dialogTitle}>Application configuration - {config.name}</DialogTitle>
                    <DialogContent>{renderSettings()}</DialogContent>
                    <DialogActions>
                        <Button
                            kind="primaryAction"
                            label={t.trans('common.action.continue')}
                            onClick={() => setOpen(false)}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </section>
    );
};

export default UserApplicationSettings;
