import { ApplicationDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Action from '../../../authorization/action';
import getUserPermissions from '../../../users/utils/get-user-permissions';
import userHasApplication from '../../../users/utils/user-has-application';

const getEngageTemplateAbilities = (limitedFeatures: boolean, user?: UserDto): Action | Action[] | undefined => {
    if (!user || limitedFeatures) {
        return undefined;
    }

    const permissions = getUserPermissions(user);

    const hasEngageApplicationAccess = userHasApplication(user, ApplicationDto.externalSystem.ENGAGE);

    if (
        user.type === UserDto.type.ADMINISTRATOR ||
        (hasEngageApplicationAccess && permissions.includes('TEMPLATE_ENGAGE_VIEW_ACCESS'))
    ) {
        return Action.Read;
    }

    return undefined;
};

export default getEngageTemplateAbilities;
