import { ApplicationDto, CustomerDto, PlayerBatchReportDto } from '@zetadisplay/connect-adminpanel-api-client';
import axios from 'axios';

import apiConfig from '../../../../../config/apiConfig';
/**
 * This function uses custom axios fetch as the generated client does not yet support arraybuffer + blob
 * https://github.com/ferdikoomen/openapi-typescript-codegen/issues/981
 * https://github.com/ferdikoomen/openapi-typescript-codegen/pull/1043
 *
 */
const handlePdfDownload = (
    customer: CustomerDto,
    engage: ApplicationDto,
    playerBatchReport: PlayerBatchReportDto,
    accessToken: string
) => {
    axios
        .get(
            `${apiConfig.adminPanelApiConfig.BASE}/${apiConfig.adminPanelApiConfig.VERSION}/api/customer/${customer.id}/application/${engage.id}/playerbatch/${playerBatchReport.id}/report/download/pdf`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                responseType: 'arraybuffer',
            }
        )
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${playerBatchReport.name || playerBatchReport.id}.pdf`);
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
};

export default handlePdfDownload;
