import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import usePortal from 'react-useportal';
import { Box, Typography } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { useSessionStorage } from '@zetadisplay/zeta-ui-components/hooks';

import CustomerContextChanger from '../components/customer-context-changer/customer-context-changer';
import CustomerInput from '../components/customer-input/customer-input';
import useCustomer from '../hooks/use-customer';
import useCustomers, { CustomerSearchState } from '../hooks/use-customers';
import CustomerContext from './customer-context';

export type CustomerProviderProps = React.PropsWithChildren<{
    customerSearchState?: CustomerSearchState;
    initialCustomerId?: string;
}>;

const CustomerProvider = ({ children, customerSearchState, initialCustomerId }: CustomerProviderProps) => {
    const [customerId, setCustomerId] = useSessionStorage<string | undefined>(
        'admin-panel.options.customer',
        initialCustomerId
    );

    const { data, isLoading } = useCustomers(customerSearchState);
    const customer = useCustomer(customerId);
    const methods = useForm();

    const { Portal } = usePortal({
        bindTo: (document && document.getElementById('customerContextChangerPortal')) || undefined,
    });

    const handleCustomerChange = useCallback(
        (arg: CustomerDto) => {
            setCustomerId(arg.id);
        },
        [setCustomerId]
    );

    const values = useMemo(
        () => ({
            changeCustomer: handleCustomerChange,
            customer: customer.result,
            customers: data,
        }),
        [customer.result, data, handleCustomerChange]
    );

    if (isLoading || customer.loading) {
        return (
            <Box data-testid="customer-provider-loader">
                <ComponentLoader />
            </Box>
        );
    }

    if (!customer.result) {
        return (
            <FormProvider {...methods}>
                <Box sx={{ width: '400px' }}>
                    <Typography sx={{ marginBottom: '12px' }}>Please select customer to continue</Typography>

                    <CustomerInput
                        fullWidth
                        loading={isLoading}
                        name="select-customer-context"
                        onChangeCallback={handleCustomerChange}
                        options={data}
                    />
                </Box>
            </FormProvider>
        );
    }

    return (
        <CustomerContext.Provider value={values}>
            <Portal>
                <CustomerContextChanger />
            </Portal>

            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerProvider;
