import { useAsyncAbortable } from 'react-async-hook';
import env from '@beam-australia/react-env';

const useIsEngageUp = () => {
    return useAsyncAbortable(async () => {
        const response = await fetch(`${env('ENGAGE_API_PATH') || 'https://apidev.zetadisplay.com'}/hc`).then(
            (res) => res
        );

        // HOTFIX as apparently healthcheck endpoint is not available on production..
        if (env('STAGE') === 'production') {
            return true;
        }

        if (!response.ok) {
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }, []);
};

export default useIsEngageUp;
