import React, { useCallback } from 'react';
import { Control } from 'react-hook-form';
import { Typography } from '@mui/material';
import { HiddenInput, SwitchInput } from '@zetadisplay/engage-components';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components';
import { ConditionalWrapper } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { Application } from '../application';
import ApplicationIcon from '../application-icon/application-icon';

const useStyles = makeStyles()((theme) => ({
    root: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.background.disabled}`,
        display: 'flex',
        height: 48,
    },
    icon: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '50%',
        display: 'flex',
        height: 30,
        marginLeft: 0,
        marginRight: 12,
        padding: 6,
    },
    name: {
        flexGrow: 1,
    },
    'name--disabled': {
        color: theme.palette.text.disabled,
    },
}));

export type ApplicationSwitchProps = {
    config: Application;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    defaultChecked?: boolean;
    disabled?: boolean;
    index: number;
    name: string;
    onChangeCallback?: (applicationId: string, checked: boolean, value?: boolean) => void;
    renderConfigurationComponent?: () => React.FunctionComponentElement<unknown> | null;
    useFormProvider?: boolean;
};

const ApplicationSwitch = ({
    config,
    control,
    defaultChecked = false,
    disabled = false,
    index,
    name,
    onChangeCallback,
    renderConfigurationComponent,
    useFormProvider = false,
}: ApplicationSwitchProps) => {
    const { classes, cx } = useStyles();

    const onSwitchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value?: boolean) => {
            if (onChangeCallback) {
                onChangeCallback(config.id, event.target.checked, value);
            }
        },
        [config.id, onChangeCallback]
    );

    return (
        <div
            className={classes.root}
            data-testid={`application-switch-${config.id}--${(disabled && 'disabled') || 'enabled'}`}
        >
            <ConditionalWrapper
                condition={useFormProvider}
                render={(children) => <FormProviderWrapper>{children}</FormProviderWrapper>}
            >
                <HiddenInput defaultValue={config.id} name={`applications.${index}.data.externalSystem`} />
                <div className={classes.icon}>
                    <ApplicationIcon application={config} size={20} />
                </div>

                <Typography className={cx({ [classes.name]: true, [classes['name--disabled']]: disabled })}>
                    {config.name}
                </Typography>

                <SwitchInput
                    control={control}
                    defaultChecked={defaultChecked}
                    disabled={disabled}
                    name={name}
                    onChangeCallback={onSwitchChange}
                />
            </ConditionalWrapper>

            {renderConfigurationComponent && renderConfigurationComponent()}
        </div>
    );
};

export default ApplicationSwitch;
