import React, { useCallback } from 'react';
import { Control } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import {
    AdminPanelAccessConfigurationDto,
    ApplicationDto,
    CreateApplicationAccessConfigurationDto,
    CreateCustomerUserDto,
    CustomerDto,
    EditCustomerUserDto,
    RoleDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { EditApplicationAccessConfigurationDto } from '@zetadisplay/connect-adminpanel-api-client/models/EditApplicationAccessConfigurationDto';

import { APPLICATIONS } from '../../applications/application';
import ApplicationSwitch from '../../applications/application-switch/application-switch';
import useCurrentUser from '../../authorization/hooks/use-current-user';
import getUserCustomerRoles from '../utils/get-user-customer-roles';
import { CREATE_USER_FORM_VALUES } from './create-user';
import { EDIT_USER_FORM_VALUES } from './edit-user';
import UserApplicationSettings from './user-application-settings';

export type UserApplicationProps<T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES> = {
    application: ApplicationDto;
    applicationIndex: number;
    control?: Control<T>;
    customerApplications: ApplicationDto[];
    customerUser: CreateCustomerUserDto | EditCustomerUserDto;
    customerUserIndex: number;
    customers: CustomerDto[];
    setValue: UseFormSetValue<CREATE_USER_FORM_VALUES> | UseFormSetValue<EDIT_USER_FORM_VALUES>;
    user?: UserDto;
};

const UserApplication = <T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES>({
    application,
    applicationIndex,
    control,
    customerApplications,
    customerUser,
    customerUserIndex,
    customers,
    setValue,
    user,
}: UserApplicationProps<T>) => {
    const currentUser = useCurrentUser();

    const userRoles = getUserCustomerRoles(currentUser, customerUser.customer, customers);

    const { applicationAccessConfigurations = [] } = customerUser;

    /**
     * Callback function to set customer user applications.
     * In this component we are not relying on the switch & RHF as we want to
     * set only the applications which has been toggled on
     */
    const updateApplication = useCallback(
        (applicationId: string, checked: boolean) => {
            const userApplication = customerApplications.filter(
                (customerApplication) => customerApplication.externalSystem === applicationId
            )[0];

            let updatedAACs = [...applicationAccessConfigurations];

            if (checked) {
                updatedAACs.push({ application: userApplication });
            } else {
                updatedAACs = updatedAACs.filter((aac) => aac.application.id !== userApplication.id);
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO look at this later
            setValue(`user.customerUsers.${customerUserIndex}.applicationAccessConfigurations`, updatedAACs);
        },
        [customerApplications, applicationAccessConfigurations, setValue, customerUserIndex]
    );

    const APPLICATION_CONFIG = APPLICATIONS.filter((app) => app.id === application.externalSystem)[0];

    const isApplicationAvailable =
        typeof APPLICATION_CONFIG.enabled === 'boolean'
            ? APPLICATION_CONFIG.enabled
            : APPLICATION_CONFIG.enabled(currentUser);

    // Check if application has been enabled for the user -> customer relation
    const defaultChecked =
        (applicationAccessConfigurations?.filter((aac) => aac.application.externalSystem === APPLICATION_CONFIG.id)?.[0]
            ?.application.id &&
            true) ||
        false;

    const canBeConfigured =
        [ApplicationDto.externalSystem.ADMIN_PANEL, ApplicationDto.externalSystem.ENGAGE].includes(
            application.externalSystem
        ) && defaultChecked;

    const accessConfigurationIndex = customerUser.applicationAccessConfigurations?.findIndex(
        (item) => item.application.externalSystem === APPLICATION_CONFIG.id
    );

    const adminPanelConfiguration =
        customerUser?.applicationAccessConfigurations?.find(
            (aac: CreateApplicationAccessConfigurationDto | EditApplicationAccessConfigurationDto, index) =>
                aac.application.externalSystem === ApplicationDto.externalSystem.ADMIN_PANEL &&
                index === accessConfigurationIndex
        ) || false;

    // In case the application is AdminPanel, we need to check if logged-in user has higher ranked role than the modified user
    // to be able to modify the application settings
    const canBeModified =
        !adminPanelConfiguration ||
        !adminPanelConfiguration?.accessConfiguration ||
        currentUser.type === UserDto.type.ADMINISTRATOR ||
        (adminPanelConfiguration.accessConfiguration &&
            ((adminPanelConfiguration.accessConfiguration as AdminPanelAccessConfigurationDto)?.roles.length === 0 ||
                (userRoles &&
                    userRoles.some((userRole) =>
                        (adminPanelConfiguration.accessConfiguration as AdminPanelAccessConfigurationDto).roles.every(
                            (role: RoleDto) => !role || role.ranking >= userRole.ranking
                        )
                    ))));

    return (
        <ApplicationSwitch
            key={application.id}
            config={APPLICATION_CONFIG}
            defaultChecked={defaultChecked}
            disabled={!isApplicationAvailable || !application.enabled || !canBeModified}
            index={applicationIndex}
            name={`ignore.${customerUserIndex}.${applicationIndex}.use-callback`}
            onChangeCallback={updateApplication}
            renderConfigurationComponent={() => (
                <UserApplicationSettings
                    application={application}
                    accessConfigurationIndex={accessConfigurationIndex}
                    config={APPLICATION_CONFIG}
                    control={control}
                    customerUser={customerUser}
                    customerUserIndex={customerUserIndex}
                    customers={customers}
                    enabled={canBeConfigured && !!canBeModified}
                    user={user}
                />
            )}
            useFormProvider
        />
    );
};

export default UserApplication;
