import { useCallback, useEffect, useState } from 'react';
import { IdentifiableObject } from '@zetadisplay/zeta-ui-components/models';

export type ResultsType<D extends IdentifiableObject> = Map<string | number, D> | undefined;

/**
 * Helper to create a new map, otherwise hook will not re-render when original in state map is just updated
 * @param state
 */
const stateToMap = <D extends IdentifiableObject>(state: ResultsType<D>) => {
    const entries = (state && state.entries()) || [];
    return new Map([...entries]);
};

const useResults = <D extends IdentifiableObject>(cacheKey: string) => {
    const [results, setResults] = useState<ResultsType<D>>(undefined);

    /**
     * Reset results when cache key changes
     */
    useEffect(() => {
        setResults(undefined);
    }, [cacheKey]);

    const removeResult = useCallback((item: D) => {
        setResults((state) => {
            const map = stateToMap<D>(state);
            map.delete(item.id);

            return map;
        });
    }, []);

    const setResult = useCallback((item: D) => {
        setResults((state) => {
            const map = stateToMap<D>(state);
            map.set(item.id, item);

            return map;
        });
    }, []);

    const setResultsCallback = useCallback((data: D[]) => {
        setResults((state) => {
            const map = stateToMap<D>(state);

            data.forEach((item) => {
                map.set(item.id, item);
            });
            return map;
        });
        return data;
    }, []);

    return {
        results,
        removeResult,
        setResult,
        setResults: setResultsCallback,
    };
};

export default useResults;
