import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { Delete } from '@styled-icons/material';
import { PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import deletePlayerBatchAction from '../../engage-players/actions/delete-player-batch-action';
import { PlayerCreationToolOptions } from '../../engage-players/player-creation-tool';

type DeletePlayerBatchPromptProps = {
    options: PlayerCreationToolOptions;
    playerBatch: PlayerBatchDto;
};

const DeletePlayerBatchPrompt = NiceModal.create<DeletePlayerBatchPromptProps>(({ options, playerBatch }) => {
    const api = useAdminPanelApi();

    const name = playerBatch.name || playerBatch.id;

    return (
        <ConfirmPrompt<boolean | void>
            action={() => deletePlayerBatchAction(api, playerBatch, options)}
            name="delete-player-batch-prompt"
            submit={{
                label: 'common.action.delete',
                name: 'delete',
            }}
            title={{ icon: <Delete size={24} />, label: `Delete batch ${name}?` }}
        >
            <Typography>Please confirm that you want to delete permanently &quot;{name}&quot;.</Typography>
        </ConfirmPrompt>
    );
});

export default DeletePlayerBatchPrompt;
