import { AdminPanelClient, ApiError, CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../dependency-injection/utils/handle-response-error';
import { CustomersOptions } from '../customers';
import { emitCustomerUpdatedEvent } from '../events/customer-updated-event';

const activateCustomerAction = async (
    api: AdminPanelClient,
    customer: CustomerDto,
    options: CustomersOptions
): Promise<CustomerDto | void> => {
    return api.customers
        .activateCustomer(customer.id)
        .then((result) => {
            options.notify(`Customer "${customer.name}" has been successfully activated`, [], 'success');

            emitCustomerUpdatedEvent(result);

            return result;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default activateCustomerAction;
