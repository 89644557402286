import { AdminPanelClient, ApiError, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ListActionOptions } from '../../../components/types';
import handleResponseError from '../../dependency-injection/utils/handle-response-error';
import { emitUserDeletedEvent } from '../events/user-deleted-event';

const deleteUserAction = async (
    api: AdminPanelClient,
    user: UserDto,
    options: ListActionOptions
): Promise<boolean | void> => {
    return api.users
        .deleteUserByIdV2(user.id)
        .then(() => {
            options.notify(`User "${user.username}" has been deleted successfully`, [], 'success');

            emitUserDeletedEvent(user);

            return true;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default deleteUserAction;
