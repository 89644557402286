import React from 'react';
import { UseFieldArrayUpdate } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { Workspace } from '@zetadisplay/engage-components/models';
import { useWorkspaceContent, WorkspaceInput } from '@zetadisplay/engage-components/modules/workspaces';
import { Icon } from '@zetadisplay/zeta-ui-components';
import { themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

import { FormType } from './share-template-modal';

const { ORANGE } = themeOptions.colors;

type WorkspaceFieldProps = {
    index: number;
    onRemoveWorkspace: (index: number) => void;
    onSelectWorkspace: UseFieldArrayUpdate<FormType, 'body'>;
    workspaceId: string | undefined;
};
const WorkspaceField = ({ index, onRemoveWorkspace, onSelectWorkspace, workspaceId }: WorkspaceFieldProps) => {
    const workspaceContent = useWorkspaceContent(workspaceId);

    return (
        <Grid container spacing={3} sx={{ marginBottom: '12px' }}>
            <Grid item xs={10}>
                <WorkspaceInput
                    defaultWorkspaces={(workspaceContent.result && [workspaceContent.result]) || []}
                    includeRootWorkspace
                    label="Workspace"
                    loading={workspaceContent.loading}
                    multiple={false}
                    onChange={(ws: Workspace[]) => onSelectWorkspace(index, ws[0])}
                    required
                />
            </Grid>

            <Grid item xs={2}>
                <IconButton data-testid={`remove-workspace--${index}`} onClick={() => onRemoveWorkspace(index)}>
                    <Icon color={ORANGE} type="DELETE" size={16} />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default WorkspaceField;
