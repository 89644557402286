import { AdminPanelClient, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ListActionOptions } from '../../../components/types';
import handleResponseError from '../../dependency-injection/utils/handle-response-error';
import { emitUserUpdatedEvent } from '../events/user-updated-event';

const activateUserAction = async (
    api: AdminPanelClient,
    user: UserDto,
    options: ListActionOptions
): Promise<UserDto | void> => {
    return api.users
        .activateUser(user.id)
        .then((result: UserDto) => {
            options.notify(`User "${user.username}" has been activated successfully`, [], 'success');

            emitUserUpdatedEvent(result);

            return result;
        })
        .catch((e) => {
            handleResponseError(e, options.notify);
        });
};

export default activateUserAction;
