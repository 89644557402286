import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APP_NAME } from '../../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import UACList from './components/uac-list';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

export type UacConfigurationOptions = {
    callback?: () => void;
    notify: (message: string, params: string[], type: 'error' | 'info' | 'success') => void;
};

const AutomatedUserCreation = () => {
    useDocumentTitle(APP_NAME, ['Automated User Creation']);
    const { classes } = useStyles();
    const navigate = useNavigate();

    const onFabClick = useCallback(() => {
        navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/automated-user-creation/create`);
    }, [navigate]);

    return (
        <Box component="section" className={classes.root} data-testid="automated-user-creation">
            <Fab
                aria-label="add-user"
                color="primary"
                data-testid="add-uac-config"
                onClick={onFabClick}
                sx={{ position: 'absolute', top: '-98px', right: '20px' }}
            >
                <AddIcon />
            </Fab>

            <UACList />
        </Box>
    );
};

export default AutomatedUserCreation;
