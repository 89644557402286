import React from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';
import { Alert, Button } from '@zetadisplay/zeta-ui-components';

import useCustomerRoles from '../../../customers/hooks/use-customer-roles';
import { DEFAULT_ROLE, UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationAdminPanelRole from './uac-configuration-admin-panel-role';

type UacConfigurationAdminPanelRolesProps = {
    customers: UAC_CONFIGURATION_FORM_VALUES['customers'];
    enabled: boolean;
    fields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'adminPanelRoles', 'key'>[];
    onAdd: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'adminPanelRoles'>;
    onRemove: UseFieldArrayRemove;
};

const UacConfigurationAdminPanelRoles = ({
    customers,
    enabled,
    fields,
    onAdd,
    onRemove,
}: UacConfigurationAdminPanelRolesProps) => {
    const canAddRoles = customers && customers[0].customer?.id !== undefined;

    const roles = useCustomerRoles((customers && customers[0].customer?.id) || undefined);

    return (
        <Box data-testid="uac-configuration-admin-panel-roles">
            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                Admin panel roles
            </Typography>

            {!canAddRoles && (
                <Alert
                    dismissible={false}
                    messages={['Please select a customer first to have role options available']}
                    severity="info"
                />
            )}

            {canAddRoles && (
                <Grid container spacing={3} sx={{ mb: 1 }}>
                    {fields.map((field, index, array) => (
                        <UacConfigurationAdminPanelRole
                            key={field.key}
                            canRemove={array.length > 1}
                            enabled={enabled}
                            index={index}
                            loading={roles.isLoading}
                            onRemove={onRemove}
                            roles={roles.data}
                        />
                    ))}
                </Grid>
            )}

            {canAddRoles && (
                <Button disabled={!enabled} name="add-another-admin-panel-role" onClick={() => onAdd(DEFAULT_ROLE)}>
                    Add another rule +
                </Button>
            )}
        </Box>
    );
};

export default UacConfigurationAdminPanelRoles;
