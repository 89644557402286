import { useContext } from 'react';

import CustomerContext from '../context/customer-context';

const useCustomerContext = () => {
    const context = useContext(CustomerContext);

    if (context === undefined) {
        throw new Error('useCustomerContext is only usable within CustomerProvider');
    }

    const { changeCustomer, customer, customers } = context;
    if (customer === undefined) {
        throw new Error('useCustomerContext is usable only when customer is selected!');
    }

    return {
        changeCustomer,
        customer,
        customers,
    };
};

export default useCustomerContext;
