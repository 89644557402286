import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { LockPerson } from '@styled-icons/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';
import { ListActionOptions } from '../../../../components/types';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import deactivateUserAction from '../../actions/deactivate-user-action';

type DeactivateUserPromptProps = {
    user: UserDto;
    options: ListActionOptions;
};

const DeactivateUserPrompt = NiceModal.create<DeactivateUserPromptProps>(({ user, options }) => {
    const api = useAdminPanelApi();

    return (
        <ConfirmPrompt<UserDto | void>
            action={() => deactivateUserAction(api, user, options)}
            name="deactivate-user-prompt"
            submit={{
                label: 'Deactivate',
                name: 'deactivate',
            }}
            title={{ icon: <LockPerson size={24} />, label: `Deactivate user ${user.username}?` }}
        >
            <Typography>Please confirm that you want to deactivate user &quot;{user.username}&quot;.</Typography>
        </ConfirmPrompt>
    );
});

export default DeactivateUserPrompt;
