import React, { useCallback, useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { Button } from '@zetadisplay/zeta-ui-components';

import WorkspaceAccess from './workspace-access';

export type WorkspaceAccessProps = {
    accessConfigurationIndex: number;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    customerUserIndex: number;
    loading?: boolean;
    workspaces?: string[];
};

const WorkspaceAccesses = ({
    accessConfigurationIndex,
    control,
    customerUserIndex,
    loading,
    workspaces = [],
}: WorkspaceAccessProps) => {
    const [userWorkspaces, setUserWorkspaces] = useState<
        { key: number; value: string | null; initialValue: string | null }[]
    >(
        workspaces.length === 0
            ? [{ key: Date.now(), value: null, initialValue: null }]
            : workspaces.map((id, index) => ({ key: index, value: id, initialValue: id }))
    );

    const { setValue, unregister } = useFormContext();

    const onAddWorkspace = useCallback(
        (index: number, key: number, value: string | null) => {
            setValue(
                `user.customerUsers.${customerUserIndex}.applicationAccessConfigurations.${accessConfigurationIndex}.accessConfiguration.workspaces.${index}`,
                value || ''
            );
            setUserWorkspaces((state) =>
                state.map((item) => (item.key === key ? { ...item, value: value || '' } : item))
            );
        },
        [accessConfigurationIndex, customerUserIndex, setValue]
    );

    const onRemoveWorkspace = useCallback(
        (index: number, key: number) => {
            unregister(
                `user.customerUsers.${customerUserIndex}.applicationAccessConfigurations.${accessConfigurationIndex}.accessConfiguration.workspaces.${index}`
            );
            setUserWorkspaces((state) => state.filter((item) => item.key !== key));
        },
        [accessConfigurationIndex, customerUserIndex, unregister]
    );

    return (
        <Box data-testid="engage-workspace-access">
            <Typography variant="h5" sx={{ marginBottom: '12px' }}>
                Workspace access
            </Typography>

            {userWorkspaces.map((elem, index) => (
                <WorkspaceAccess
                    key={elem.key}
                    accessConfigurationIndex={accessConfigurationIndex}
                    canRemove={userWorkspaces.length > 1}
                    control={control}
                    customerUserIndex={customerUserIndex}
                    formKey={elem.key}
                    index={index}
                    loading={loading}
                    onAddWorkspace={onAddWorkspace}
                    onRemoveWorkspace={onRemoveWorkspace}
                    workspaceId={elem.initialValue || undefined}
                />
            ))}

            <Box>
                <Button
                    disabled={userWorkspaces.some((item) => item.value === null)}
                    name="add-another-workspace"
                    onClick={() =>
                        setUserWorkspaces((state) => [...state, { key: Date.now(), value: null, initialValue: null }])
                    }
                >
                    Add another workspace +
                </Button>
            </Box>
        </Box>
    );
};

export default WorkspaceAccesses;
