import { useState } from 'react';
import { useAsyncAbortable } from 'react-async-hook';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { SearchFilterOption } from '@zetadisplay/zeta-ui-components';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';

import useResults from '../../../hooks/use-results';
import getResultData from '../../../utils/get-result-data';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import { useUserCreatedEventListener } from '../events/user-created-event';
import { useUserDeletedEventListener } from '../events/user-deleted-event';
import { useUserSearchEventListener } from '../events/user-search-event';
import { useUserUpdatedEventListener } from '../events/user-updated-event';
import { Order, SortBy } from './use-sorting';
import { UsersSearchFilters } from './use-users-search-filters';

export type UseUsersReturnType = {
    data: UserDto[] | undefined;
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<UserDto[] | undefined>;
};

export type UserSearchState = {
    applications: string[];
    customers: SearchFilterOption<string>[] | undefined;
    search: string;
    status: 'active' | 'disabled' | undefined;
};

const initialState = {
    applications: [],
    customers: [],
    search: '',
    status: undefined,
};

const useUsers = (
    disabled: boolean,
    listenSearchEvent: boolean,
    sort?: SortBy,
    order?: Order,
    domain: string | undefined = undefined
): UseUsersReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const storedValues = (domain && sessionStorage.getItem(domain)) || null;

    const [queryParams, setQueryParams] = useState<UserSearchState>(
        (storedValues && JSON.parse(storedValues)) || initialState
    );

    const { results, removeResult, setResult, setResults } = useResults<UserDto>(
        JSON.stringify({ ...queryParams, sort, order })
    );

    const users = useAsyncAbortable<UserDto[] | undefined>(
        async () => {
            if (disabled) {
                return undefined;
            }

            return api.users
                .getUsers(
                    queryParams.customers?.map((customer) => customer.value),
                    undefined,
                    queryParams.applications,
                    queryParams.search,
                    queryParams.status,
                    sort,
                    order
                )
                .then((data) => setResults(data));
        },
        [
            api.users,
            disabled,
            order,
            queryParams.applications,
            queryParams.customers,
            queryParams.search,
            queryParams.status,
            setResults,
            sort,
        ],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    useUserCreatedEventListener((data: UserDto) => {
        setResult(data);
    });

    useUserUpdatedEventListener((data: UserDto) => {
        setResult(data);
    });

    useUserDeletedEventListener((data: UserDto) => {
        removeResult(data);
    });

    useUserSearchEventListener((data: SearchState<UsersSearchFilters>) => {
        if (listenSearchEvent) {
            setQueryParams(data as UserSearchState);
        }
    });

    return {
        data: getResultData(results),
        error: users.error,
        isLoading: users.loading,
        refresh: users.execute,
    };
};

export default useUsers;
