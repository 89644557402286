import * as React from 'react';
import { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Workspace } from '@zetadisplay/engage-components/models';
import {
    useWorkspaceContent,
    useWorkspacesContent,
    WorkspaceSelect,
} from '@zetadisplay/engage-components/modules/workspaces';

import EngageLoader from '../../../engage/components/engage-loader/engage-loader';

export type EngageWorkspaceSelectProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    defaultValue?: string;
    index: number;
};

const EngageSettingsWorkspaceSelect = ({ control, defaultValue, index }: EngageWorkspaceSelectProps) => {
    const [resolvedWorkspace, setResolvedWorkspace] = useState<Workspace | boolean | null>(
        (defaultValue && null) || false
    );
    const workspaces = useWorkspacesContent();
    const workspace = useWorkspaceContent(defaultValue);

    useEffect(() => {
        if (resolvedWorkspace === null && !workspace.loading) {
            setResolvedWorkspace(workspace.result || false);
        }
    }, [resolvedWorkspace, workspace.loading, workspace.result]);

    if (workspaces.loading || resolvedWorkspace === null) {
        return <EngageLoader />;
    }

    return (
        <div data-testid="engage-workspace-select">
            <Controller
                control={control}
                name={`applications.${index}.data.externalEntityId`}
                render={({ field: { onChange } }) => (
                    <WorkspaceSelect
                        clearOnSelect={false}
                        defaultValue={workspace.result}
                        onSelectWorkspace={(value: Workspace | null) => {
                            onChange(value?.id || '');
                        }}
                        workspaces={workspaces.result || []}
                    />
                )}
            />
        </div>
    );
};

export default EngageSettingsWorkspaceSelect;
