import { FormProvider, useForm } from 'react-hook-form';

import useCustomerContext from '../../hooks/use-customer-context';
import CustomerInput from '../customer-input/customer-input';

const CustomerContextChanger = () => {
    const methods = useForm();
    const { changeCustomer, customer, customers } = useCustomerContext();

    return (
        <div data-testid="customer-context-changer">
            <FormProvider {...methods}>
                <CustomerInput
                    defaultValue={customer}
                    name="change-customer-context"
                    loading={false}
                    onChangeCallback={changeCustomer}
                    options={customers}
                    useLabel={false}
                    variant="standard"
                />
            </FormProvider>
        </div>
    );
};

export default CustomerContextChanger;
