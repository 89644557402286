import { useMemo } from 'react';
import { Box } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../components/tag/tag';

export type CustomerStatusProps = {
    customer: CustomerDto;
};

const CustomerStatus = ({ customer }: CustomerStatusProps) => {
    const active = useMemo(() => !customer.deletedAt || customer?.deletedAt?.length === 0, [customer.deletedAt]);

    return (
        <Box>
            <Tag text={(active && 'Active') || 'Deactivated'} type={(active && 'GREEN') || 'RED'} />
        </Box>
    );
};

export default CustomerStatus;
