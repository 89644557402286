import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import Fab from '../../components/fab/fab';
import { APP_NAME } from '../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../routing/constants';
import useAuthorization from '../authorization/hooks/use-auth';
import UserList from './components/user-list/user-list';

const useStyles = makeStyles()(() => ({
    root: {
        position: 'relative',
    },
}));

const Users = () => {
    useDocumentTitle(APP_NAME, ['Users']);
    const { classes } = useStyles();
    const context = useAuthorization();
    const navigate = useNavigate();

    const onFabClick = useCallback(() => {
        navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/users/create`);
    }, [navigate]);

    return (
        <Box component="section" className={classes.root} data-testid="users">
            <Fab action={onFabClick} disabled={!context.can('manage', 'User')} name="add-user" />

            <UserList />
        </Box>
    );
};

export default Users;
