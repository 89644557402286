import { ApiError } from '@zetadisplay/connect-adminpanel-api-client';
import { UACClient } from '@zetadisplay/connect-userautocreation-api-client';
import { MetaScriptContainerC } from '@zetadisplay/connect-userautocreation-api-client/models/MetaScriptContainerC';

import { ListActionOptions } from '../../../../components/types';
import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { emitUacDeletedEvent } from '../events/uac-deleted-event';

const deleteUacAction = async (
    api: UACClient,
    script: MetaScriptContainerC,
    options: ListActionOptions
): Promise<boolean | void> => {
    return api.script
        .scriptDelete(script.id)
        .then((result) => {
            if (result.statusCode === 200) {
                options.notify(`UAC configuration "${script.id}" has been deleted successfully`, [], 'success');

                emitUacDeletedEvent(script);

                return true;
            }
            options.notify(`Deleting UAC configuration "${script.id}" failed`, [], 'error');
            return false;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default deleteUacAction;
