import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { Edit, Lock, LockOpen } from '@styled-icons/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ActionGroup, ContextMenu } from '@zetadisplay/zeta-ui-components';

import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../../routing/constants';
import useAuthorization from '../../../authorization/hooks/use-auth';
import { CustomersOptions } from '../../customers';
import ActivateCustomerPrompt from '../prompts/activate-customer-prompt';
import DeactivateCustomerPrompt from '../prompts/deactivate-customer-prompt';

export type CustomerListActionsProps = {
    options: CustomersOptions;
    customer: CustomerDto;
};

const CustomerListActions = ({ options, customer }: CustomerListActionsProps) => {
    const context = useAuthorization();
    const history = useNavigate();

    const canEdit = context.can('update', 'Customer', customer.id);
    const canDelete = context.can('delete', 'Customer', customer.id);

    const deactivated = (customer.deletedAt && customer.deletedAt.length > 0) || false;

    const actions: ActionGroup<CustomerDto>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => history(`/${ACCESS_CONFIGURATION_BASE_PATH}/customers/${customer.id}/edit`),
                        icon: Edit,
                        isDisabled: () => deactivated || !canEdit,
                        label: 'Edit',
                        name: 'edit-customer',
                    },
                    {
                        handler: () =>
                            NiceModal.show(ActivateCustomerPrompt, {
                                customer,
                                options,
                            }),
                        icon: LockOpen,
                        isDisabled: () => !deactivated || !canEdit,
                        isVisible: () => deactivated,
                        label: 'Activate',
                        name: 'activate-customer',
                    },
                    {
                        handler: () =>
                            NiceModal.show(DeactivateCustomerPrompt, {
                                customer,
                                options,
                            }),
                        icon: Lock,
                        isDisabled: () => deactivated || !canDelete,
                        label: 'Deactivate',
                        name: 'deactivate-customer',
                    },
                ],
                name: 'customer-actions',
            },
        ];
    }, [canDelete, canEdit, customer, deactivated, history, options]);

    return (
        <Box component="div" sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center' }}>
            <ContextMenu<CustomerDto> item={customer} actions={actions} placement="bottom" />
        </Box>
    );
};

export default CustomerListActions;
