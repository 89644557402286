type NamedEntity = {
    name: string;
};

const sortByName = (a: NamedEntity, b: NamedEntity) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
};

export default sortByName;
