import { useCallback } from 'react';
import NiceModal, { NiceModalHocProps } from '@ebay/nice-modal-react';
import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LibraryModelsTemplateInfo, LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { CreateTemplateModal } from '@zetadisplay/engage-components/modules/templates/components/modals';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

type AddTemplateProps = {
    setResult: (item: DiscriminatedEntity<LibraryModelsTemplateRelations>) => void;
};

const AddTemplate = ({ setResult }: AddTemplateProps) => {
    const { hasRole } = useUserInformation();
    const theme: ZetaTheme = useTheme();

    const onFabClick = useCallback(() => {
        NiceModal.show<DiscriminatedEntity<LibraryModelsTemplateInfo>, { dark?: boolean } & NiceModalHocProps>(
            CreateTemplateModal,
            {
                dark: theme.palette.dark,
            }
        ).then((result) => {
            setResult(result);
        });
    }, [setResult, theme.palette.dark]);

    return (
        <Fab
            aria-label="add-template"
            color="primary"
            disabled={!hasRole(['ENGAGE_LIBRARY_TEMPLATE_CREATE'])}
            onClick={onFabClick}
            sx={{ position: 'absolute', top: '-98px', right: '20px' }}
        >
            <AddIcon />
        </Fab>
    );
};

export default AddTemplate;
