import { Box, Typography } from '@mui/material';
import { EngageLabelDto } from '@zetadisplay/connect-adminpanel-api-client';

type PlayerBatchReportPlayerLabelsProps = {
    labels?: EngageLabelDto[];
};
const PlayerBatchReportPlayerLabels = ({ labels }: PlayerBatchReportPlayerLabelsProps) => {
    if (!labels) {
        return <Typography>N/A</Typography>;
    }

    if (labels.length === 0) {
        return <Typography>No labels</Typography>;
    }

    return (
        <>
            {labels.map((label, index) => (
                <Box component="span" key={label.attribute.id} sx={{ marginRight: '4px' }}>
                    <Typography display="inline" sx={{ fontWeight: 600 }}>
                        {label.attribute.name}
                    </Typography>

                    {label.attributeValue?.value && (
                        <Typography display="inline">{`: ${label.attributeValue.value}`}</Typography>
                    )}
                    {index < labels.length - 1 && ','}
                </Box>
            ))}
        </>
    );
};

export default PlayerBatchReportPlayerLabels;
