import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { ApplicationDto, CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { Workspace } from '@zetadisplay/engage-components/models';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { dequal } from 'dequal';

import FormActions from '../../../components/form/form-actions';
import { APP_NAME } from '../../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH, AUTOMATED_USER_CREATION_PATH } from '../../../routing/constants';
import { Application, APPLICATIONS } from '../../applications/application';
import getCustomer from '../../customers/api/get-customer';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import persistUacAction from './actions/persist-uac-action';
import getUacScript from './api/get-uac-script';
import { UacConfigurationOptions } from './automated-user-creation';
import UacConfigurationAdminPanel from './components/uac-configuration-admin-panel';
import UacConfigurationEngage from './components/uac-configuration-engage';
import UacConfigurationProfile from './components/uac-configuration-profile';
import useUACApi from './hooks/use-uac-api';

export interface UAC_CONFIGURATION_FORM_VALUES extends FieldValues {
    id: string;
    customers: { value: string; customer: CustomerDto | undefined }[];
    applicationAccesses: { value: string; applications: { application: Application; enabled: boolean }[] }[];
    adminPanelRoles: { value: string; role: string | undefined }[];
    engageAccesses: { value: string; workspace: Workspace | undefined }[];
    engageRoles: { value: string; roleId: number | undefined }[];
}

export const DEFAULT_CUSTOMER = { value: '', customer: undefined };
export const DEFAULT_ROLE = { value: '', role: undefined };
export const DEFAULT_ENGAGE_ACCESS = { value: '', workspace: undefined };
export const DEFAULT_ENGAGE_ROLE = { value: '', roleId: undefined };
export const DEFAULT_APPLICATION_ACCESS = {
    value: '',
    applications: APPLICATIONS.filter(
        (application) => application.enabled && application.id !== ApplicationDto.externalSystem.UAC
    ).map((application) => ({
        application,
        enabled: true,
    })),
};

const defaultValues: UAC_CONFIGURATION_FORM_VALUES = {
    id: '',
    customers: [DEFAULT_CUSTOMER],
    applicationAccesses: [DEFAULT_APPLICATION_ACCESS],
    adminPanelRoles: [DEFAULT_ROLE],
    engageAccesses: [DEFAULT_ENGAGE_ACCESS],
    engageRoles: [DEFAULT_ENGAGE_ROLE],
};

const UacConfiguration = () => {
    const { scriptId } = useParams();

    useDocumentTitle(APP_NAME, ['Users', 'Automated User Creation', (scriptId && 'Edit') || 'Create']);
    const uacApi = useUACApi();
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const navigate = useNavigate();

    const methods = useForm<UAC_CONFIGURATION_FORM_VALUES>({
        defaultValues: async () => {
            if (!scriptId) {
                return defaultValues;
            }

            try {
                const { uacProfile } = await getUacScript(scriptId, uacApi);

                const { id, adminpanelCustomer, applicationAccess, adminpanelRole, engageAccess, engageRole } =
                    uacProfile;

                const customers = [];
                const applicationAccesses = [];

                // eslint-disable-next-line no-restricted-syntax
                for await (const customer of Object.entries(adminpanelCustomer.customer)) {
                    const customerDto = await getCustomer(customer[1], api);
                    customers.push({
                        value: customer[0],
                        customer: customerDto,
                    });
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const app of Object.entries(applicationAccess.applications)) {
                    const applicationIdentifiers = app[1].split(', ');

                    applicationAccesses.push({
                        value: app[0],
                        applications: APPLICATIONS.filter(
                            (application) => application.enabled && application.id !== ApplicationDto.externalSystem.UAC
                        ).map((application) => ({
                            application,
                            enabled: applicationIdentifiers.includes(application.id),
                        })),
                    });
                }

                return {
                    id,
                    customers,
                    applicationAccesses,
                    adminPanelRoles: (Object.keys(adminpanelRole).length > 0 &&
                        Object.entries(adminpanelRole).map((item) => ({
                            value: item[0],
                            role: item[1],
                        }))) || [DEFAULT_ROLE],
                    engageAccesses: (Object.keys(engageAccess.workspaces).length > 0 &&
                        Object.entries(engageAccess.workspaces).map((item) => ({
                            value: item[0],
                            workspace: {
                                id: item[1],
                            },
                        }))) || [DEFAULT_ENGAGE_ACCESS],
                    engageRoles: (Object.keys(engageRole.roles).length > 0 &&
                        Object.entries(engageRole.roles).map((item) => ({
                            value: item[0],
                            roleId: item[1],
                        }))) || [DEFAULT_ENGAGE_ROLE],
                };
            } catch (e) {
                snackbar('Failed to load UAC script, data has not been initialized', undefined, 'error');
                return defaultValues;
            }
        },
        mode: 'onChange',
    });

    const { control, formState, handleSubmit, reset, watch } = methods;

    const { isLoading, isSubmitting } = formState;

    const {
        fields: customerFields,
        append: appendCustomer,
        remove: removeCustomer,
    } = useFieldArray({ name: 'customers', control, keyName: 'key' });

    const {
        fields: applicationAccessFields,
        append: appendApplicationAccess,
        remove: removeApplicationAccess,
    } = useFieldArray({ name: 'applicationAccesses', control, keyName: 'key' });

    const {
        fields: adminPanelRoleFields,
        append: appendAdminPanelRole,
        remove: removeAdminPanelRole,
    } = useFieldArray({ name: 'adminPanelRoles', control, keyName: 'key' });

    const {
        fields: engageAccessFields,
        append: appendEngageAccess,
        remove: removeEngageAccess,
    } = useFieldArray({ name: 'engageAccesses', control, keyName: 'key' });

    const {
        fields: engageRoleFields,
        append: appendEngageRole,
        remove: removeEngageRole,
    } = useFieldArray({ name: 'engageRoles', control, keyName: 'key' });

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<UAC_CONFIGURATION_FORM_VALUES>(
        'ap.uac_configuration',
        scriptId || 'new'
    );

    const [hasInitialized, setHasInitialized] = useState(false);

    const values = watch();

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/${AUTOMATED_USER_CREATION_PATH}`);
        });
    }, [defuse, navigate]);

    const options: UacConfigurationOptions = {
        callback: actionsCallback,
        notify: snackbar,
    };

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(defaultValues, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...defaultValues, ...preservedValues });
        }

        setHasInitialized(true);
    }, [hasInitialized, preservedValues, reset, snackbar]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => setPreservedValues(value as UAC_CONFIGURATION_FORM_VALUES));
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const onSubmit: SubmitHandler<UAC_CONFIGURATION_FORM_VALUES> = (data) =>
        persistUacAction(uacApi, data, options, !!scriptId);

    return (
        <Box component="div" data-testid="uac-configuration">
            {isLoading && <ComponentLoader />}

            {!isLoading && (
                <FormProvider<UAC_CONFIGURATION_FORM_VALUES> {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <UacConfigurationProfile edit={!!scriptId} />

                        <UacConfigurationAdminPanel
                            adminPanelRoleFields={adminPanelRoleFields}
                            addAdminPanelRole={appendAdminPanelRole}
                            addApplicationAccess={appendApplicationAccess}
                            addCustomer={appendCustomer}
                            applicationAccessFields={applicationAccessFields}
                            customerFields={customerFields}
                            removeAdminPanelRole={removeAdminPanelRole}
                            removeApplicationAccess={removeApplicationAccess}
                            removeCustomer={removeCustomer}
                            values={values}
                        />

                        <UacConfigurationEngage
                            addEngageAccess={appendEngageAccess}
                            addEngageRole={appendEngageRole}
                            engageAccessFields={engageAccessFields}
                            engageRoleFields={engageRoleFields}
                            removeEngageAccess={removeEngageAccess}
                            removeEngageRole={removeEngageRole}
                            values={values}
                        />

                        <FormActions
                            callback={actionsCallback}
                            name="uac-configuration-actions"
                            isSubmitting={isSubmitting}
                        />
                    </form>
                </FormProvider>
            )}
        </Box>
    );
};

export default UacConfiguration;
