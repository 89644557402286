import { AdminPanelClient, ApiError, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { emitUserCreatedEvent } from '../../events/user-created-event';
import { CREATE_USER_FORM_VALUES } from '../create-user';
import { UserConfigurationOptions } from '../user-configuration';

const createUserAction = async <T extends CREATE_USER_FORM_VALUES>(
    api: AdminPanelClient,
    data: T,
    options: UserConfigurationOptions
) => {
    try {
        if (options?.user) {
            return;
        }

        const user: UserDto = await api.users.createUser(data.user);

        emitUserCreatedEvent(user);

        options.notify(`User "${user.username}" has been created successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default createUserAction;
