import React, { useMemo } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { ApplicationDto, CustomerDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput, SelectOption, TextInput } from '@zetadisplay/engage-components';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components';

import useCurrentUser from '../../../../authorization/hooks/use-current-user';
import useCustomerRoles from '../../../../customers/hooks/use-customer-roles';
import getUserCustomerRoles from '../../../utils/get-user-customer-roles';

const gridItemProps = {
    item: true,
    xs: 12,
    md: 4,
};

export type AdminPanelSettingsProps = {
    application: ApplicationDto;
    accessConfigurationIndex: number;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    customerUserIndex: number;
    customers: CustomerDto[];
};

const AdminPanelSettings = ({
    application,
    accessConfigurationIndex,
    control,
    customerUserIndex,
    customers,
}: AdminPanelSettingsProps) => {
    const { getValues } = useFormContext();

    const roles = useCustomerRoles(application.customerId);
    const currentUser = useCurrentUser();

    const values = getValues();

    const userRoles = getUserCustomerRoles(
        currentUser,
        values.user?.customerUsers[customerUserIndex].customer,
        customers
    );

    const roleDefaultValue =
        values.user?.customerUsers?.[customerUserIndex]?.applicationAccessConfigurations[accessConfigurationIndex]
            ?.accessConfiguration?.roles[0]?.id;

    const roleOptions: SelectOption[] = useMemo(() => {
        return (
            roles.data?.filter((role) => {
                return (
                    values?.user?.type === UserDto.type.ADMINISTRATOR ||
                    (userRoles?.some((userRole) => userRole.ranking <= role.ranking) &&
                        (role.userTypeRestrictions.length === 0 ||
                            role.userTypeRestrictions.includes(values?.user.type))) ||
                    (!userRoles &&
                        (role.userTypeRestrictions.length === 0 ||
                            role.userTypeRestrictions.includes(values?.user.type)))
                );
            }) || []
        );
    }, [roles.data, userRoles, values?.user.type]);

    return (
        <section data-testid="user-application-engage-settings">
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextInput
                        defaultLabel="Username"
                        defaultValue={values.user?.username || undefined}
                        disabled
                        name="user.username"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextInput
                        defaultLabel="Customer"
                        defaultValue={values.user?.customerUsers[customerUserIndex].customer.name || undefined}
                        disabled
                        name={`user.customerUsers.${customerUserIndex}.customer.name`}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={4} />

                <Grid {...gridItemProps} data-testid="adminPanel-role-container">
                    <Typography variant="h5" sx={{ marginBottom: '12px' }}>
                        Roles
                    </Typography>

                    <Controller
                        control={control}
                        name={`user.customerUsers.${customerUserIndex}.applicationAccessConfigurations.${accessConfigurationIndex}.accessConfiguration.roles.0`}
                        render={({ field: { onChange } }) => {
                            return (
                                <FormProviderWrapper>
                                    <SelectInput
                                        control={control}
                                        defaultValue={roleDefaultValue || ''}
                                        fullWidth
                                        name="adminPanel-role-select-0"
                                        label="Role"
                                        loading={roles.isLoading || !roles.data}
                                        onChangeCallback={(event) => {
                                            const role = roleOptions.find((item) => item.id === event.target.value);
                                            onChange(role);
                                        }}
                                        options={roleOptions}
                                        required
                                        rules={{
                                            required: 'common.validation.required',
                                        }}
                                    />
                                </FormProviderWrapper>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </section>
    );
};

export default AdminPanelSettings;
