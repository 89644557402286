import React, { useCallback, useEffect, useState } from 'react';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Box, Typography } from '@mui/material';
import {
    CreateBatchPlayerDto,
    CreatePlayerBatchDto,
    EditPlayerBatchDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import {
    useAttributes,
    useAvailableSubscriptions,
    useNetworkGroups,
} from '@zetadisplay/engage-components/modules/players/hooks';
import { Button, ComponentLoader } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import PlayerBatchPlayer from './player-batch-player';

const useStyles = makeStyles()(() => ({
    root: {
        marginTop: 6,
    },
    title: {
        textTransform: 'uppercase',
    },
    button: {
        marginTop: 6,
    },
}));

export type PlayerBatchPlayersProps<
    T extends (
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
    )[]
> = {
    addPlayer: (position?: number, player?: CreateBatchPlayerDto) => void;
    players: T;
    removePlayer: UseFieldArrayRemove;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>;
};

const PlayerBatchPlayers = <
    T extends (
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
    )[]
>({
    addPlayer,
    players,
    removePlayer,
    setValue,
}: PlayerBatchPlayersProps<T>) => {
    const { classes } = useStyles();

    const [reservedSubscriptions, setReservedSubscriptions] = useState<Record<number, string>>(
        (() => {
            const licenses: Record<number, string> = {};

            players.forEach((player, index) => {
                if (typeof player.license === 'string' && player.license.length > 0) {
                    licenses[index] = player.license;
                }
            });
            return licenses;
        })()
    );

    const { result: availableSubscriptions } = useAvailableSubscriptions();
    const labels = useAttributes('playerBatchPlayerFields');
    const networkGroups = useNetworkGroups();

    const reserveSubscription = useCallback((index: number, license: string) => {
        setReservedSubscriptions((state) => ({ ...state, [index]: license }));
    }, []);

    useEffect(() => {
        if (!labels.isLoading && labels.total && labels.total > labels.data.length) {
            labels.getNextResultPage();
        }
    }, [labels]);

    useEffect(() => {
        if (!networkGroups.isLoading && networkGroups.total && networkGroups.total > networkGroups.data.length) {
            networkGroups.getNextResultPage();
        }
    }, [networkGroups]);

    if (labels.isLoading || networkGroups.isLoading) {
        return <ComponentLoader />;
    }

    return (
        <Box className={classes.root} component="section" data-testid="player-batch-players">
            <Typography className={classes.title} variant="h3">
                Players
            </Typography>

            {players.map((player, index) => (
                <PlayerBatchPlayer
                    key={player.key}
                    addPlayer={addPlayer}
                    index={index}
                    networkGroups={networkGroups.data}
                    onLicenseChange={reserveSubscription}
                    player={player}
                    removePlayer={removePlayer}
                    reservedSubscriptions={reservedSubscriptions}
                    setValue={setValue}
                    subscriptions={availableSubscriptions?.items || undefined}
                    workspaceLabels={labels.data}
                />
            ))}

            <Button className={classes.button} name="add-another-player" onClick={() => addPlayer()}>
                Add another player +
            </Button>
        </Box>
    );
};

export default PlayerBatchPlayers;
