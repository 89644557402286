import React, { useCallback } from 'react';
import { ProductFruits } from 'react-product-fruits';
import env from '@beam-australia/react-env';

import useCurrentUser from '../../modules/authorization/hooks/use-current-user';

const ProductFruitsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    const productFruitsWorkspaceCode = env('PRODUCT_FRUITS_WORKSPACE');
    const currentUser = useCurrentUser();

    const renderProductFruits = useCallback(() => {
        if (!productFruitsWorkspaceCode || !currentUser.username) {
            return null;
        }

        return (
            <ProductFruits
                language="en"
                user={{
                    email: currentUser.username,
                    firstname: currentUser.firstName,
                    lastname: currentUser.lastName,
                    username: currentUser.username,
                    props: {
                        type: currentUser.type,
                    },
                }}
                workspaceCode={productFruitsWorkspaceCode}
            />
        );
    }, [
        currentUser.firstName,
        currentUser.lastName,
        currentUser.type,
        currentUser.username,
        productFruitsWorkspaceCode,
    ]);

    return (
        <>
            {children}
            {renderProductFruits()}
        </>
    );
};

export default ProductFruitsWrapper;
