import React from 'react';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

type Context = {
    changeCustomer: (arg: CustomerDto) => void;
    customer: CustomerDto | undefined;
    customers: CustomerDto[] | undefined;
};

export default React.createContext<Context | undefined>(undefined);
