import { Typography } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

export type UserCustomerProps = {
    user: UserDto;
};

const UserCustomer = ({ user }: UserCustomerProps) => {
    return (
        <Typography data-testid="user-customer">
            {user.customers.map((userCustomer) => userCustomer.customer.name).join(', ')}
        </Typography>
    );
};

export default UserCustomer;
