import React from 'react';
import { Box, Typography } from '@mui/material';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { APP_NAME } from '../../config/contants';
import useCurrentUser from '../authorization/hooks/use-current-user';

const useStyles = makeStyles()(() => ({
    root: {},
}));

const Dashboard = () => {
    useDocumentTitle(APP_NAME, ['Dashboard']);
    const { classes } = useStyles();

    const currentUser = useCurrentUser();

    return (
        <Box component="section" className={classes.root} data-testid="dashboard">
            <Typography variant="h2">Hello {currentUser?.firstName || ''}!</Typography>
        </Box>
    );
};

export default Dashboard;
