import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';
import { Lock } from '@styled-icons/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import ConfirmPrompt from '../../../../components/modals/confirm-prompt';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import deactivateCustomerAction from '../../actions/deactivate-customer-action';
import { CustomersOptions } from '../../customers';

type DeactivateCustomerPromptProps = {
    customer: CustomerDto;
    options: CustomersOptions;
};

const DeactivateCustomerPrompt = NiceModal.create<DeactivateCustomerPromptProps>(({ customer, options }) => {
    const api = useAdminPanelApi();

    return (
        <ConfirmPrompt<CustomerDto | void>
            action={() => deactivateCustomerAction(api, customer, options)}
            name="delete-customer-prompt"
            submit={{
                label: 'Deactivate',
                name: 'deactivate',
            }}
            title={{ icon: <Lock size={24} />, label: `Deactivate customer ${customer.name}?` }}
        >
            <Typography sx={{ mb: 1 }}>
                Please confirm that you want to deactivate customer &quot;{customer.name}&quot;.
            </Typography>

            <Typography>
                On deactivation, all customer applications will be disabled and customer user accesses to the
                applications will be revoked.
            </Typography>
        </ConfirmPrompt>
    );
});

export default DeactivateCustomerPrompt;
