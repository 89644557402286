import { CustomerTreeNode } from '../customer-profile';

/**
 * Function signiture for the action performed on each node in Hierarchy traversal.
 */
export type TraversalAction = (customer: CustomerTreeNode) => void;
/**
 * Function signiture for the Hierarchy traversals.
 */
export type HierarchyTraversal = (customer: CustomerTreeNode, action: TraversalAction) => void;

/**
 * Utility function for traversing the hierarchy from the bottom up, and for each discovered node,
 * the provided action is called. This allows for easy implementation of many kinds of functionality.
 *
 * @implements HierarchyTraversal, TraversalAction
 *
 * @param customer  The start node in the hierarchy
 * @param action    Action performed for each node.
 * @returns         void
 */
export const traverseCustomersBottomsUp: HierarchyTraversal = (customer: CustomerTreeNode, action: TraversalAction) => {
    if (!customer.children) {
        action(customer);
        return;
    }

    customer.children.forEach((child) => {
        return traverseCustomersBottomsUp(child, action);
    });
    action(customer);
};

/**
 * Utility function for traversing the hierarchy from the top and then down, and for each discovered node,
 * the provided action is called. This allows for easy implementation of many kinds of functionality.
 *
 * @implements HierarchyTraversal, TraversalAction
 *
 * @param customer  The start node in the hierarchy
 * @param action    Action performed for each node.
 * @returns         void
 */
export const traverseCustomersTopDown: HierarchyTraversal = (customer: CustomerTreeNode, action: TraversalAction) => {
    action(customer);

    if (customer.children) {
        customer.children.forEach((child) => traverseCustomersTopDown(child, action));
    }
};

/**
 * Find Customer in hierarchies based on Customer Id.
 *
 * @param trees         of TreeNodes
 * @param customerId    The id of the node we are looking for
 * @returns             The node with a matching if OR null
 */
export const findNode = (trees: CustomerTreeNode[], customerId: string): CustomerTreeNode | null => {
    if (!customerId) {
        return null;
    }

    const traverseNodes = (node: CustomerTreeNode): CustomerTreeNode | null => {
        if (node.id === customerId) {
            return node;
        }

        if (node.children) {
            // eslint-disable-next-line no-restricted-syntax
            for (const child of node.children) {
                const found = traverseNodes(child);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    let foundNode: CustomerTreeNode | null = null;
    for (let index = 0; !foundNode && index < trees.length; index += 1) {
        const tree = trees[index];
        foundNode = traverseNodes(tree);
    }
    return foundNode;
};

/**
 * Utility function for walking up the hierarchy, implemented as a generator function.
 * First iteration it returns the start Customer and then its parents one-by-one for each loop.
 *
 * @param customer      Start Customer
 * @param customerMap   Map of <CustomerId:Customer> representing the hierarchy
 * @returns             The CustomerTreeNode
 */
export function* traverseUpCustomerHierarchy(
    customer: CustomerTreeNode,
    customerMap: Record<string, CustomerTreeNode>
): Generator<CustomerTreeNode, void, undefined> {
    let currentCustomer = customer;

    if (currentCustomer.id in customerMap) {
        const cust = customerMap[currentCustomer.id];
        yield cust;
    } else {
        return;
    }

    while (currentCustomer.parentCustomerId in customerMap) {
        const parentCustomer = customerMap[currentCustomer.parentCustomerId];
        if (parentCustomer) {
            yield parentCustomer;
            currentCustomer = parentCustomer;
        } else {
            return;
        }
    }
}
