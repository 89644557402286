import React, { useMemo } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import {
    ApplicationDto,
    CreateCustomerUserDto,
    EditCustomerUserDto,
    EngageExternalAccessConfigurationDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { SelectOption, TextInput } from '@zetadisplay/engage-components';
import { RolesInput } from '@zetadisplay/engage-components/modules/users/components';
import { useWorkspaceContent, WorkspaceInput } from '@zetadisplay/engage-components/modules/workspaces';

import EngageLoader from '../../../../engage/components/engage-loader/engage-loader';
import userHasApplication from '../../../utils/user-has-application';
import WorkspaceAccesses from './workspace-accesses';

const gridItemProps = {
    item: true,
    xs: 12,
    md: 4,
};

export type EngageSettingsProps = {
    application: ApplicationDto;
    accessConfigurationIndex: number;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    customerUser: CreateCustomerUserDto | EditCustomerUserDto;
    customerUserIndex: number;
    user?: UserDto;
};

const EngageSettings = ({
    application,
    accessConfigurationIndex,
    control,
    customerUser,
    customerUserIndex,
    user,
}: EngageSettingsProps) => {
    const { getValues } = useFormContext();
    const workspace = useWorkspaceContent(application.externalEntityId);
    const values = getValues();

    /**
     * Extra option for roles input - deprecated previous roles are not available in RolesInput, so we need to extract it
     * from user data.
     */
    const extraOptions: SelectOption[] | undefined = useMemo(() => {
        // For existing users, the role is extracted from user entity
        if (user && userHasApplication(user, ApplicationDto.externalSystem.ENGAGE)) {
            // customerUserIndex is form index, not data index, so we need to find the correct customer relation from existing user
            const usersCustomer = user.customers.filter((c) => c.customer.id === customerUser.customer.id)[0];
            // We'll get next the application access relation
            const applicationAccess = usersCustomer.applications?.filter(
                (aac) => aac.application.externalSystem === ApplicationDto.externalSystem.ENGAGE
            )[0];

            const { role } = (applicationAccess?.accessConfiguration as EngageExternalAccessConfigurationDto) || {};

            return (role && [{ disabled: true, id: role.id, name: `${role.name} - Deprecated` }]) || undefined;
        }

        // For new users, the role is extracted from data set in the form when username was validated & returned Engage data
        if (
            customerUser.applicationAccessConfigurations?.some(
                (aac) => aac.application.externalSystem === ApplicationDto.externalSystem.ENGAGE
            )
        ) {
            const applicationAccess = customerUser.applicationAccessConfigurations.filter(
                (aac) => aac.application.externalSystem === ApplicationDto.externalSystem.ENGAGE
            )[0];
            const { role } = (applicationAccess?.accessConfiguration as EngageExternalAccessConfigurationDto) || {};

            return (role && [{ disabled: true, id: role.id, name: `${role.name} - Deprecated` }]) || undefined;
        }

        return undefined;
    }, [customerUser.applicationAccessConfigurations, customerUser.customer.id, user]);

    if (workspace.loading || !workspace.result) {
        return <EngageLoader text="Loading workspace.." />;
    }

    return (
        <section data-testid="user-application-engage-settings">
            <Grid container spacing={3}>
                <Grid {...gridItemProps}>
                    <TextInput
                        defaultLabel="Username"
                        defaultValue={values.user?.username || undefined}
                        disabled
                        name="user.username"
                        required
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <TextInput
                        defaultLabel="Customer"
                        defaultValue={values.user?.customerUsers[customerUserIndex].customer.name || undefined}
                        disabled
                        name={`user.customerUsers.${customerUserIndex}.customer.name`}
                        required
                    />
                </Grid>

                <Grid {...gridItemProps}>
                    <WorkspaceInput
                        defaultWorkspaces={[workspace.result]}
                        disabled
                        includeRootWorkspace
                        label="Root workspace"
                        loading={workspace.loading}
                        name="externalEntityId"
                        multiple={false}
                        required
                    />
                </Grid>

                <Grid {...gridItemProps} data-testid="engage-role-container">
                    <Typography variant="h5" sx={{ marginBottom: '12px' }}>
                        Role
                    </Typography>

                    <RolesInput
                        control={control}
                        extraOptions={extraOptions}
                        name={`user.customerUsers.${customerUserIndex}.applicationAccessConfigurations.${accessConfigurationIndex}.accessConfiguration.roleId`}
                        required
                    />
                </Grid>

                <Grid {...gridItemProps} md={12}>
                    <WorkspaceAccesses
                        accessConfigurationIndex={accessConfigurationIndex}
                        control={control}
                        customerUserIndex={customerUserIndex}
                        loading={workspace.loading}
                        workspaces={
                            values.user?.customerUsers[customerUserIndex]?.applicationAccessConfigurations[
                                accessConfigurationIndex
                            ]?.accessConfiguration?.workspaces || undefined
                        }
                    />
                </Grid>
            </Grid>
        </section>
    );
};
export default EngageSettings;
