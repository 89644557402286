import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import TableRowSkeleton from '../../../../components/table/table-row-skeleton';
import UserBatchListRow from './user-batch-list-row';

const COLUMNS: ColumnType[] = [
    { label: '', sx: { width: '25px' } },
    { label: 'Source' },
    { label: 'Status' },
    { label: 'Users' },
    { label: 'Author' },
    { label: 'Last updated' },
    { label: 'Actions', sx: { width: '100px', textAlign: 'center' } },
];

export type UserBatchListProps = {
    error?: Error;
    isLoading: boolean;
    userBatches?: UserBatchDto[];
};

const UserBatchList = ({ error, isLoading, userBatches }: UserBatchListProps) => {
    const renderUserBatches = () => {
        if (userBatches?.length === 0 || error) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No batch jobs found
                    </TableCell>
                </TableRow>
            );
        }

        return userBatches?.map((userBatch: UserBatchDto) => (
            <UserBatchListRow key={userBatch.id} userBatch={userBatch} />
        ));
    };

    return (
        <Box component="section" data-testid="user-batch-list">
            <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                Batch jobs
            </Typography>

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && !userBatches && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}
                        {userBatches && renderUserBatches()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UserBatchList;
