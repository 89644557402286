import AddIcon from '@mui/icons-material/Add';
import { Fab as MuiFab } from '@mui/material';

type Props = {
    action: () => void;
    disabled?: boolean;
    name: string;
};

const Fab = ({ action, disabled = false, name }: Props) => {
    return (
        <MuiFab
            aria-label={name}
            color="primary"
            disabled={disabled}
            data-testid={name}
            onClick={action}
            sx={{ position: 'absolute', top: '-103px', right: '20px' }}
        >
            <AddIcon />
        </MuiFab>
    );
};

export default Fab;
