import React from 'react';
import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { makeStyles, themeOptions } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()((theme) => ({
    tag: {
        display: 'inline-block',
        padding: 4,
        borderRadius: 4,
        fontSize: 10,
        fontWeight: themeOptions.BOLD,
        lineHeight: '12px',
        textTransform: 'uppercase',
    },
    DEFAULT: {
        backgroundColor: `${theme.palette.info.main}99`,
    },
    RED: {
        backgroundColor: `${theme.palette.error.main}99`,
    },
    GREEN: {
        backgroundColor: `${theme.palette.success.main}99`,
    },
    YELLOW: {
        backgroundColor: `${theme.palette.warning.main}99`,
    },
}));

export type SupportedTags = 'DEFAULT' | 'RED' | 'GREEN' | 'YELLOW';

type Props = {
    sx?: SxProps<Theme>;
    text: string;
    type: SupportedTags;
};

const Tag = ({ sx, text, type }: Props) => {
    const { classes, cx } = useStyles();

    const tagClasses = cx({
        [classes.tag]: true,
        [classes[type]]: true,
    });

    return (
        <Typography className={tagClasses} sx={sx}>
            {text}
        </Typography>
    );
};

export default Tag;
