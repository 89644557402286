import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId, FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { Box } from '@mui/material';
import {
    ApplicationDto,
    CreateBatchPlayerDto,
    CreatePlayerBatchDto,
    CustomerDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { NetworkModelsContact, NetworkModelsPlayersPlayerLocation } from '@zetadisplay/engage-api-client';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { DiscriminatedEntity, Workspace } from '@zetadisplay/engage-components/models';
import { useWorkspace } from '@zetadisplay/engage-components/modules/workspaces';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { dequal } from 'dequal';

import { APP_NAME } from '../../../config/contants';
import { SOFTWARE_CONFIG_PLAYER_CREATION_PATH, SOFTWARE_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import EngageSubWorkspaceWrapper from '../components/engage-workspace-wrapper/engage-sub-workspace-wrapper';
import createPlayerBatchAction from './actions/create-player-batch-action';
import PlayerBatchActions from './components/player-batch-actions';
import PlayerBatchContext from './components/player-batch-context';
import PlayerBatchPlayers from './components/player-batch-players';
import PlayerGeneralInformation from './components/player-general-information';
import { DEFAULT_PLAYER, FORM_DEFAULT_VALUES } from './constants';
import { PlayerCreationToolOptions } from './player-creation-tool';

export type CreatePlayerBatchProps = {
    application: ApplicationDto;
    customer: CustomerDto;
};

const CreatePlayerBatch = ({ application, customer }: CreatePlayerBatchProps) => {
    useDocumentTitle(APP_NAME, ['Engage', 'Players', 'Create player batch']);
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const navigate = useNavigate();

    const { workspace } = useWorkspace();

    const [hasInitialized, setHasInitialized] = useState(false);

    const defaultValues = useMemo(
        () => ({
            ...FORM_DEFAULT_VALUES,
            externalEntityId: workspace.id,
        }),
        [workspace.id]
    );

    const methods = useForm<CreatePlayerBatchDto>({
        defaultValues,
        mode: 'onChange',
    });
    const { control, formState, getValues, handleSubmit, reset, setValue, watch } = methods;
    const { isSubmitting, isValid } = formState;

    const {
        append,
        fields: players,
        insert,
        remove: removePlayer,
    } = useFieldArray({ name: 'batchPlayers', control, keyName: 'key' });

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<CreatePlayerBatchDto>(
        'ap.playerBatch',
        'new'
    );

    const externalEntityId = useWatch({ control, name: 'externalEntityId' });

    const values = getValues();

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}`);
        });
    }, [defuse, navigate]);

    const options: PlayerCreationToolOptions = useMemo(
        () => ({
            callback: actionsCallback,
            customer,
            application,
            notify: snackbar,
        }),
        [application, actionsCallback, customer, snackbar]
    );

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(defaultValues, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...defaultValues, ...preservedValues });
        }

        setHasInitialized(true);
    }, [defaultValues, hasInitialized, preservedValues, reset, snackbar]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => {
                setPreservedValues(value as CreatePlayerBatchDto);
            });
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const updateLocationInputs = useCallback(
        (locationData: NetworkModelsPlayersPlayerLocation | null) => {
            const fieldOptions = {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            };
            setValue('location.address', locationData?.address || '', fieldOptions);
            setValue('location.id', locationData?.id || 0, fieldOptions);
            setValue('location.city', locationData?.city || '', fieldOptions);
            setValue('location.countryId', locationData?.countryId || 0, fieldOptions);
            setValue('location.zipcode', locationData?.zipcode || '', fieldOptions);
            setValue('location.timezoneId', locationData?.timezoneId || values.location?.timezoneId || 0, fieldOptions);
        },
        [setValue, values.location?.timezoneId]
    );

    const updateContactInputs = useCallback(
        (data: DiscriminatedEntity<NetworkModelsContact> | null) => {
            const fieldOptions = {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
            };
            setValue('contact.email', data?.email || '', fieldOptions);
            setValue('contact.id', data?.id || 0, fieldOptions);
            setValue('contact.firstName', data?.firstName || '', fieldOptions);
            setValue('contact.lastName', data?.lastName || '', fieldOptions);
            setValue('contact.telephone', data?.telephone || '', fieldOptions);
        },
        [setValue]
    );

    const onWorkspaceChange = useCallback(
        (arg: Workspace) => {
            reset({
                ...FORM_DEFAULT_VALUES,
                externalEntityId: arg.id,
            });
        },
        [reset]
    );

    const addPlayer = useCallback(
        (position?: number, player: CreateBatchPlayerDto = DEFAULT_PLAYER) => {
            if (position !== undefined) {
                insert(position, player);
            } else {
                append(player);
            }
        },
        [append, insert]
    );

    const onSubmit: SubmitHandler<CreatePlayerBatchDto> = (data) => createPlayerBatchAction(api, data, options);

    return (
        <Box component="div" data-testid="create-player-batch">
            <FormProvider {...methods}>
                {/* <DevTool control={control} placement="top-right" /> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <PlayerBatchContext customer={customer} onWorkspaceChange={onWorkspaceChange} />

                    <EngageSubWorkspaceWrapper workspaceId={externalEntityId}>
                        <PlayerGeneralInformation<CreatePlayerBatchDto>
                            control={control}
                            updateContactInputs={updateContactInputs}
                            updateLocationInputs={updateLocationInputs}
                            values={values}
                        />

                        <PlayerBatchPlayers<FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>[]>
                            addPlayer={addPlayer}
                            players={players}
                            removePlayer={removePlayer}
                            setValue={setValue}
                        />

                        <PlayerBatchActions callback={actionsCallback} isSubmitting={isSubmitting} isValid={isValid} />
                    </EngageSubWorkspaceWrapper>
                </form>
            </FormProvider>
        </Box>
    );
};

export default CreatePlayerBatch;
