import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { ContactDto } from '@zetadisplay/connect-adminpanel-api-client';
import { NetworkModelsContact } from '@zetadisplay/engage-api-client';
import { TextInput } from '@zetadisplay/engage-components';
import {
    createDiscriminatedEntity,
    DiscriminatedEntity,
    EntityDiscriminators,
} from '@zetadisplay/engage-components/models';
import { ContactInput } from '@zetadisplay/engage-components/modules/contacts/components';

export type PlayerContactProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    updateContactInputs: (arg: DiscriminatedEntity<NetworkModelsContact>) => void;
};

const PlayerContact = ({ control, updateContactInputs }: PlayerContactProps) => {
    const contact: ContactDto = useWatch({ control, name: 'contact' });
    const readOnly = (contact?.id && contact.id > 0 && true) || false;

    return (
        <Grid container spacing={2} data-testid="player-contact">
            <Grid item xs={12}>
                <Typography sx={{ fontSize: '12px' }} variant="h5">
                    Contact person
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <ContactInput
                    control={control}
                    defaultValue={
                        (contact &&
                            createDiscriminatedEntity(EntityDiscriminators.Contact, {
                                ...contact,
                                company: '',
                                function: '',
                                mobile: '',
                                remark: '',
                                id: contact?.id || 0,
                                telephone: contact?.telephone || null,
                            })) ||
                        undefined
                    }
                    name="contact"
                    onChangeCallback={updateContactInputs}
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Firstname"
                            defaultValue={contact?.firstName || ''}
                            disabled={readOnly}
                            name="contact.firstName"
                            required={contact?.email?.length > 0}
                            rules={{
                                validate: {
                                    requiredWithEmail: (value: string) =>
                                        contact?.email?.length === 0 ||
                                        (contact?.email?.length > 0 && value.length > 0) ||
                                        'common.validation.required',
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextInput
                            control={control}
                            defaultLabel="Lastname"
                            defaultValue={contact?.lastName || ''}
                            disabled={readOnly}
                            name="contact.lastName"
                            required={contact?.email?.length > 0}
                            rules={{
                                validate: {
                                    requiredWithEmail: (value: string) =>
                                        contact?.email?.length === 0 ||
                                        (contact?.email?.length > 0 && value.length > 0) ||
                                        'common.validation.required',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    control={control}
                    defaultLabel="Phone number"
                    defaultValue={contact?.telephone || ''}
                    disabled={readOnly}
                    name="contact.telephone"
                />
            </Grid>
        </Grid>
    );
};

export default PlayerContact;
