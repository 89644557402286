import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { BatchUserDTO, UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Alert } from '@zetadisplay/zeta-ui-components';

import getSeverityForAlert from '../../../../../utils/get-severity-for-alert/get-severity-for-alert';
import BatchUserStatus from './batch-user-status';

type UserBatchReportRowProps = {
    batchUser: BatchUserDTO;
    userBatch: UserBatchDto;
};
const UserBatchReportRow = ({ batchUser, userBatch }: UserBatchReportRowProps) => {
    const [open, setOpen] = useState(false);

    const created = new Date(batchUser.createdAt);

    const hasMessages = batchUser.messages.length > 0;

    const tableCellSx = {
        borderBottom: (hasMessages && 'none') || undefined,
    };

    return (
        <>
            <TableRow key={batchUser.id} data-testid={`batch-user-row-${batchUser.id}`}>
                <TableCell sx={{ ...tableCellSx, padding: '6px' }}>
                    <IconButton
                        aria-label="expand row"
                        data-testid="expander"
                        disabled={!hasMessages}
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell sx={tableCellSx}>{batchUser.externalIdentifier}</TableCell>

                <TableCell sx={tableCellSx}>
                    <BatchUserStatus batchUser={batchUser} />
                </TableCell>

                <TableCell sx={tableCellSx}>{userBatch.createdBy?.username}</TableCell>

                <TableCell data-testid="created" sx={tableCellSx}>
                    {created.toLocaleString()}
                </TableCell>

                <TableCell sx={tableCellSx}>{userBatch.application.externalSystem}</TableCell>
            </TableRow>

            {hasMessages && (
                <TableRow data-testid={`batch-user-row-messages-${batchUser.id}`}>
                    <TableCell colSpan={6} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            {batchUser.messages.map((message) => (
                                <Alert
                                    key={message.message}
                                    dismissible={false}
                                    messages={[message.message]}
                                    severity={getSeverityForAlert(message.type)}
                                />
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default UserBatchReportRow;
