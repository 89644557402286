import { useCallback } from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import NiceModal from '@ebay/nice-modal-react';
import { Typography } from '@mui/material';

import ConfirmPrompt from '../../../../../components/modals/confirm-prompt';

type RemovePlayerFromBatchPromptProps = {
    index: number;
    name: string;
    removePlayer: UseFieldArrayRemove;
};

const RemovePlayerFromBatchPrompt = NiceModal.create<RemovePlayerFromBatchPromptProps>(
    ({ index, name, removePlayer }) => {
        const action = useCallback(async () => {
            removePlayer(index);
            return Promise.resolve(true);
        }, [index, removePlayer]);

        return (
            <ConfirmPrompt<boolean>
                action={() => action()}
                name="remove-player-from-batch-prompt"
                submit={{
                    label: 'common.action.remove',
                    name: 'remove',
                }}
                title={{ label: `Remove player ${name}?` }}
            >
                <Typography>Are you sure to remove &quot;{name}&quot; from the batch job?</Typography>
            </ConfirmPrompt>
        );
    }
);

export default RemovePlayerFromBatchPrompt;
