import { useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ApplicationDto, CustomerDto, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { useInterval } from 'usehooks-ts';

import { ColumnType } from '../../../../../components/table/table';
import TableHead from '../../../../../components/table/table-head';
import TableRowSkeleton from '../../../../../components/table/table-row-skeleton';
import usePlayerBatches from '../../hooks/use-player-batches';
import { PlayerCreationToolOptions } from '../../player-creation-tool';
import PlayerBatchListActions from './player-batch-list-actions';
import PlayerBatchStatus from './player-batch-status';

const COLUMNS: ColumnType[] = [
    { label: 'Name' },
    { label: 'Players' },
    { label: 'Last updated' },
    { label: 'Status', sx: { width: '120px' } },
    { label: 'Actions', sx: { width: '100px', textAlign: 'center' } },
];

export type PlayerBatchListProps = {
    customer: CustomerDto;
    refreshInterval?: number;
};

const PlayerBatchList = ({ customer, refreshInterval = 10000 }: PlayerBatchListProps) => {
    const snackbar = useTranslatedSnackbar();

    const application = useMemo(() => {
        if (customer.applications?.length === 0) {
            return undefined;
        }

        return customer.applications?.find((app) => app.externalSystem === ApplicationDto.externalSystem.ENGAGE);
    }, [customer]);

    const { data, error, isLoading, refresh } = usePlayerBatches(customer, application);

    useInterval(() => {
        refresh().then(() => null);
    }, refreshInterval);

    const options: PlayerCreationToolOptions = useMemo(
        () => ({
            callback: () => refresh(),
            customer,
            application,
            notify: snackbar,
        }),
        [application, customer, refresh, snackbar]
    );

    const renderPlayerBatches = () => {
        if (data?.length === 0 || error) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No batch jobs found
                    </TableCell>
                </TableRow>
            );
        }

        return data?.map((playerBatch: PlayerBatchDto) => {
            const name = playerBatch.name || playerBatch.id;

            const lastUpdated = new Date(playerBatch.updatedAt);

            return (
                <TableRow key={playerBatch.id} data-testid={`player-batch-row-${playerBatch.id}`}>
                    <TableCell>{name}</TableCell>

                    <TableCell data-testid="player-count">{playerBatch?.batchPlayers?.length || '-'}</TableCell>

                    <TableCell data-testid="last-updated">{lastUpdated.toLocaleString()}</TableCell>

                    <TableCell>
                        <PlayerBatchStatus playerBatch={playerBatch} />
                    </TableCell>

                    <TableCell>
                        <PlayerBatchListActions customer={customer} options={options} playerBatch={playerBatch} />
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Box component="section" data-testid="player-batch-list">
            <Typography variant="h2">Batch jobs</Typography>

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && data === undefined && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}
                        {(!isLoading || data !== undefined) && renderPlayerBatches()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default PlayerBatchList;
