import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Dialog } from '@mui/material';

import ModalActions, { ModalActionsProps } from './modal-actions';
import ModalContent, { ModalContentProps } from './modal-content';
import ModalTitle, { ModalTitleProps } from './modal-title';
import ModalTransition from './modal-transition';

export type ModalProps = React.PropsWithChildren<{
    actions?: ModalActionsProps;
    content?: ModalContentProps;
    keepMounted?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    name: string;
    title?: ModalTitleProps;
}>;

const Modal = ({
    actions,
    children,
    content,
    fullWidth = false,
    keepMounted = false,
    name,
    maxWidth = 'xl',
    title,
}: ModalProps) => {
    const modal = useModal();

    return (
        <Dialog
            fullWidth={fullWidth}
            keepMounted={keepMounted}
            onClose={() => modal.hide()}
            open={modal.visible}
            data-testid={`modal-${name}`}
            maxWidth={maxWidth}
            TransitionComponent={ModalTransition}
            TransitionProps={{
                onExited: () => modal.remove(),
            }}
        >
            <ModalTitle icon={title?.icon} label={title?.label} labelParams={title?.labelParams} />
            <ModalContent type={content?.type}>{children}</ModalContent>
            <ModalActions buttons={actions?.buttons} />
        </Dialog>
    );
};

export default Modal;
