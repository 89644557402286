import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../../routing/constants';
import useCustomerContext from '../../../customers/hooks/use-customer-context';
import useUserBatch from '../../hooks/use-user-batch';
import UserBatchReportActions from './components/user-batch-report-actions';
import UserBatchReportRow from './components/user-batch-report-row';

const COLUMNS: ColumnType[] = [
    { label: '', sx: { width: '25px' } },
    { label: 'Username' },
    { label: 'Status' },
    { label: 'Author' },
    { label: 'Import date' },
    { label: 'Source' },
];

const UserBatchReport = () => {
    const navigate = useNavigate();
    const { userBatchId } = useParams();
    const { customer } = useCustomerContext();

    const { isLoading, data: userBatch } = useUserBatch(customer, userBatchId);

    const closeCallback = useCallback(() => {
        navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/user-imports`);
    }, [navigate]);

    if (isLoading || userBatch === undefined) {
        return <ComponentLoader />;
    }

    return (
        <Box component="section" data-testid="user-batch-report">
            <Typography variant="h2" sx={{ marginBottom: '8px' }}>
                Imported users
            </Typography>

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {userBatch.batchUsers.map((batchUser) => (
                            <UserBatchReportRow key={batchUser.id} batchUser={batchUser} userBatch={userBatch} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <UserBatchReportActions closeCallback={closeCallback} />
        </Box>
    );
};

export default UserBatchReport;
