import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import { SOFTWARE_CONFIG_PLAYER_CREATION_PATH, SOFTWARE_CONFIGURATION_BASE_PATH } from '../../../../routing/constants';
import useCustomerContext from '../../../customers/hooks/use-customer-context';
import EngageDependencyInjection from '../../../dependency-injection/components/engage-dependency-injection';
import EngageWorkspaceWrapper from '../../components/engage-workspace-wrapper/engage-workspace-wrapper';
import usePlayerBatch from '../hooks/use-player-batch';
import usePlayerBatchReport from '../hooks/use-player-batch-report';
import PlayerBatchReportActions from './player-batch-report-actions';
import PlayerBatchReportContact from './player-batch-report-contact';
import PlayerBatchReportHeader from './player-batch-report-header';
import PlayerBatchReportLocation from './player-batch-report-location';
import PlayerBatchReportPlayers from './player-batch-report-players';
import handlePdfDownload from './utils/handle-pdf-download';

const PlayerBatchReport = () => {
    const navigate = useNavigate();

    const { playerBatchId } = useParams();
    const { customer } = useCustomerContext();

    const { authState } = useOktaAuth();

    const engage = useMemo(() => {
        return customer.applications?.find(
            (application) => application.externalSystem === ApplicationDto.externalSystem.ENGAGE
        );
    }, [customer]);

    const playerBatch = usePlayerBatch(customer, engage, playerBatchId);
    const playerBatchReport = usePlayerBatchReport(customer, engage, playerBatchId);

    const cancelCallback = useCallback(() => {
        navigate(`/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}`);
    }, [navigate]);

    const handleDownloadPdf = useCallback(() => {
        if (engage !== undefined && playerBatchReport.result !== undefined && authState?.accessToken?.accessToken) {
            handlePdfDownload(customer, engage, playerBatchReport.result, authState.accessToken.accessToken);
        }
    }, [authState?.accessToken?.accessToken, customer, engage, playerBatchReport.result]);

    if (
        engage === null ||
        playerBatch.loading ||
        playerBatch.result === undefined ||
        playerBatchReport.loading ||
        playerBatchReport.result === undefined
    ) {
        return <ComponentLoader />;
    }

    return (
        <Box component="section" data-testid="player-batch-report">
            <EngageDependencyInjection>
                <EngageWorkspaceWrapper workspaceId={playerBatchReport.result.externalEntityId}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <PlayerBatchReportHeader
                                playerBatch={playerBatch.result}
                                report={playerBatchReport.result}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <PlayerBatchReportLocation location={playerBatchReport.result.location} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <PlayerBatchReportContact contact={playerBatchReport.result.contact} />
                        </Grid>

                        <Grid item xs={12}>
                            <PlayerBatchReportPlayers players={playerBatchReport.result.players} />
                        </Grid>

                        <Grid item xs={12}>
                            <PlayerBatchReportActions
                                cancelCallback={cancelCallback}
                                handleDownloadPdf={handleDownloadPdf}
                            />
                        </Grid>
                    </Grid>
                </EngageWorkspaceWrapper>
            </EngageDependencyInjection>
        </Box>
    );
};

export default PlayerBatchReport;
