import { useContext } from 'react';

import AuthorizationContext from '../context/authorization-context';

const useCurrentUser = () => {
    const context = useContext(AuthorizationContext);

    if (context === undefined) {
        throw new Error('useCurrentUser is only usable within AuthorizationProvider');
    }

    return context;
};

export default useCurrentUser;
