import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import sortByName from '../../../../utils/sort-by-name';

/**
 * Find tree roots, return thge first(by design).
 * @param data
 * @returns
 */
const getRoots = (data: CustomerDto[] | undefined): CustomerDto[] | undefined => {
    if (!data) {
        return undefined;
    }

    const roots: CustomerDto[] = []; // Parentless nodes AKA roots
    const rootIds = new Set<string>(); // Node IDs for root elements, easier faster check
    const checkedNodes = new Set<string>(); // Node IDs as key, easy check

    const MAX_DEPTH = 30;
    let currentNode: CustomerDto | undefined;

    for (let index = 0; index < data.length; index += 1) {
        const loopNode = data[index];

        if (checkedNodes.has(loopNode.id)) {
            // eslint-disable-next-line no-continue
            continue;
        }
        let loops = 0;
        let done = false;
        currentNode = loopNode;
        do {
            // eslint-disable-next-line no-loop-func
            const nodeParent = data.find((m) => currentNode && m.id === currentNode.parentCustomerId);
            if (nodeParent === undefined) {
                // No duplicates
                if (!rootIds.has(currentNode.id)) {
                    rootIds.add(currentNode.id);
                    roots.push(currentNode);
                }
                // Also add root to checkedNodes
                if (!checkedNodes.has(currentNode.id)) {
                    checkedNodes.add(currentNode.id);
                }
                done = true;
            } else {
                if (!checkedNodes.has(currentNode.id)) {
                    checkedNodes.add(currentNode.id);
                }
                currentNode = nodeParent;
            }
            loops += 1;
        } while (!done && loops < MAX_DEPTH);

        // MAX_DEPTH check guarantees no infinite loops.
        // This also means that when we come by a node that lies
        // deeper that MAX_DEPTH levels, no root will be found/added...
        // BUT we assume that the roots will anyways be found by
        // deep node's parents higher up in the hierarchy.
    }
    return (roots && roots.sort(sortByName)) || undefined;
};

export default getRoots;
