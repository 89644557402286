import { useMemo } from 'react';
import { SearchFilter } from '@zetadisplay/zeta-ui-components';

import { APPLICATIONS } from '../../applications/application';
import useCustomers from './use-customers';

export type CustomersSearchFilters = {
    search: SearchFilter<'search-field', string>;
    customer: SearchFilter<'autocomplete', string>;
    applications: SearchFilter<'select', string>;
    status: SearchFilter<'select', string>;
};

const useCustomersSearchFilters = () => {
    const { data: customers, isLoading } = useCustomers(undefined, false, false);

    const searchFilters: CustomersSearchFilters | undefined = useMemo(() => {
        if (isLoading) {
            return undefined;
        }

        const searchFilter: SearchFilter<'search-field', string> = {
            defaultValue: undefined,
            label: 'Search',
            name: 'search',
            type: 'search-field',
        };

        const customerFilter: SearchFilter<'autocomplete', string> = {
            defaultValue: undefined,
            label: 'Customer',
            name: 'parentCustomerId',
            multiple: true,
            options:
                customers?.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    value: customer.id,
                })) || [],
            type: 'autocomplete',
            FormControlProps: {
                sx: {
                    width: '220px',
                },
            },
        };

        const applicationFilter: SearchFilter<'select', string> = {
            defaultValue: undefined,
            label: 'Application',
            name: 'externalSystem',
            multiple: true,
            options: APPLICATIONS.map((application) => ({
                id: application.id,
                name: application.name,
                value: application.id,
            })),
            type: 'select',
        };

        const statusFilter: SearchFilter<'select', string> = {
            defaultValue: undefined,
            label: 'Status',
            name: 'status',
            multiple: false,
            options: [
                { id: 'active', name: 'Active', value: 'active' },
                { id: 'disabled', name: 'Disabled', value: 'disabled' },
            ],
            type: 'select',
            FormControlProps: {
                sx: {
                    width: '100px',
                },
            },
        };

        return {
            search: searchFilter,
            customer: customerFilter,
            applications: applicationFilter,
            status: statusFilter,
        };
    }, [customers, isLoading]);

    return searchFilters;
};

export default useCustomersSearchFilters;
