import { useMemo } from 'react';
import { Box } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../components/tag/tag';

export type UserStatusProps = {
    user: UserDto;
};

const UserStatus = ({ user }: UserStatusProps) => {
    const active = useMemo(() => !user.deletedAt || user?.deletedAt?.length === 0, [user.deletedAt]);

    return (
        <Box>
            <Tag text={(active && 'Active') || 'Deactivated'} type={(active && 'GREEN') || 'RED'} />
        </Box>
    );
};

export default UserStatus;
