import { useTemplates } from '@zetadisplay/engage-components/modules/templates/hooks';

import AddTemplate from './components/add-template/add-template';
import TemplateList from './components/template-list/template-list';

const EngageTemplatesView = () => {
    const { data, isLoading, setResult } = useTemplates();

    return (
        <>
            <AddTemplate setResult={setResult} />

            <TemplateList data={data} isLoading={isLoading} />
        </>
    );
};

export default EngageTemplatesView;
