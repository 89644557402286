import { useMemo } from 'react';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Thumbnail } from '@zetadisplay/zeta-ui-components';
import {
    ApplicationAdminPanel,
    ApplicationAdminPanelPruned,
    ApplicationContentEditor,
    ApplicationContentEditorPruned,
    ApplicationControl,
    ApplicationControlPruned,
    ApplicationEngage,
    ApplicationEngagePlus,
    ApplicationEngagePlusPruned,
    ApplicationEngagePruned,
} from '@zetadisplay/zeta-ui-components/assets';

import { Application } from '../application';

export type ApplicationIconProps = {
    application: Application;
    pruned?: boolean;
    size?: number;
};
const ApplicationIcon = ({ application, pruned = true, size = 100 }: ApplicationIconProps) => {
    const source = useMemo(() => {
        switch (application.id) {
            case ApplicationDto.externalSystem.CONTENT_EDITOR:
                return (pruned && ApplicationContentEditorPruned) || ApplicationContentEditor;
            case ApplicationDto.externalSystem.CONTROL:
                return (pruned && ApplicationControlPruned) || ApplicationControl;
            case ApplicationDto.externalSystem.ENGAGE:
                return (pruned && ApplicationEngagePruned) || ApplicationEngage;
            case ApplicationDto.externalSystem.ENGAGE_PLUS:
                return (pruned && ApplicationEngagePlusPruned) || ApplicationEngagePlus;
            case ApplicationDto.externalSystem.ADMIN_PANEL:
            default:
                return (pruned && ApplicationAdminPanelPruned) || ApplicationAdminPanel;
        }
    }, [application.id, pruned]);

    return <Thumbnail alt={application.name} size={size} source={source} />;
};

export default ApplicationIcon;
