import env from '@beam-australia/react-env';
import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';

const adminPanelApiConfig: OpenAPIConfig = {
    BASE: env('ADMIN_PANEL_API_BASE') || 'http://localhost:3001',
    VERSION: 'v1',
    WITH_CREDENTIALS: false,
    CREDENTIALS: 'omit',
};

const apiConfig = {
    adminPanelApiConfig,
};

export default apiConfig;
