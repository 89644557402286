import { AdminPanelClient, ApiError, CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { emitCustomerCreatedEvent } from '../../events/customer-created-event';
import { CREATE_CUSTOMER_FORM_VALUES } from '../create-customer';
import { CustomerConfigurationOptions } from '../customer-configuration';

const createCustomerAction = async <T extends CREATE_CUSTOMER_FORM_VALUES>(
    api: AdminPanelClient,
    data: T,
    options: CustomerConfigurationOptions<undefined>
) => {
    try {
        if (options?.customer) {
            return;
        }

        const customer: CustomerDto = await api.customers.createCustomer({
            ...data.customer,
            applications: data.applications
                .filter((application) => application.data?.enabled === true)
                .map((application) => ({ ...application.data })),
        });

        emitCustomerCreatedEvent(customer);

        options.notify(`Customer "${customer.name}" has been created successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default createCustomerAction;
