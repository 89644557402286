import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { Box } from '@mui/material';
import {
    ApplicationDto,
    CreateCustomerUserDto,
    EditUserDto,
    EngageExternalAccessConfigurationDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { EditCustomerUserDto } from '@zetadisplay/connect-adminpanel-api-client/models/EditCustomerUserDto';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { dequal } from 'dequal';

import AuthorInformation from '../../../components/author-information/author-information';
import FormActions from '../../../components/form/form-actions';
import { APP_NAME } from '../../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import PLACEHOLDER_CUSTOMER from '../../customers';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import editUserAction from './actions/edit-user-action';
import { UserConfigurationOptions } from './user-configuration';
import UserCustomers from './user-customers';
import UserProfile from './user-profile';

export interface EDIT_USER_FORM_VALUES extends FieldValues {
    user: EditUserDto;
}

export type EditUserProps = {
    user: UserDto;
};

const EditUser = ({ user }: EditUserProps) => {
    useDocumentTitle(APP_NAME, ['Users', 'Edit']);
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const navigate = useNavigate();

    const methods = useForm<EDIT_USER_FORM_VALUES>({
        defaultValues: {
            user: {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                username: user.username,
                language: user.language,
                type: user.type,
                customerUsers:
                    user.customers.map((customerUser) => {
                        // Backend supports multiple roles but at the moment
                        // we haven't enabled this behaviour - UI picks only the first one
                        const roles = (customerUser.roles && [customerUser.roles[0]]) || [];

                        return {
                            id: customerUser.id,
                            customer: customerUser.customer,
                            applicationAccessConfigurations:
                                (customerUser.applications &&
                                    customerUser.applications.map((applicationConfiguration) => {
                                        if (
                                            applicationConfiguration.application.externalSystem ===
                                            ApplicationDto.externalSystem.ADMIN_PANEL
                                        ) {
                                            return {
                                                ...applicationConfiguration,
                                                accessConfiguration: {
                                                    roles,
                                                },
                                            };
                                        }

                                        // If Engage, omit the role object and only use the roleId as we do not wish to maintain both values in form state
                                        if (
                                            applicationConfiguration.application.externalSystem ===
                                            ApplicationDto.externalSystem.ENGAGE
                                        ) {
                                            const accessConfiguration =
                                                applicationConfiguration.accessConfiguration as EngageExternalAccessConfigurationDto;

                                            const { roleId, workspaces } = accessConfiguration;

                                            return {
                                                ...applicationConfiguration,
                                                accessConfiguration: {
                                                    roleId,
                                                    workspaces,
                                                },
                                            };
                                        }

                                        return applicationConfiguration;
                                    })) ||
                                [],
                        };
                    }) || [],
            },
        },
        mode: 'onChange',
    });
    const { control, formState, handleSubmit, reset, setError, setValue, watch } = methods;
    const { isSubmitting } = formState;

    const { fields, append, remove, update } = useFieldArray({
        name: 'user.customerUsers',
        control,
        keyName: 'key',
    });

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<EDIT_USER_FORM_VALUES>(
        'ap.user',
        user.id
    );

    const [hasInitialized, setHasInitialized] = useState(false);

    const values = watch();

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/users`);
        });
    }, [defuse, navigate]);

    const options: UserConfigurationOptions = {
        callback: actionsCallback,
        user,
        notify: snackbar,
    };

    const addCustomer = useCallback(
        (data?: CreateCustomerUserDto | EditCustomerUserDto) => {
            append(data || { customer: PLACEHOLDER_CUSTOMER, applicationAccessConfigurations: [] });
        },
        [append]
    );

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(values, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...values, ...preservedValues });
        }

        setHasInitialized(true);
    }, [hasInitialized, preservedValues, reset, snackbar, values]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => setPreservedValues(value as EDIT_USER_FORM_VALUES));
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const onSubmit: SubmitHandler<EDIT_USER_FORM_VALUES> = (data) => editUserAction(api, data, options);

    const renderExtraContent = useCallback(() => <AuthorInformation<UserDto> item={user} />, [user]);

    return (
        <Box component="div" data-testid="edit-user">
            <FormProvider {...methods}>
                {/* <DevTool control={control} placement="top-left" /> */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <UserProfile
                        control={control}
                        defaultValues={user}
                        externalProfile={user.externalProfile}
                        setError={setError}
                        setValue={setValue}
                    />

                    <UserCustomers
                        addCustomer={addCustomer}
                        control={control}
                        fields={fields}
                        remove={remove}
                        setValue={setValue}
                        update={update}
                        user={user}
                        values={values}
                    />

                    <FormActions
                        callback={actionsCallback}
                        extraContent={renderExtraContent}
                        name="user-configuration-actions"
                        isSubmitting={isSubmitting}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default EditUser;
