import * as React from 'react';
import { Control } from 'react-hook-form';
import { CreateApplicationDto, EditApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';

import EngageSettingsWorkspaceSelect from '../../../../applications/application-settings/engage-settings/engage-settings-workspace-select';
import EngageDependencyInjection from '../../../../dependency-injection/components/engage-dependency-injection';

export type EngageSettingsProps = {
    application: CreateApplicationDto | EditApplicationDto;
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    index: number;
};

const EngageSettings = ({ application, control, index }: EngageSettingsProps) => {
    return (
        <EngageDependencyInjection>
            <div data-testid="engage-settings">
                <EngageSettingsWorkspaceSelect
                    control={control}
                    defaultValue={application.externalEntityId}
                    index={index}
                />
            </div>
        </EngageDependencyInjection>
    );
};

export default EngageSettings;
