import React from 'react';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Accordion } from '@zetadisplay/zeta-ui-components';

import { UAC_CONFIGURATION_FORM_VALUES } from '../uac-configuration';
import UacConfigurationAdminPanelApplicationAccesses from './uac-configuration-admin-panel-application-accesses';
import UacConfigurationAdminPanelCustomers from './uac-configuration-admin-panel-customers';
import UacConfigurationAdminPanelRoles from './uac-configuration-admin-panel-roles';

type UacConfigurationAdminPanelProps = {
    adminPanelRoleFields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'adminPanelRoles', 'key'>[];
    addAdminPanelRole: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'adminPanelRoles'>;
    addApplicationAccess: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'applicationAccesses'>;
    addCustomer: UseFieldArrayAppend<UAC_CONFIGURATION_FORM_VALUES, 'customers'>;
    applicationAccessFields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'applicationAccesses', 'key'>[];
    customerFields: FieldArrayWithId<UAC_CONFIGURATION_FORM_VALUES, 'customers', 'key'>[];
    removeAdminPanelRole: UseFieldArrayRemove;
    removeApplicationAccess: UseFieldArrayRemove;
    removeCustomer: UseFieldArrayRemove;
    values: UAC_CONFIGURATION_FORM_VALUES;
};

const UacConfigurationAdminPanel = ({
    adminPanelRoleFields,
    addAdminPanelRole,
    addApplicationAccess,
    addCustomer,
    applicationAccessFields,
    customerFields,
    removeAdminPanelRole,
    removeApplicationAccess,
    removeCustomer,
    values,
}: UacConfigurationAdminPanelProps) => {
    return (
        <Accordion defaultExpanded title="Admin panel">
            <UacConfigurationAdminPanelCustomers
                fields={customerFields}
                onAdd={addCustomer}
                onRemove={removeCustomer}
            />

            <UacConfigurationAdminPanelApplicationAccesses
                fields={applicationAccessFields}
                onAdd={addApplicationAccess}
                onRemove={removeApplicationAccess}
            />

            <UacConfigurationAdminPanelRoles
                customers={values.customers}
                enabled={values.applicationAccesses.some((item) =>
                    item.applications.some(
                        (app) => app.application.id === ApplicationDto.externalSystem.ADMIN_PANEL && app.enabled
                    )
                )}
                fields={adminPanelRoleFields}
                onAdd={addAdminPanelRole}
                onRemove={removeAdminPanelRole}
            />
        </Accordion>
    );
};

export default UacConfigurationAdminPanel;
