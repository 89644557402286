import { AdminPanelClient, ApiError, CreatePlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../../dependency-injection/utils/handle-response-error';
import { PlayerCreationToolOptions } from '../player-creation-tool';
import validateContact from './validate-contact';

const createPlayerBatchAction = async <T extends CreatePlayerBatchDto>(
    api: AdminPanelClient,
    data: T,
    options: PlayerCreationToolOptions
) => {
    try {
        if (!options.customer) {
            options.notify('Customer must be set', [], 'error');
            return;
        }
        if (!options.application) {
            options.notify('Application must be set', [], 'error');
            return;
        }

        const { contact, ...rest } = data;

        const body = {
            ...rest,
            contact: (validateContact(contact) && contact) || undefined,
        };

        await api.playerBatch.createPlayerBatch(options.customer.id, options.application.id, body);

        options.notify(`Batch has been created successfully`, [], 'success');

        if (options.callback) {
            options.callback();
        }
    } catch (e) {
        await handleResponseError(e as ApiError, options.notify);
    }
};

export default createPlayerBatchAction;
