import { useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { Share } from '@styled-icons/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { useUserInformation } from '@zetadisplay/engage-components/modules/auth';
import { ActionGroup, ContextMenu } from '@zetadisplay/zeta-ui-components';

import { ListActionOptions } from '../../../../../components/types';
import ShareTemplateModal, { ShareTemplateModalProps } from '../share-template/share-template-modal';

type TemplateListActionsProps = {
    collapseRow: () => void;
    options: ListActionOptions;
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
};

const TemplateListActions = ({ collapseRow, options, template }: TemplateListActionsProps) => {
    const { hasRole } = useUserInformation();

    const actions: ActionGroup<DiscriminatedEntity<LibraryModelsTemplateRelations>>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () => {
                            // Temporary solution to close the row, as the data will not be updated
                            // until we have events to trigger a re-render when data changes.
                            collapseRow();
                            return NiceModal.show<undefined, ShareTemplateModalProps>(ShareTemplateModal, {
                                options,
                                template,
                            });
                        },
                        isDisabled: () => !hasRole('ENGAGE_LIBRARY_TEMPLATE_SHARE'),
                        icon: Share,
                        label: 'Share template',
                        name: 'share-template',
                    },
                ],
                name: 'template-actions',
            },
        ];
    }, [collapseRow, hasRole, options, template]);

    return (
        <Box component="div" sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center' }}>
            <ContextMenu<DiscriminatedEntity<LibraryModelsTemplateRelations>>
                actions={actions}
                item={template}
                placement="bottom"
            />
        </Box>
    );
};

export default TemplateListActions;
