import { ApplicationDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

import Action from '../../../authorization/action';
import userHasApplication from '../../utils/user-has-application';

const getAutomatedUserCreationAbilities = (user?: UserDto): Action[] | undefined => {
    if (!user) {
        return undefined;
    }

    const hasUACApplicationAccess = userHasApplication(user, ApplicationDto.externalSystem.UAC);

    if (hasUACApplicationAccess) {
        return [Action.Manage, Action.Read];
    }

    return undefined;
};

export default getAutomatedUserCreationAbilities;
