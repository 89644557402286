import React from 'react';
import { Box, Divider, List, ListItem, ListItemText, Paper } from '@mui/material';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import Action from '../../../modules/authorization/action';
import useAuthorization from '../../../modules/authorization/hooks/use-auth';
import {
    ACCESS_CONFIGURATION_BASE_PATH,
    SOFTWARE_CONFIG_PLAYER_CREATION_PATH,
    SOFTWARE_CONFIG_TEMPLATES_PATH,
    SOFTWARE_CONFIGURATION_BASE_PATH,
} from '../../../routing/constants';
import NavigationSubsection, { NavigationSubSectionType } from './navigation-subsection';

const useStyles = makeStyles()((theme) => ({
    root: {
        marginRight: 24,
        maxWidth: 240,
        minWidth: 240,
    },
    paper: {
        backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
    },
    title: {
        backgroundColor: (theme.palette.dark && '#030303') || theme.palette.background.content,
        paddingLeft: 24,
    },
    divider: { margin: '0 auto', opacity: (theme.palette.dark && 0.5) || 0.8, width: '90%' },
}));

export type NavigationMainSection = {
    sections: NavigationSubSectionType[];
    title: string;
    visible?: boolean;
};

const accessConfiguration: NavigationSubSectionType[] = [
    {
        auth: [Action.Read, 'User'],
        title: 'Users',
        links: [
            {
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/users`,
                text: 'All users',
            },
            {
                auth: [Action.Read, 'Customer'],
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/user-imports`,
                text: 'User imports',
            },
            {
                auth: [Action.Read, 'UAC'],
                href: `/${ACCESS_CONFIGURATION_BASE_PATH}/automated-user-creation`,
                text: 'Automated user creation',
            },
        ],
    },
    {
        auth: [Action.Read, 'Customer'],
        title: 'Customers',
        titleHref: `/${ACCESS_CONFIGURATION_BASE_PATH}/customers`,
        links: [],
    },
];

const softwareConfiguration: NavigationSubSectionType[] = [
    {
        auth: [
            [Action.Read, 'PlayerBatch-Engage'],
            [Action.Read, 'Templates-Engage'],
        ],
        title: 'Engage',
        links: [
            {
                auth: [Action.Read, 'PlayerBatch-Engage'],
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}`,
                text: 'Players',
            },
            {
                auth: [Action.Read, 'Templates-Engage'],
                href: `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_TEMPLATES_PATH}`,
                text: 'Templates',
            },
        ],
    },
];

const elements: NavigationMainSection[] = [
    {
        title: 'Access configuration',
        sections: accessConfiguration,
    },
    {
        title: 'Software configuration',
        sections: softwareConfiguration,
    },
];

const Navigation = () => {
    const { classes, cx } = useStyles();
    const context = useAuthorization();

    return (
        <Box component="nav" className={cx(classes.root, 'no-printing')} data-testid="navigation">
            <Paper elevation={2} className={classes.paper}>
                {elements.map((element) => {
                    const visibleSections = element.sections.filter(
                        (section) =>
                            (Array.isArray(section.auth) && section.auth.some((auth) => context.can(...auth))) ||
                            context.can(...section.auth)
                    );

                    if (visibleSections.length === 0) {
                        return null;
                    }

                    return (
                        <List key={element.title}>
                            <ListItem className={classes.title}>
                                <ListItemText primary={element.title} />
                            </ListItem>

                            {visibleSections.map((section, index, self) => (
                                <div key={section.title}>
                                    <NavigationSubsection section={section} />

                                    {index < self.length - 1 && <Divider className={classes.divider} />}
                                </div>
                            ))}
                        </List>
                    );
                })}
            </Paper>
        </Box>
    );
};

export default Navigation;
