import { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { ApplicationDto, CreateUserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput } from '@zetadisplay/engage-components';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components/form-provider-wrapper';
import { createDefaultButtons, Modal } from '@zetadisplay/engage-components/modules/modal/components';
import { AxiosError } from 'axios';

import { ListActionOptions } from '../../../../components/types';
import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../../dependency-injection/utils/handle-admin-panel-response-error';

export type CreateNewUserBatchModalProps = {
    applications: ApplicationDto[];
    options: ListActionOptions;
} & NiceModalHocProps;

const CreateNewUserBatchModal = NiceModal.create<CreateNewUserBatchModalProps>(
    ({ applications, options }: CreateNewUserBatchModalProps) => {
        const api = useAdminPanelApi();
        const modal = useModal();
        const methods = useForm<CreateUserBatchDto>();
        const { setValue } = methods;

        const onSubmit = useCallback(
            async (formValues: CreateUserBatchDto) => {
                try {
                    const response = await api.userBatch.createUserBatch(formValues.application.customerId, formValues);
                    options.notify('A new user batch has been created', [], 'success');

                    modal.resolve(response);
                    modal.hide();
                } catch (e) {
                    if (e instanceof AxiosError) {
                        options.notify(handleAdminPanelResponseError(e), [], 'error');
                    } else {
                        throw e;
                    }
                }
            },
            [api.userBatch, modal, options]
        );

        return (
            <Modal
                actions={{
                    buttons: createDefaultButtons({
                        cancel: {
                            disabled: methods.formState.isSubmitting,
                            onClick: modal.hide,
                        },
                        submit: {
                            busy: methods.formState.isSubmitting,
                            disabled: methods.formState.isSubmitting || !methods.formState.isValid,
                            onClick: methods.handleSubmit(onSubmit),
                        },
                    }),
                }}
                dark
                name="new-user-batch-form"
                title={{ label: 'Create new user batch import' }}
            >
                <FormProvider {...methods}>
                    <Box sx={{ width: '200px' }}>
                        <Controller
                            /* TODO SelectInput should take in ref as a prop */
                            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                            render={({ field: { onChange, ref, ...props } }) => (
                                <FormProviderWrapper>
                                    {/* TODO SelectInput needs improvements to work better with objects */}
                                    <SelectInput<ApplicationDto>
                                        {...props}
                                        fullWidth
                                        loading={!applications || applications.length === 0}
                                        label="Application"
                                        name="application"
                                        onChangeCallback={(event) => {
                                            onChange(event.target.value);
                                            setValue(
                                                'application',
                                                applications.find((item) => item.id === event.target.value) ||
                                                    applications[0]
                                            );
                                        }}
                                        options={applications.map((item) => ({ ...item, name: item.externalSystem }))}
                                        required
                                        rules={{
                                            required: 'common.validation.required',
                                        }}
                                    />
                                </FormProviderWrapper>
                            )}
                            name="application"
                        />
                    </Box>
                </FormProvider>
            </Modal>
        );
    }
);

export default CreateNewUserBatchModal;
