import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { Application, APPLICATIONS } from '../../../applications/application';
import ApplicationIcon from '../../../applications/application-icon/application-icon';

export type CustomerApplicationsProps = {
    customer: CustomerDto;
};

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
    },
    icon: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '50%',
        display: 'flex',
        height: 26,
        marginLeft: 0,
        marginRight: 6,
        padding: 6,
        width: 26,
    },
}));

const CustomerApplications = ({ customer }: CustomerApplicationsProps) => {
    const { classes } = useStyles();

    const applications = useMemo(() => {
        const enabledApplications = customer.applications?.filter((application) => application.enabled);

        const apps: Record<string, Application> = {};

        enabledApplications?.forEach((applicationDto) => {
            const app = APPLICATIONS.find((application) => application.id === applicationDto.externalSystem);
            if (app) {
                apps[app.id] = app;
            }
        });

        return apps;
    }, [customer]);

    if (customer.applications?.length === 0) {
        return <Typography>No applications</Typography>;
    }

    return (
        <Box component="div" className={classes.root} data-testid="customer-applications">
            {Object.values(applications)?.map((application) => (
                <div key={application.id} className={classes.icon} title={application.name}>
                    <ApplicationIcon application={application} size={20} />
                </div>
            ))}
        </Box>
    );
};

export default CustomerApplications;
