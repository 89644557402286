import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { ApplicationDto } from '@zetadisplay/connect-adminpanel-api-client';
import { DefaultLayout } from '@zetadisplay/engage-components/layouts';
import { DefaultLayoutContainerProps } from '@zetadisplay/engage-components/layouts/components/default-layout-container';
import { Loader } from '@zetadisplay/zeta-ui-components';

import ContentWrapper from '../layout/components/content-wrapper';
import InternalServerError from '../layout/components/error-pages/internal-server-error';
import NotFound from '../layout/components/error-pages/not-found';
import PendingConnection from '../layout/components/error-pages/pending-connection';
import ErrorWrapper from '../layout/components/error-wrapper';
import LayoutViewWrapper from '../layout/components/layout-view-wrapper';
import CallbackErrorComponent from '../modules/authorization/components/callback-error-component';
import RequireAuth from '../modules/authorization/components/require-auth';
import CustomerConfiguration from '../modules/customers/customer-configuration/customer-configuration';
import Customers from '../modules/customers/customers';
import Dashboard from '../modules/dashboard/dashboard';
import EngagePlayers from '../modules/engage/engage-players/engage-players';
import PlayerBatchReport from '../modules/engage/engage-players/player-batch-report/player-batch-report';
import PlayerCreationTool from '../modules/engage/engage-players/player-creation-tool';
import EngageTemplates from '../modules/engage/engage-templates/engage-templates';
import AutomatedUserCreation from '../modules/users/automated-user-creation/automated-user-creation';
import UacConfiguration from '../modules/users/automated-user-creation/uac-configuration';
import UserConfiguration from '../modules/users/user-configuration/user-configuration';
import UserBatchReport from '../modules/users/user-imports/user-batch-report/user-batch-report';
import UserImports from '../modules/users/user-imports/user-imports';
import Users from '../modules/users/users';
import {
    ACCESS_CONFIGURATION_BASE_PATH,
    SOFTWARE_CONFIG_PLAYER_CREATION_PATH,
    SOFTWARE_CONFIG_TEMPLATES_PATH,
    SOFTWARE_CONFIGURATION_BASE_PATH,
} from './constants';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const Routing = () => {
    const layoutContainerProps: DefaultLayoutContainerProps = {
        fullWidthOnSmall: false,
        fullWidth: true,
        useFormProvider: false,
        useModalProvider: false,
    };

    return (
        <SentryRoutes>
            <Route
                path="login/callback"
                element={<LoginCallback errorComponent={CallbackErrorComponent} loadingElement={<Loader />} />}
            />
            <Route
                path="/"
                element={<LayoutViewWrapper layout={DefaultLayout} LayoutContainerProps={layoutContainerProps} />}
            >
                <Route index element={<ContentWrapper component={Dashboard} title="Dashboard" />} />
                <Route path={ACCESS_CONFIGURATION_BASE_PATH}>
                    <Route path="users">
                        <Route element={<RequireAuth action="read" subject="User" />}>
                            <Route index element={<ContentWrapper component={Users} title="Users" />} />
                        </Route>

                        <Route element={<RequireAuth action="update" subject="User" />}>
                            <Route
                                path=":userId/edit"
                                element={<ContentWrapper component={UserConfiguration} title="User configuration" />}
                            />
                        </Route>
                        <Route element={<RequireAuth action="create" subject="User" />}>
                            <Route
                                path="create"
                                element={<ContentWrapper component={UserConfiguration} title="User configuration" />}
                            />
                        </Route>
                    </Route>

                    <Route path="user-imports" element={<RequireAuth action="read" subject="User" />}>
                        <Route element={<RequireAuth action="read" subject="Customer" />}>
                            <Route
                                index
                                element={
                                    <ContentWrapper
                                        component={UserImports}
                                        customerSearchState={{
                                            externalSystem: [
                                                ApplicationDto.externalSystem.ENGAGE,
                                                ApplicationDto.externalSystem.ENGAGE_PLUS,
                                            ],
                                        }}
                                        title="User imports"
                                        useCustomerContext
                                        useCustomerContextChanger
                                    />
                                }
                            />

                            <Route
                                path=":userBatchId/report"
                                element={
                                    <ContentWrapper
                                        component={UserBatchReport}
                                        customerSearchState={{
                                            externalSystem: [
                                                ApplicationDto.externalSystem.ENGAGE,
                                                ApplicationDto.externalSystem.ENGAGE_PLUS,
                                            ],
                                        }}
                                        title="User import report"
                                        useCustomerContext
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route path="automated-user-creation" element={<RequireAuth action="read" subject="UAC" />}>
                        <Route
                            index
                            element={
                                <ContentWrapper
                                    component={AutomatedUserCreation}
                                    title="Automated User Creation"
                                    useAutomatedUserCreationContext
                                />
                            }
                        />

                        <Route element={<RequireAuth action="update" subject="UAC" />}>
                            <Route
                                path=":scriptId/edit"
                                element={
                                    <ContentWrapper
                                        component={UacConfiguration}
                                        title="Automated User Creation configuration"
                                        useAutomatedUserCreationContext
                                    />
                                }
                            />
                        </Route>

                        <Route element={<RequireAuth action="create" subject="UAC" />}>
                            <Route
                                path="create"
                                element={
                                    <ContentWrapper
                                        component={UacConfiguration}
                                        title="Automated User Creation configuration"
                                        useAutomatedUserCreationContext
                                    />
                                }
                            />
                        </Route>
                    </Route>

                    <Route path="customers">
                        <Route element={<RequireAuth action="read" subject="Customer" />}>
                            <Route index element={<ContentWrapper component={Customers} title="Customers" />} />
                        </Route>

                        <Route element={<RequireAuth action="update" subject="Customer" />}>
                            <Route
                                path=":customerId/edit"
                                element={
                                    <ContentWrapper component={CustomerConfiguration} title="Customer configuration" />
                                }
                            />
                        </Route>

                        <Route element={<RequireAuth action="create" subject="Customer" />}>
                            <Route
                                path="create"
                                element={
                                    <ContentWrapper component={CustomerConfiguration} title="Customer configuration" />
                                }
                            />
                        </Route>
                    </Route>
                </Route>
                <Route path={SOFTWARE_CONFIGURATION_BASE_PATH}>
                    <Route path="engage">
                        <Route path={SOFTWARE_CONFIG_PLAYER_CREATION_PATH}>
                            <Route element={<RequireAuth action="read" subject="PlayerBatch-Engage" />}>
                                <Route
                                    index
                                    element={
                                        <ContentWrapper
                                            component={EngagePlayers}
                                            customerSearchState={{
                                                externalSystem: [ApplicationDto.externalSystem.ENGAGE],
                                            }}
                                            title="Engage players"
                                            useCustomerContext
                                            useCustomerContextChanger
                                        />
                                    }
                                />
                            </Route>

                            <Route element={<RequireAuth action="update" subject="PlayerBatch-Engage" />}>
                                <Route
                                    path=":playerBatchId/edit"
                                    element={
                                        <ContentWrapper
                                            component={PlayerCreationTool}
                                            title="Player creation"
                                            useCustomerContext
                                        />
                                    }
                                />
                            </Route>

                            <Route
                                path=":playerBatchId/report"
                                element={
                                    <ContentWrapper
                                        component={PlayerBatchReport}
                                        title="Player batch report"
                                        useCustomerContext
                                    />
                                }
                            />

                            <Route
                                path="create"
                                element={
                                    <ContentWrapper
                                        component={PlayerCreationTool}
                                        title="Player creation"
                                        useCustomerContext
                                    />
                                }
                            />
                        </Route>

                        <Route path={SOFTWARE_CONFIG_TEMPLATES_PATH}>
                            <Route element={<RequireAuth action="read" subject="Templates-Engage" />}>
                                <Route
                                    index
                                    element={
                                        <ContentWrapper
                                            component={EngageTemplates}
                                            customerSearchState={{
                                                externalSystem: [ApplicationDto.externalSystem.ENGAGE],
                                            }}
                                            title="Engage templates"
                                            useCustomerContext
                                            useCustomerContextChanger
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>

            {/* Errors */}
            <Route
                path="/error"
                element={<ErrorWrapper layout={DefaultLayout} LayoutContainerProps={layoutContainerProps} />}
            >
                <Route path="pending-connection" element={<PendingConnection />} />
                <Route path="api-error" element={<InternalServerError />} />
                <Route path="not-found" element={<NotFound />} />
            </Route>
        </SentryRoutes>
    );
};

export default Routing;
