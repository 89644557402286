import { MessageDto } from '@zetadisplay/connect-adminpanel-api-client';

const getSeverityForAlert = (type: MessageDto.type) => {
    switch (type) {
        case MessageDto.type.ERROR:
        case 0:
            return 'error';
        case MessageDto.type.SUCCESS:
        case 2:
            return 'success';
        case MessageDto.type.WARNING:
        case 3:
            return 'warning';
        default:
            return 'info';
    }
};

export default getSeverityForAlert;
