import { useAsyncAbortable } from 'react-async-hook';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';
import getCustomer from '../api/get-customer';

const useCustomer = (customerId?: string) => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    return useAsyncAbortable<CustomerDto | undefined>(
        async () => {
            if (!customerId) {
                return undefined;
            }

            return getCustomer(customerId, api);
        },
        [api, customerId],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
        }
    );
};

export default useCustomer;
