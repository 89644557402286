import { createEvent } from 'react-event-hook';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

/**
 * Customer removed event is a UI event - it does not mean customer was necessarily deleted but rather
 * than we want to remove it from the list of the customers.
 * Use cases: Customer was disabled and we want to remove it from the list of the customers.
 * Use cases: Customer was deleted and we want to remove it from the list of the customers.
 */
export const { useCustomerRemovedEventListener, emitCustomerRemovedEvent } =
    createEvent('customer-removed-event')<CustomerDto>();
