import { useCallback, useState } from 'react';
import env from '@beam-australia/react-env';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { getThumbnailUrl } from '@zetadisplay/engage-components/utils/media';
import { Thumbnail } from '@zetadisplay/zeta-ui-components';
import { DefaultNotFoundImage } from '@zetadisplay/zeta-ui-components/assets';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { ListActionOptions } from '../../../../../components/types';
import TemplateInformationResolution from './template-information-resolution';
import TemplateInformationShares from './template-information-shares';
import TemplateListActions from './template-list-actions';

const useStyles = makeStyles()(() => ({
    templateInformationContainer: {
        display: 'flex',
        padding: '8px 16px 16px 46px',
        '& > *': {
            marginRight: '32px',
        },
    },
}));

type TemplateListRow = {
    options: ListActionOptions;
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
};

const TemplateListRow = ({ options, template }: TemplateListRow) => {
    const { classes } = useStyles();
    const [open, setOpen] = useState(false);

    const modified = new Date(template.modified);

    const tableCellSx = {
        borderBottom: 'none',
    };

    const thumbnailUrl =
        getThumbnailUrl(template, true, `${env('ENGAGE_API_PATH')}/${env('ENGAGE_API_VERSION')}`) ||
        DefaultNotFoundImage;

    const collapseRow = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <>
            <TableRow data-testid={`template-row-${template.id}`}>
                <TableCell sx={{ ...tableCellSx, padding: '6px' }}>
                    <IconButton
                        aria-label="expand row"
                        data-testid="expander"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell sx={tableCellSx}>{template.name}</TableCell>

                <TableCell sx={tableCellSx}>
                    {template.liveTemplate && 'Live'}
                    {!template.liveTemplate && 'Transcoded'}
                </TableCell>

                <TableCell sx={tableCellSx}>{modified.toLocaleString()}</TableCell>

                <TableCell sx={tableCellSx}>
                    <TemplateListActions collapseRow={collapseRow} options={options} template={template} />
                </TableCell>
            </TableRow>

            <TableRow data-testid={`template-row-details-${template.id}`}>
                <TableCell colSpan={5} sx={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box className={classes.templateInformationContainer}>
                            <Thumbnail alt={template.name} size={160} source={thumbnailUrl} />

                            <TemplateInformationShares template={template} />

                            <TemplateInformationResolution template={template} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default TemplateListRow;
