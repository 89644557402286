import { Box, Typography } from '@mui/material';
import { LibraryModelsTemplateRelations } from '@zetadisplay/engage-api-client';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';

type TemplateInformationSharesProps = {
    template: DiscriminatedEntity<LibraryModelsTemplateRelations>;
};

const TemplateInformationResolution = ({ template }: TemplateInformationSharesProps) => {
    return (
        <Box sx={{ width: '150px' }} data-testid={`template-resolution-${template.id}`}>
            <Typography sx={{ mb: 0.5 }} variant="h6">
                Resolution
            </Typography>

            <Typography>
                {template.width}x{template.height}
            </Typography>
        </Box>
    );
};

export default TemplateInformationResolution;
