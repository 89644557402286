import { AdminPanelClient, ApiError, CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';

import handleResponseError from '../../dependency-injection/utils/handle-response-error';
import { CustomersOptions } from '../customers';
// import { emitCustomerRemovedEvent } from '../events/customer-removed-event';
import { emitCustomerUpdatedEvent } from '../events/customer-updated-event';

const deactivateCustomerAction = async (
    api: AdminPanelClient,
    customer: CustomerDto,
    options: CustomersOptions
): Promise<CustomerDto | void> => {
    return api.customers
        .deactivateCustomer(customer.id)
        .then((result) => {
            options.notify(`Customer has been successfully deactivated.`, [], 'success');

            // TODO In the future we need to decide correct event based on the view configuration / query params
            emitCustomerUpdatedEvent(result);
            // emitCustomerRemovedEvent(customer);

            return result;
        })
        .catch((e) => {
            handleResponseError(e as ApiError, options.notify);
        });
};

export default deactivateCustomerAction;
