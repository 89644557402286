import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Action, Subject } from '../authorization';
import useAuthorization from '../hooks/use-auth';

export type RequireAuthProps = {
    action: Action;
    subject: Subject;
};

const RequireAuth = ({ action, subject }: RequireAuthProps) => {
    const context = useAuthorization();
    const location = useLocation();

    return context.can(action, subject) ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
