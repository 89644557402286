import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
    ApplicationDto,
    CreateApplicationDto,
    CustomerDto,
    EditApplicationDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { ComponentLoader } from '@zetadisplay/zeta-ui-components';

import useCustomer from '../hooks/use-customer';
import CreateCustomer from './create-customer';
import EditCustomer from './edit-customer';

export type CustomerApplicationFormData<T = CreateApplicationDto | EditApplicationDto> = {
    data: T;
    object?: ApplicationDto;
};

export type CustomerConfigurationOptions<T extends CustomerDto | unknown> = {
    callback?: () => void;
    customer: T;
    notify: (message: string, params: string[], type: 'error' | 'info' | 'success') => void;
};

const CustomerConfiguration = () => {
    const { customerId } = useParams();

    const customer = useCustomer(customerId);

    if (customerId && !customer?.result) {
        return <ComponentLoader />;
    }

    return (
        <Box component="section" data-testid="customer-configuration">
            {!customerId && <CreateCustomer />}

            {customer?.result && <EditCustomer customer={customer.result} />}
        </Box>
    );
};

export default CustomerConfiguration;
