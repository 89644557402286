import { useAsyncAbortable } from 'react-async-hook';
import { CustomerDto, UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../dependency-injection/utils/handle-admin-panel-response-error';

export type UseUsersBatchesReturnType = {
    data: UserBatchDto | undefined;
    error: Error | undefined;
    isLoading: boolean;
    refresh: () => Promise<UserBatchDto | undefined>;
};

const useUserBatch = (customer: CustomerDto, userBatchId?: string): UseUsersBatchesReturnType => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    const userBatch = useAsyncAbortable<UserBatchDto | undefined>(
        async () => {
            if (!userBatchId) {
                return undefined;
            }

            return api.userBatch.getUserBatchById(customer.id, userBatchId).then((data) => data);
        },
        [api.userBatch, customer, userBatchId],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
            setLoading: (state) => ({ ...state, loading: true }),
        }
    );

    return {
        data: userBatch.result,
        error: userBatch.error,
        isLoading: userBatch.loading,
        refresh: userBatch.execute,
    };
};

export default useUserBatch;
