import { useMemo } from 'react';
import { Box } from '@mui/material';
import { UserBatchDto } from '@zetadisplay/connect-adminpanel-api-client';

import Tag from '../../../../components/tag/tag';

export type UserBatchStatusProps = {
    userBatch: UserBatchDto;
};

const UserBatchStatus = ({ userBatch }: UserBatchStatusProps) => {
    const type = useMemo(() => {
        switch (userBatch.status) {
            case UserBatchDto.status.PROCESSING:
                return 'YELLOW';
            case UserBatchDto.status.EXECUTED:
                return 'GREEN';
            case UserBatchDto.status.FAILED:
                return 'RED';
            case UserBatchDto.status.PENDING:
            default:
                return 'DEFAULT';
        }
    }, [userBatch.status]);

    return (
        <Box>
            <Tag text={userBatch.status} type={type} />
        </Box>
    );
};

export default UserBatchStatus;
