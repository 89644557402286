import { Box } from '@mui/material';
import { PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import AuthorInformation from '../../../../components/author-information/author-information';

const useStyles = makeStyles()(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
}));

export type PlayerBatchActionsProps = {
    callback: () => void;
    isSubmitting: boolean;
    isValid: boolean;
    playerBatch?: PlayerBatchDto;
};

const PlayerBatchActions = ({ callback, isSubmitting, isValid, playerBatch }: PlayerBatchActionsProps) => {
    const { classes } = useStyles();

    const t = useTranslation();

    return (
        <Box sx={{ position: 'relative' }} data-testid="player-batch-actions">
            <div className={classes.root}>
                <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={callback} />

                <Button
                    busy={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    kind="primaryAction"
                    label={t.trans('common.action.save')}
                    type="submit"
                />
            </div>

            {playerBatch && <AuthorInformation<PlayerBatchDto> item={playerBatch} />}
        </Box>
    );
};

export default PlayerBatchActions;
