import React, { useCallback } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { CreatePlayerBatchDto, EditPlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ConfigModelsAttributeDetails } from '@zetadisplay/engage-api-client';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { Autocomplete } from '@zetadisplay/zeta-ui-components';

import PlayerLabelValue from './player-label-value';

export type PlayerLabelsProps = {
    control?: Control<CreatePlayerBatchDto | EditPlayerBatchDto>;
    disabled: boolean;
    index: number;
    workspaceLabels: DiscriminatedEntity<ConfigModelsAttributeDetails>[];
};

const PlayerLabels = ({ control, disabled, index, workspaceLabels }: PlayerLabelsProps) => {
    const { fields, append, remove } = useFieldArray({ name: `batchPlayers.${index}.labels`, control });

    const onChangeCallback = useCallback(
        (labelId: number) => {
            append({
                labelId,
                valueId: 0,
            });
        },
        [append]
    );

    const handleRemoveLabel = useCallback(
        (labelIndex: number) => {
            remove(labelIndex);
        },
        [remove]
    );

    const externalEntityId = useWatch({ name: 'externalEntityId' });

    const isWorkspaceSet = externalEntityId !== '';

    return (
        <Grid container spacing={2} data-testid="player-labels">
            <Grid item xs={12} data-testid="select-label">
                {/* Extra provider here as dummy solution to isolate this input from main form */}
                <FormProviderWrapper>
                    <Autocomplete<DiscriminatedEntity<ConfigModelsAttributeDetails>, false, true, false>
                        ref={null}
                        autoSelect={false}
                        blurOnSelect
                        disabled={disabled || !isWorkspaceSet}
                        disableClearable
                        fullWidth
                        getOptionDisabled={(option) => fields.some((field) => field.labelId === option.id)}
                        getOptionLabel={(option) => option.name}
                        name="label-select"
                        onChange={(event, value) => {
                            onChangeCallback(value.id);
                        }}
                        options={workspaceLabels}
                        placeholder="Select label"
                        variant="outlined"
                    />
                </FormProviderWrapper>
            </Grid>

            <Grid item xs={12}>
                <Box component="div" sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
                    {fields?.map((field, labelIndex) => (
                        <PlayerLabelValue
                            control={control}
                            disabled={disabled}
                            key={field.id}
                            index={labelIndex}
                            onRemove={handleRemoveLabel}
                            playerIndex={index}
                            values={field}
                        />
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};

export default PlayerLabels;
