import React, { useMemo } from 'react';
import { OpenAPIConfig } from '@zetadisplay/connect-adminpanel-api-client';

import createApi from '../utils/create-api';
import DependencyInjectionContext from './dependency-injection-context';

type Props = React.PropsWithChildren<{
    accessToken?: string;
    apiConfig: OpenAPIConfig;
}>;

const DependencyInjectionProvider = ({ accessToken, apiConfig, children }: Props) => {
    const values = useMemo(() => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('DependencyInjectionProvider creating a new client', accessToken);
        }

        return {
            api: createApi({ ...apiConfig, TOKEN: accessToken }),
        };
    }, [accessToken, apiConfig]);

    return <DependencyInjectionContext.Provider value={values}>{children}</DependencyInjectionContext.Provider>;
};

export default DependencyInjectionProvider;
