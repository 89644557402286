import { ApplicationDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';

// Interface for configurable Applications on UI
export interface Application {
    id: ApplicationDto['externalSystem'];
    configurable: boolean;
    enabled: boolean | ((user: UserDto) => boolean);
    name: string;
    externalLinkType?: string;
}

export const APPLICATIONS: Application[] = [
    { id: ApplicationDto.externalSystem.ADMIN_PANEL, configurable: false, enabled: true, name: 'AdminPanel' },
    { id: ApplicationDto.externalSystem.CONTENT_EDITOR, configurable: false, enabled: true, name: 'ContentEditor' },
    { id: ApplicationDto.externalSystem.CONTROL, configurable: false, enabled: false, name: 'Control' },
    {
        id: ApplicationDto.externalSystem.ENGAGE,
        configurable: true,
        enabled: true,
        name: 'Engage',
        externalLinkType: 'workspace',
    },
    { id: ApplicationDto.externalSystem.ENGAGE_PLUS, configurable: false, enabled: false, name: 'EngagePlus' },
    {
        id: ApplicationDto.externalSystem.UAC,
        configurable: false,
        enabled: (user: UserDto) => user.type === UserDto.type.ADMINISTRATOR,
        name: 'UAC',
    },
];
