import React, { useCallback, useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Grid } from '@mui/material';
import { BatchPlayerDto, CreatePlayerBatchDto, EditPlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SelectInput, TextInput } from '@zetadisplay/engage-components';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components/form-provider-wrapper';

type ResolutionOption = {
    id: number;
    name: string;
    width: number;
    height: number;
};

const CUSTOM_OPTION: ResolutionOption = { id: 5, name: 'Custom', width: 0, height: 0 };

const RESOLUTION_OPTIONS: ResolutionOption[] = [
    { id: 1, name: 'Landscape FullHD', width: 1920, height: 1080 },
    { id: 2, name: 'Portrait FullHD', width: 1080, height: 1920 },
    { id: 3, name: 'Landscape UHD', width: 3840, height: 2160 },
    { id: 4, name: 'Portrait UHD', width: 2160, height: 3840 },
    CUSTOM_OPTION,
];

const getOptionByWidthAndHeight = (width: number, height: number): ResolutionOption => {
    const defaultOption = RESOLUTION_OPTIONS.find((option) => option.width === width && option.height === height);

    return defaultOption || CUSTOM_OPTION;
};

type PlayerResolutionProps<
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
> = {
    index: number;
    player: T;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>;
};

const PlayerResolution = <
    T extends
        | FieldArrayWithId<CreatePlayerBatchDto, 'batchPlayers', 'key'>
        | FieldArrayWithId<EditPlayerBatchDto, 'batchPlayers', 'key'>
>({
    index,
    player,
    setValue,
}: PlayerResolutionProps<T>) => {
    const executed = player.status === BatchPlayerDto.status.EXECUTED;
    const [selectedOption, setSelectedOption] = useState(
        getOptionByWidthAndHeight(player.width || 1920, player.height || 1080)
    );

    const handleResolutionChange = useCallback(
        (option: ResolutionOption) => {
            setSelectedOption(option);
            setValue(`batchPlayers.${index}.width`, option.width);
            setValue(`batchPlayers.${index}.height`, option.height);
        },
        [index, setValue]
    );

    return (
        <>
            <Grid item xs={12} data-testid="resolution-container">
                <FormProviderWrapper>
                    <SelectInput
                        defaultValue={selectedOption.id}
                        fullWidth
                        name="resolution-quick-menu"
                        options={RESOLUTION_OPTIONS}
                        onChangeCallback={(event) => {
                            handleResolutionChange(
                                RESOLUTION_OPTIONS.find((option) => option.id === event.target.value) || CUSTOM_OPTION
                            );
                        }}
                    />
                </FormProviderWrapper>
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    defaultLabel="common.form.input.width"
                    defaultValue={player.width}
                    disabled={executed || selectedOption.id !== CUSTOM_OPTION.id}
                    name={`batchPlayers.${index}.width`}
                    parseAsNumber
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    defaultLabel="common.form.input.height"
                    defaultValue={player.height}
                    disabled={executed || selectedOption.id !== CUSTOM_OPTION.id}
                    name={`batchPlayers.${index}.height`}
                    parseAsNumber
                    required
                    rules={{
                        required: 'common.validation.required',
                    }}
                />
            </Grid>
        </>
    );
};

export default PlayerResolution;
