enum Action {
    Manage = 'manage',
    Create = 'create',
    Read = 'read',
    Update = 'update',
    Delete = 'delete',
}

export const ALL_ACTION_STRINGS = Object.values(Action);

export default Action;
