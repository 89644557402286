import { useCallback, useMemo } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';

import ListToolbar from '../../../../components/list-toolbar/list-toolbar';
import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import TableRowSkeleton from '../../../../components/table/table-row-skeleton';
import { ListActionOptions } from '../../../../components/types';
import useCustomers from '../../../customers/hooks/use-customers';
import { emitUserSearchEvent } from '../../events/user-search-event';
import useSorting from '../../hooks/use-sorting';
import useUsers from '../../hooks/use-users';
import useUsersSearchFilters, { UsersSearchFilters } from '../../hooks/use-users-search-filters';
import UserApplications from './user-applications';
import UserCustomer from './user-customer';
import UserListActions from './user-list-actions';
import UserStatus from './user-status';
import UserType from './user-type';

const COLUMNS: ColumnType[] = [
    { label: 'Username' },
    { label: 'Name' },
    { label: 'Customer' },
    { label: 'Application access', sx: { maxWidth: '140px' } },
    { label: 'Type', sx: { width: '220px' } },
    { label: 'Status', sx: { width: '120px' } },
    { label: 'Actions', sx: { textAlign: 'center', width: '100px' } },
];

const SEARCH_DOMAIN = 'users';

const UserList = () => {
    const snackbar = useTranslatedSnackbar();
    const { sort, sortingOptions } = useSorting();
    const { data: customers } = useCustomers();

    const filters = useUsersSearchFilters(customers);

    const handleSearchStateChange = useCallback((arg: SearchState<UsersSearchFilters>) => {
        emitUserSearchEvent(arg);
    }, []);

    const { data, error, isLoading } = useUsers(false, true, sort?.sortBy, sort?.order, SEARCH_DOMAIN);

    const options: ListActionOptions = useMemo(
        () => ({
            notify: snackbar,
        }),
        [snackbar]
    );

    const renderUsers = () => {
        if (data?.length === 0 || error) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No users found
                    </TableCell>
                </TableRow>
            );
        }

        return data?.map((user: UserDto) => (
            <TableRow key={user.id} data-testid={`user-row-${user.id}`}>
                <TableCell>{user.username}</TableCell>

                <TableCell data-testid="name-container">
                    <Typography noWrap>
                        {user.firstName} {user.lastName}
                    </Typography>
                </TableCell>

                <TableCell>
                    <UserCustomer user={user} />
                </TableCell>

                <TableCell>
                    <UserApplications user={user} />
                </TableCell>

                <TableCell>
                    <UserType user={user} />
                </TableCell>

                <TableCell>
                    <UserStatus user={user} />
                </TableCell>

                <TableCell>
                    <UserListActions options={options} user={user} />
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <Box component="section" data-testid="user-list">
            <ListToolbar<UserDto[], UsersSearchFilters>
                isSortingActive={!!sort}
                item={data}
                ListToolbarSearchProps={{
                    domain: SEARCH_DOMAIN,
                    filters,
                    onChangeCallback: handleSearchStateChange,
                }}
                sortingOptions={sortingOptions}
            />

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}

                        {!isLoading && renderUsers()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UserList;
