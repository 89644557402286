import React, { useCallback, useState } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { Expander } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import Action from '../../../modules/authorization/action';
import { Subject } from '../../../modules/authorization/authorization';
import useAuthorization from '../../../modules/authorization/hooks/use-auth';
import NavigationLink, { NavigationLinkType } from './navigation-link';

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: (theme.palette.dark && theme.palette.background.content) || theme.palette.background.main,
        paddingLeft: 12,
    },
    title: { paddingLeft: 0, paddingRight: 0, paddingTop: 4, paddingBottom: 4 },
    links: { paddingLeft: 25, paddingBottom: 4 },
    'links--closed': {
        display: 'none',
    },
    text: {
        cursor: 'pointer',
    },
}));

export type NavigationSubSectionType = {
    auth: [Action, Subject] | [Action, Subject][];
    links: NavigationLinkType[];
    title: string;
    titleHref?: string;
};

type Props = {
    section: NavigationSubSectionType;
};

const NavigationSubsection = ({ section }: Props) => {
    const { classes, cx } = useStyles();
    const context = useAuthorization();

    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpanding = useCallback(() => {
        setExpanded((state) => !state);
    }, []);

    return (
        <List className={classes.root} data-testid={`section-${section.title}`}>
            {section.titleHref && (
                <NavigationLink
                    key={section.titleHref}
                    link={{ href: section.titleHref, text: section.title }}
                    level="main"
                />
            )}

            {!section.titleHref && (
                <ListItem className={classes.title}>
                    <Expander expanded={expanded} onClick={handleExpanding} />

                    <ListItemText className={classes.text} onClick={handleExpanding} primary={section.title} />
                </ListItem>
            )}

            <List className={cx({ [classes.links]: true, [classes['links--closed']]: !expanded })}>
                {section.links
                    .filter((link) => {
                        return !link.auth || context.can(...link.auth);
                    })
                    .map((link) => (
                        <NavigationLink key={link.href} link={link} />
                    ))}
            </List>
        </List>
    );
};

export default NavigationSubsection;
