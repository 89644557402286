import React, { useCallback, useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate } from 'react-router-dom';
// import { DevTool } from '@hookform/devtools';
import { Box } from '@mui/material';
import { CreateCustomerUserDto, CreateUserDto, UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { EditCustomerUserDto } from '@zetadisplay/connect-adminpanel-api-client/models/EditCustomerUserDto';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';
import { dequal } from 'dequal';

import FormActions from '../../../components/form/form-actions';
import { APP_NAME } from '../../../config/contants';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import PLACEHOLDER_CUSTOMER from '../../customers/index';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import createUserAction from './actions/create-user-action';
import { UserConfigurationOptions } from './user-configuration';
import UserCustomers from './user-customers';
import UserProfile from './user-profile';

export interface CREATE_USER_FORM_VALUES extends FieldValues {
    user: CreateUserDto;
}

const defaultValues: CREATE_USER_FORM_VALUES = {
    user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        username: '',
        language: UserDto.language.EN_US,
        type: UserDto.type.USER,
        customerUsers: [],
    },
};

const CreateUser = () => {
    useDocumentTitle(APP_NAME, ['Users', 'Create']);
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const navigate = useNavigate();

    const methods = useForm<CREATE_USER_FORM_VALUES>({
        defaultValues,
        mode: 'onChange',
    });
    const { control, formState, handleSubmit, reset, setError, setValue, watch } = methods;

    const { isSubmitting } = formState;

    const { fields, append, update, remove } = useFieldArray({ name: 'user.customerUsers', control, keyName: 'key' });

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<CREATE_USER_FORM_VALUES>(
        'ap.user',
        'new'
    );

    const [hasInitialized, setHasInitialized] = useState(false);

    const values = watch();

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/users`);
        });
    }, [defuse, navigate]);

    const options: UserConfigurationOptions = {
        callback: actionsCallback,
        user: undefined,
        notify: snackbar,
    };

    const addCustomer = useCallback(
        (data?: CreateCustomerUserDto | EditCustomerUserDto) => {
            append(data || { customer: PLACEHOLDER_CUSTOMER, applicationAccessConfigurations: [] });
        },
        [append]
    );

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(defaultValues, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...defaultValues, ...preservedValues });
        }

        setHasInitialized(true);
    }, [hasInitialized, preservedValues, reset, snackbar]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => setPreservedValues(value as CREATE_USER_FORM_VALUES));
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const onSubmit: SubmitHandler<CREATE_USER_FORM_VALUES> = (data) => createUserAction(api, data, options);

    return (
        <Box component="div" data-testid="create-user">
            <FormProvider<CREATE_USER_FORM_VALUES> {...methods}>
                {/* <DevTool control={control} placement="top-left" /> */}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <UserProfile
                        addCustomer={addCustomer}
                        control={control}
                        defaultValues={{ ...defaultValues.user }}
                        setError={setError}
                        setValue={setValue}
                    />

                    <UserCustomers
                        addCustomer={addCustomer}
                        control={control}
                        fields={fields}
                        remove={remove}
                        setValue={setValue}
                        update={update}
                        values={values}
                    />

                    <FormActions
                        callback={actionsCallback}
                        name="user-configuration-actions"
                        isSubmitting={isSubmitting}
                    />
                </form>
            </FormProvider>
        </Box>
    );
};

export default CreateUser;
