import { useTranslation } from '@zetadisplay/zeta-localization';
import { Button } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
}));

export type PlayerBatchReportActionsProps = {
    cancelCallback: () => void;
    handleDownloadPdf: () => void;
};

const PlayerBatchReportActions = ({ cancelCallback, handleDownloadPdf }: PlayerBatchReportActionsProps) => {
    const { classes, cx } = useStyles();

    const t = useTranslation();

    return (
        <div className={cx(classes.root, 'no-printing')} data-testid="player-batch-report-actions">
            <Button kind="secondaryAction" label={t.trans('common.action.cancel')} onClick={cancelCallback} />
            <Button kind="primaryAction" label="Export PDF" onClick={handleDownloadPdf} />
        </div>
    );
};

export default PlayerBatchReportActions;
