import { useCallback } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ActionGroup, ContextMenu, Search, SearchFilters } from '@zetadisplay/zeta-ui-components';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';
import { ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

type ListToolbarSearchProps<T extends SearchFilters> = {
    domain: string;
    filters: T;
    onChangeCallback?: (state: SearchState<T>) => void;
};

type ListToolbarProps<T, TSearchFilters extends SearchFilters> = {
    isSortingActive?: boolean;
    item?: T;
    ListToolbarSearchProps?: ListToolbarSearchProps<TSearchFilters>;
    sortingOptions?: ActionGroup<T>[];
};

const ListToolbar = <T, TSearchFilters extends SearchFilters>({
    isSortingActive,
    item,
    ListToolbarSearchProps,
    sortingOptions,
}: ListToolbarProps<T, TSearchFilters>) => {
    const theme: ZetaTheme = useTheme();

    const renderSortingOptions = useCallback(() => {
        if (item) {
            return <ContextMenu actions={sortingOptions} active={isSortingActive} item={item} icon="SORT" />;
        }

        return <Skeleton data-testid="skeleton" variant="rectangular" width={36} height={36} />;
    }, [isSortingActive, item, sortingOptions]);

    return (
        <Box
            data-testid="list-toolbar"
            sx={{
                alignItems: 'center',
                borderBottom: `1px solid ${theme.palette.background.disabledText}15`,
                display: 'flex',
                height: '57px',
                padding: '6px 32px 6px 16px',
            }}
        >
            {ListToolbarSearchProps && (
                <Box sx={{ display: 'flex', flexGrow: 1, height: '44px', marginRight: '4px' }}>
                    <Search<TSearchFilters>
                        domain={ListToolbarSearchProps.domain}
                        filters={ListToolbarSearchProps.filters}
                        onChangeCallback={ListToolbarSearchProps.onChangeCallback}
                    />
                </Box>
            )}

            {sortingOptions && <Box sx={{ display: 'flex' }}>{renderSortingOptions()}</Box>}
        </Box>
    );
};

export default ListToolbar;
