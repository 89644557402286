import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Autocomplete } from '@zetadisplay/zeta-ui-components';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

const useStyles = makeStyles()({
    'MuiAutocomplete-listbox': {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

export type CustomerInputProps = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    defaultValue?: CustomerDto;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    loading: boolean;
    name: string;
    options?: CustomerDto[];
    onChangeCallback?: (customer: CustomerDto) => void;
    readOnly?: boolean;
    required?: boolean;
    rules?: Record<string, unknown>;
    useLabel?: boolean;
    variant?: 'standard' | 'outlined';
};

const CustomerInput = ({
    control,
    defaultValue = undefined,
    disabled = false,
    fullWidth = false,
    label = 'Customer',
    loading,
    name,
    options = [],
    onChangeCallback,
    readOnly,
    required = false,
    rules = undefined,
    useLabel = true,
    variant = 'outlined',
}: CustomerInputProps) => {
    const { classes } = useStyles();

    return (
        <Controller
            control={control}
            // TODO PLACEHOLDER_CUSTOMER being used on a form, that should be removed to simplify this prop
            defaultValue={defaultValue && defaultValue.id.length > 0 ? defaultValue : undefined}
            name={name}
            render={({ field: { onChange, value, ...props } }) => (
                <Autocomplete<CustomerDto, false, true, false>
                    {...props}
                    ref={null}
                    autoSelect={false}
                    blurOnSelect
                    classes={{
                        listbox: classes['MuiAutocomplete-listbox'],
                    }}
                    disabled={disabled}
                    disableClearable
                    defaultValue={defaultValue && defaultValue.id.length > 0 ? defaultValue : undefined}
                    fullWidth={fullWidth}
                    getOptionLabel={(option) => option?.name}
                    isOptionEqualToValue={(option, _value) => option.id === _value.id}
                    label={(useLabel && label) || undefined}
                    loading={loading}
                    name={name}
                    options={options}
                    onChange={(event, _value) => {
                        onChange(_value);
                        onChangeCallback?.(_value);
                    }}
                    placeholder="Select customer"
                    readOnly={readOnly}
                    required={required}
                    variant={variant}
                    value={value || null}
                />
            )}
            rules={rules}
        />
    );
};

export default CustomerInput;
