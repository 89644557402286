import { useAsyncAbortable } from 'react-async-hook';
import { ApplicationDto, CustomerDto, PlayerBatchReportDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';

import useAdminPanelApi from '../../../dependency-injection/hooks/use-admin-panel-api';
import handleAdminPanelResponseError from '../../../dependency-injection/utils/handle-admin-panel-response-error';

const usePlayerBatchReport = (customer: CustomerDto, application?: ApplicationDto | null, playerBatchId?: string) => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();

    return useAsyncAbortable<PlayerBatchReportDto | undefined>(
        async () => {
            if (!playerBatchId || !application) {
                return undefined;
            }

            return api.playerBatch
                .getPlayerBatchReport(customer.id, application.id, playerBatchId)
                .then((data) => data);
        },
        [api.playerBatch, application, customer.id, playerBatchId],
        {
            onError: (error) => snackbar(handleAdminPanelResponseError(error), undefined, 'error'),
        }
    );
};

export default usePlayerBatchReport;
