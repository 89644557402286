import { useMemo } from 'react';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { SearchFilter } from '@zetadisplay/zeta-ui-components';

import { APPLICATIONS } from '../../applications/application';

export type UsersSearchFilters = {
    search: SearchFilter<'search-field', string>;
    customers: SearchFilter<'autocomplete', string>;
    applications: SearchFilter<'select', string>;
    status: SearchFilter<'select', string>;
};

const useUsersSearchFilters = (customers: CustomerDto[] | undefined) => {
    const searchFilters: UsersSearchFilters = useMemo(() => {
        const searchFilter: SearchFilter<'search-field', string> = {
            defaultValue: undefined,
            label: 'Search',
            name: 'search',
            type: 'search-field',
        };

        const customerFilter: SearchFilter<'autocomplete', string> = {
            defaultValue: undefined,
            label: 'Customer',
            name: 'customers',
            multiple: true,
            options:
                customers?.map((customer) => ({
                    id: customer.id,
                    name: customer.name,
                    value: customer.id,
                })) || [],
            type: 'autocomplete',
            FormControlProps: {
                sx: {
                    width: '220px',
                },
            },
        };

        const applicationFilter: SearchFilter<'select', string> = {
            defaultValue: undefined,
            label: 'Application',
            name: 'applications',
            multiple: true,
            options: APPLICATIONS.map((application) => ({
                id: application.id,
                name: application.name,
                value: application.id,
            })),
            type: 'select',
        };

        const statusFilter: SearchFilter<'select', string> = {
            defaultValue: undefined,
            label: 'Status',
            name: 'status',
            multiple: false,
            options: [
                { id: 'active', name: 'Active', value: 'active' },
                { id: 'disabled', name: 'Disabled', value: 'disabled' },
            ],
            type: 'select',
            FormControlProps: {
                sx: {
                    width: '100px',
                },
            },
        };

        return {
            search: searchFilter,
            customers: customerFilter,
            applications: applicationFilter,
            status: statusFilter,
        };
    }, [customers]);

    return searchFilters;
};

export default useUsersSearchFilters;
