import React from 'react';
import { Control } from 'react-hook-form';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CreatePlayerBatchDto, EditPlayerBatchDto, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { NetworkModelsContact, NetworkModelsPlayersPlayerLocation } from '@zetadisplay/engage-api-client';
import { SelectInput, TextInput } from '@zetadisplay/engage-components';
import { DiscriminatedEntity } from '@zetadisplay/engage-components/models';
import { Accordion } from '@zetadisplay/zeta-ui-components';
import { makeStyles, ZetaTheme } from '@zetadisplay/zeta-ui-components/utils/theme';

import { COLUMN_GRID_ITEM_PROPS, DEFAULT_LOCATION } from '../constants';
import PlayerContact from './player-contact';
import PlayerLocation from './player-location';

const useStyles = makeStyles()(() => ({
    root: {
        marginTop: 6,
    },
}));

export type PlayerGeneralInformationProps<T extends CreatePlayerBatchDto | EditPlayerBatchDto> = {
    // TODO Update input components in enco
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    updateContactInputs: (arg: DiscriminatedEntity<NetworkModelsContact>) => void;
    updateLocationInputs: (arg: NetworkModelsPlayersPlayerLocation) => void;
    values: T;
};

const PlayerGeneralInformation = <T extends CreatePlayerBatchDto | EditPlayerBatchDto>({
    control,
    updateContactInputs,
    updateLocationInputs,
    values,
}: PlayerGeneralInformationProps<T>) => {
    const { classes } = useStyles();
    const theme: ZetaTheme = useTheme();

    return (
        <Accordion defaultExpanded title="General information">
            <div className={classes.root} data-testid="player-batch-general-information">
                <Grid container spacing={3}>
                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: '12px' }} variant="h5">
                                    &nbsp;
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextInput control={control} defaultLabel="Batch/Project name" name="name" />
                            </Grid>

                            <Grid item xs={12}>
                                <SelectInput
                                    control={control}
                                    defaultValue={values?.status || PlayerBatchDto.status.DRAFT}
                                    label="Status"
                                    LabelProps={{
                                        backgroundColor:
                                            (theme.palette.dark && theme.palette.background.content) ||
                                            theme.palette.background.main,
                                    }}
                                    name="status"
                                    options={[
                                        {
                                            id: PlayerBatchDto.status.DRAFT,
                                            value: PlayerBatchDto.status.DRAFT,
                                        },
                                        {
                                            id: PlayerBatchDto.status.READY,
                                            value: PlayerBatchDto.status.READY,
                                        },
                                        {
                                            id: PlayerBatchDto.status.FAILED,
                                            disabled: true,
                                            value: PlayerBatchDto.status.FAILED,
                                        },
                                    ]}
                                    rules={{
                                        validate: (value: PlayerBatchDto.status) => {
                                            const batchPlayers = values?.batchPlayers || [];

                                            if (batchPlayers.length === 0 && value === PlayerBatchDto.status.READY) {
                                                return false;
                                            }

                                            return [PlayerBatchDto.status.DRAFT, PlayerBatchDto.status.READY].includes(
                                                value
                                            );
                                        },
                                    }}
                                    testId="select-status"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <PlayerLocation
                            control={control}
                            updateLocationInputs={updateLocationInputs}
                            values={values?.location || DEFAULT_LOCATION}
                        />
                    </Grid>

                    <Grid {...COLUMN_GRID_ITEM_PROPS}>
                        <PlayerContact control={control} updateContactInputs={updateContactInputs} />
                    </Grid>
                </Grid>
            </div>
        </Accordion>
    );
};

export default PlayerGeneralInformation;
