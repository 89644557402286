import React from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import env from '@beam-australia/react-env';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { locales } from '@zetadisplay/engage-components/locales';
import { TranslationProvider } from '@zetadisplay/zeta-localization';
import { SnackbarProvider } from 'notistack';

import oktaConfig from './config/oidcConfiugration';
import Routing from './routing/routing';

const reactPlugin = new ReactPlugin();

if (env('APPLICATIONINSIGHTS_CONNECTION_STRING')) {
    const appInsights = new ApplicationInsights({
        config: {
            connectionString: env('APPLICATIONINSIGHTS_CONNECTION_STRING'),
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            extensions: [reactPlugin],
        },
    });

    appInsights.loadAppInsights();
}

const oktaAuth = new OktaAuth(oktaConfig);

/**
 * With @okta/okta-react <Security /> and routes are quite closely coupled together,
 * because we have to handle authState (see <LayoutViewWrapper /> so
 * our providers which are relying on valid accessToken are not included here.
 *
 * You'll find them from LayoutViewWrapper, which should be fine _if_ we are
 * using memo correctly.
 *
 * @constructor
 */
const App = () => {
    const navigate = useNavigate();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routing />
        </Security>
    );
};

/**
 * <App /> needs access to Router for useNavigate().
 *
 * When navigation occurs, Router changes so the providers we do not wish to re-render
 * are above the <Router />
 *
 * @constructor
 */
const AppWithRouterAccess = () => (
    <TranslationProvider defaultLocale="en-US" translations={locales}>
        <SnackbarProvider maxSnack={5}>
            <Router basename={process.env.PUBLIC_URL}>
                <App />
            </Router>
        </SnackbarProvider>
    </TranslationProvider>
);

export default withAITracking(reactPlugin, AppWithRouterAccess);
