import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Subscription } from 'react-hook-form/dist/utils/createSubject';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CreateApplicationDto, CreateCustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { usePreserveFormState, useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { dequal } from 'dequal';

import FormActions from '../../../components/form/form-actions';
import { ACCESS_CONFIGURATION_BASE_PATH } from '../../../routing/constants';
import { APPLICATIONS } from '../../applications/application';
import useAdminPanelApi from '../../dependency-injection/hooks/use-admin-panel-api';
import createCustomerAction from './actions/create-customer-action';
import CustomerApplications from './customer-applications';
import { CustomerApplicationFormData, CustomerConfigurationOptions } from './customer-configuration';
import CustomerProfile from './customer-profile';

// TODO Refactor the whole form as now it's possible to use CreateCustomerDto as the form type
export type CREATE_CUSTOMER_FORM_VALUES = {
    customer: CreateCustomerDto;
    applications: CustomerApplicationFormData<CreateApplicationDto>[];
};

const applicationsToDTO = (): CustomerApplicationFormData<CreateApplicationDto>[] => {
    return APPLICATIONS.map((application) => {
        return {
            data: {
                enabled: false,
                externalSystem: application.id,
                externalLinkType: application.externalLinkType || '',
                externalEntityId: '',
                externalEntityName: '',
            },
            object: undefined,
        };
    });
};

const defaultValues: CREATE_CUSTOMER_FORM_VALUES = {
    customer: {
        name: '',
        logoUrl: '',
        contact: '',
        phoneNumber: '',
        language: 'en-US',
        parentCustomerId: '',
    },
    applications: applicationsToDTO(),
};

const CreateCustomer = () => {
    const api = useAdminPanelApi();
    const snackbar = useTranslatedSnackbar();
    const navigate = useNavigate();

    const methods = useForm<CREATE_CUSTOMER_FORM_VALUES>({
        defaultValues,
    });
    const { control, formState, handleSubmit, reset, watch } = methods;

    const { isSubmitting } = formState;

    const { defuse, preservedValues, setPreservedValues } = usePreserveFormState<CREATE_CUSTOMER_FORM_VALUES>(
        'ap.customer',
        'new'
    );

    const [hasInitialized, setHasInitialized] = useState(false);

    const values = watch();
    const { applications } = values;

    const actionsCallback = useCallback(() => {
        defuse().then(() => {
            navigate(`/${ACCESS_CONFIGURATION_BASE_PATH}/customers`);
        });
    }, [defuse, navigate]);

    const options: CustomerConfigurationOptions<undefined> = {
        callback: actionsCallback,
        customer: undefined,
        notify: snackbar,
    };

    /**
     * Initialization - if session storage contains unsaved values, apply them on the form state
     */
    useEffect(() => {
        if (hasInitialized) {
            return;
        }

        if (preservedValues && !dequal(defaultValues, preservedValues)) {
            snackbar('Previously unsaved values were found & applied', [], 'info');
            reset({ ...defaultValues, ...preservedValues });
        }

        setHasInitialized(true);
    }, [hasInitialized, preservedValues, reset, snackbar]);

    /**
     * Monitor values changing and preserve them
     */
    useEffect(() => {
        let subscription: Subscription | null = null;

        if (hasInitialized) {
            subscription = watch((value) => setPreservedValues(value as CREATE_CUSTOMER_FORM_VALUES));
        }

        return () => subscription?.unsubscribe();
    }, [hasInitialized, setPreservedValues, watch]);

    const onSubmit: SubmitHandler<CREATE_CUSTOMER_FORM_VALUES> = (data) => createCustomerAction(api, data, options);

    return (
        <Box component="div" data-testid="create-customer">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomerProfile control={control} defaultValues={{ ...defaultValues.customer }} />

                    <CustomerApplications<CREATE_CUSTOMER_FORM_VALUES> applications={applications} control={control} />

                    <FormActions callback={actionsCallback} name="customer-actions" isSubmitting={isSubmitting} />
                </form>
            </FormProvider>
        </Box>
    );
};

export default CreateCustomer;
