import { useCallback, useMemo } from 'react';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { CustomerDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { SearchState } from '@zetadisplay/zeta-ui-components/components/search';

import ListToolbar from '../../../../components/list-toolbar/list-toolbar';
import { ColumnType } from '../../../../components/table/table';
import TableHead from '../../../../components/table/table-head';
import TableRowSkeleton from '../../../../components/table/table-row-skeleton';
import useAuthorization from '../../../authorization/hooks/use-auth';
import { CustomerTreeNode, treeToSelector } from '../../customer-configuration/customer-profile';
import { buildNewHierarchy } from '../../customer-configuration/helper/hierarchy.util';
import { CustomersOptions } from '../../customers';
import { emitCustomerSearchEvent } from '../../events/customer-search-event';
import useCustomers from '../../hooks/use-customers';
import useCustomersSearchFilters, { CustomersSearchFilters } from '../../hooks/use-customers-search-filters';
import CustomerApplications from './customer-applications';
import CustomerListActions from './customer-list-actions';
import CustomerStatus from './customer-status';

const COLUMNS: ColumnType[] = [
    { label: 'Name' },
    { label: 'Applications' },
    { label: 'Status', sx: { width: '120px' } },
    { label: 'Actions', sx: { textAlign: 'center', width: '100px' } },
];

const SEARCH_DOMAIN = 'customers';

/**
 * TODO If we'll decide to use tables for data lists, we should abstract the implementation.
 * This is now just a placeholder. Not a final proposal for our lists..
 */
const CustomerList = () => {
    const context = useAuthorization();
    const snackbar = useTranslatedSnackbar();
    const actionOptions: CustomersOptions = useMemo(
        () => ({
            notify: snackbar,
        }),
        [snackbar]
    );

    const filters = useCustomersSearchFilters();

    const handleSearchStateChange = useCallback((arg: SearchState<CustomersSearchFilters>) => {
        emitCustomerSearchEvent(arg);
    }, []);

    const { data, error, isLoading } = useCustomers(undefined, undefined, true, SEARCH_DOMAIN);

    const dataHierarchy: CustomerTreeNode[] = buildNewHierarchy(data);

    const listCustomers: CustomerTreeNode[] = [];
    const customerList = treeToSelector(dataHierarchy);
    listCustomers.push(...customerList);

    const renderCustomers = () => {
        if (listCustomers?.length === 0 || error) {
            return (
                <TableRow>
                    <TableCell colSpan={COLUMNS.length} sx={{ textAlign: 'center' }}>
                        No customers found
                    </TableCell>
                </TableRow>
            );
        }
        return listCustomers?.map((customer) => {
            const accessToCustomer = context.can('read', 'Customer', customer.id);

            // Access to customer can be denied when the user has a relation to the customer
            // but does not have AP permission access to the customer.
            if (!accessToCustomer) {
                return null;
            }

            return (
                <TableRow key={customer.id} data-testid={`customer-row-${customer.id}`}>
                    <TableCell
                        sx={{
                            paddingLeft: (customer.depth > 1 && `${0.5 + 0.5 * customer.depth}em`) || '1em',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            {customer.depth > 1 && (
                                <SubdirectoryArrowRightIcon
                                    sx={{ display: 'inline', paddingRight: '5px', fontSize: '1.4rem' }}
                                />
                            )}
                            {customer.name}
                        </Box>
                    </TableCell>

                    <TableCell>
                        <CustomerApplications customer={customer} />
                    </TableCell>

                    <TableCell>
                        <CustomerStatus customer={customer} />
                    </TableCell>

                    <TableCell>
                        <CustomerListActions customer={customer} options={actionOptions} />
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Box component="section" data-testid="customer-list">
            <ListToolbar<CustomerDto[], CustomersSearchFilters>
                isSortingActive={false}
                item={data}
                ListToolbarSearchProps={
                    (filters && {
                        domain: SEARCH_DOMAIN,
                        filters,
                        onChangeCallback: handleSearchStateChange,
                    }) ||
                    undefined
                }
            />

            <TableContainer>
                <Table size="small">
                    <TableHead columns={COLUMNS} />

                    <TableBody>
                        {isLoading && <TableRowSkeleton rows={3} cells={COLUMNS.length} />}

                        {!isLoading && renderCustomers()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CustomerList;
