import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ApplicationDto, CustomerDto, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { useTranslatedSnackbar } from '@zetadisplay/engage-components/hooks';
import { useDocumentTitle } from '@zetadisplay/zeta-ui-components/hooks';

import { APP_NAME } from '../../../config/contants';
import useCustomerContext from '../../customers/hooks/use-customer-context';
import EngageDependencyInjection from '../../dependency-injection/components/engage-dependency-injection';
import EngageWorkspaceWrapper from '../components/engage-workspace-wrapper/engage-workspace-wrapper';
import CreatePlayerBatch from './create-player-batch';
import EditPlayerBatch from './edit-player-batch';
import usePlayerBatch from './hooks/use-player-batch';

export type PlayerCreationToolOptions = {
    callback?: () => void;
    customer: CustomerDto;
    application?: ApplicationDto;
    playerBatch?: PlayerBatchDto;
    notify: (message: string, params: string[], type: 'error' | 'info' | 'success') => void;
};

/**
 * TODO Improve application validation, it's a bit messy right now
 * @constructor
 */
const PlayerCreationTool = () => {
    useDocumentTitle(APP_NAME, ['Engage', 'Players']);
    const { playerBatchId } = useParams();
    const snackbar = useTranslatedSnackbar();

    const [validApplication, setValidApplication] = useState<ApplicationDto | null>(null);

    const { customer } = useCustomerContext();

    // TODO Refactor - applications from customer context are now always including Engage (among others customer applications)
    useEffect(() => {
        if (validApplication === null) {
            const engageConfiguration = customer.applications?.find(
                (application) => application.externalSystem === ApplicationDto.externalSystem.ENGAGE
            );

            if (engageConfiguration) {
                if (!engageConfiguration.externalEntityId || engageConfiguration.externalEntityId.length === 0) {
                    snackbar('Engage is not correctly configured for this customer', undefined, 'error');
                } else {
                    setValidApplication(engageConfiguration);
                }
            } else {
                snackbar('Engage is not enabled for this customer', undefined, 'error');
            }
        }
    }, [customer.applications, snackbar, validApplication]);

    const playerBatch = usePlayerBatch(customer, validApplication, playerBatchId);

    const applicationIsValid =
        validApplication && validApplication.externalEntityId && validApplication.externalEntityId.length > 0;

    return (
        <Box component="section" data-testid="player-creation-tool">
            {!applicationIsValid && <Typography>Application is not valid</Typography>}

            {applicationIsValid && (
                <EngageDependencyInjection>
                    <EngageWorkspaceWrapper workspaceId={validApplication.externalEntityId as string}>
                        {!playerBatchId && validApplication && (
                            <CreatePlayerBatch application={validApplication} customer={customer} />
                        )}

                        {playerBatch?.result && validApplication && (
                            <EditPlayerBatch
                                application={validApplication}
                                customer={customer}
                                playerBatch={playerBatch.result}
                            />
                        )}
                    </EngageWorkspaceWrapper>
                </EngageDependencyInjection>
            )}
        </Box>
    );
};

export default PlayerCreationTool;
