import React, { useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { UserInformationProvider } from '@zetadisplay/engage-components/modules/auth';
import { UserInformationProviderProps } from '@zetadisplay/engage-components/modules/auth/components/user-information-provider';
import { DependencyInjectionProvider as EngageDependencyInjectionProvider } from '@zetadisplay/engage-components/modules/dependency-injection';

import { ENGAGE_API_PATH } from '../../../config/engage';
import EngageLoader from '../../engage/components/engage-loader/engage-loader';

export type EngageDependencyInjectionProps = UserInformationProviderProps;

const EngageDependencyInjection = ({
    children,
    defaultUser,
    fetchUserAsynchronously,
}: EngageDependencyInjectionProps) => {
    const { authState } = useOktaAuth();

    const renderLoader = useCallback(() => <EngageLoader testId="engage-loader-user-information-provider" />, []);

    return (
        <EngageDependencyInjectionProvider
            accessToken={authState?.accessToken?.accessToken}
            apiBasePath={ENGAGE_API_PATH}
        >
            <UserInformationProvider
                defaultUser={defaultUser}
                fetchUserAsynchronously={fetchUserAsynchronously}
                renderLoader={renderLoader}
            >
                {children}
            </UserInformationProvider>
        </EngageDependencyInjectionProvider>
    );
};

export default EngageDependencyInjection;
