import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { Box } from '@mui/material';
import { DocumentBulletList } from '@styled-icons/fluentui-system-regular/DocumentBulletList';
import { Delete, Edit } from '@styled-icons/material';
import { CustomerDto, PlayerBatchDto } from '@zetadisplay/connect-adminpanel-api-client';
import { ActionGroup, ContextMenu } from '@zetadisplay/zeta-ui-components';

import {
    SOFTWARE_CONFIG_PLAYER_CREATION_PATH,
    SOFTWARE_CONFIGURATION_BASE_PATH,
} from '../../../../../routing/constants';
import useAuthorization from '../../../../authorization/hooks/use-auth';
import DeletePlayerBatchPrompt from '../../../components/prompts/delete-player-batch-prompt';
import { PlayerCreationToolOptions } from '../../player-creation-tool';

export type PlayerBatchListActionsProps = {
    customer: CustomerDto;
    options: PlayerCreationToolOptions;
    playerBatch: PlayerBatchDto;
};

const PlayerBatchListActions = ({ customer, options, playerBatch }: PlayerBatchListActionsProps) => {
    const context = useAuthorization();
    const history = useNavigate();

    const canEdit = context.can('update', 'PlayerBatch-Engage', customer.id);
    const canDelete = context.can('delete', 'PlayerBatch-Engage', customer.id);

    const editAllowed =
        [PlayerBatchDto.status.DRAFT, PlayerBatchDto.status.FAILED].includes(playerBatch.status) && canEdit;
    const deleteAllowed = [PlayerBatchDto.status.DRAFT].includes(playerBatch.status) && canDelete;

    const actions: ActionGroup<PlayerBatchDto>[] = useMemo(() => {
        return [
            {
                actions: [
                    {
                        handler: () =>
                            history(
                                `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}/${playerBatch.id}/report`
                            ),
                        icon: DocumentBulletList,
                        isDisabled: () => playerBatch.status !== PlayerBatchDto.status.EXECUTED,
                        label: 'Report',
                        name: 'player-batch-report',
                    },
                    {
                        handler: () =>
                            history(
                                `/${SOFTWARE_CONFIGURATION_BASE_PATH}/engage/${SOFTWARE_CONFIG_PLAYER_CREATION_PATH}/${playerBatch.id}/edit`
                            ),
                        icon: Edit,
                        isDisabled: () => !editAllowed,
                        label: 'Edit',
                        name: 'edit-player-batch',
                    },
                    {
                        handler: () =>
                            NiceModal.show(DeletePlayerBatchPrompt, {
                                options,
                                playerBatch,
                            }),
                        icon: Delete,
                        isDisabled: () => !deleteAllowed,
                        label: 'Delete',
                        name: 'delete-player-batch',
                    },
                ],
                name: 'player-batch-actions',
            },
        ];
    }, [deleteAllowed, editAllowed, history, options, playerBatch]);

    return (
        <Box component="div" sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center' }}>
            <ContextMenu<PlayerBatchDto> item={playerBatch} actions={actions} placement="bottom" />
        </Box>
    );
};

export default PlayerBatchListActions;
