import { useMemo } from 'react';
import { Box } from '@mui/material';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { makeStyles } from '@zetadisplay/zeta-ui-components/utils/theme';

import { Application, APPLICATIONS } from '../../../applications/application';
import ApplicationIcon from '../../../applications/application-icon/application-icon';

const useStyles = makeStyles()(() => ({
    root: {
        display: 'flex',
    },
    icon: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: '50%',
        display: 'flex',
        height: 26,
        marginLeft: 0,
        marginRight: 6,
        padding: 6,
        width: 26,
    },
}));

export type UserApplicationsProps = {
    user: UserDto;
};

const UserApplications = ({ user }: UserApplicationsProps) => {
    const { classes } = useStyles();

    /**
     * Iterate over each customer relation and find unique applications for showing icons.
     */
    const applications = useMemo(() => {
        const data: Record<string, Application> = {};

        user.customers.forEach((userCustomer) => {
            userCustomer.applications?.forEach((aac) => {
                const application = APPLICATIONS.find((appConfig) => appConfig.id === aac.application.externalSystem);
                if (application) {
                    data[application.id] = application;
                }
            });
        });

        return data;
    }, [user]);

    return (
        <Box component="div" className={classes.root} data-testid="user-applications">
            {Object.values(applications).map((application) => (
                <div key={application.id} className={classes.icon} title={application.name}>
                    <ApplicationIcon application={application} size={20} />
                </div>
            ))}
        </Box>
    );
};

export default UserApplications;
