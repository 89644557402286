import { useMemo } from 'react';
import { Control, Controller, UseFieldArrayRemove, UseFieldArrayUpdate } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Box, Grid } from '@mui/material';
import {
    CreateCustomerUserDto,
    CustomerDto,
    EditCustomerUserDto,
    UserDto,
} from '@zetadisplay/connect-adminpanel-api-client';
import { FormProviderWrapper } from '@zetadisplay/engage-components/layouts/components';
import { Accordion } from '@zetadisplay/zeta-ui-components';

import PLACEHOLDER_CUSTOMER from '../../customers';
import CustomerInput from '../../customers/components/customer-input/customer-input';
import { CREATE_USER_FORM_VALUES } from './create-user';
import { EDIT_USER_FORM_VALUES } from './edit-user';
import UserApplications from './user-applications';
import UserCustomerHeader from './user-customer-header';

export type UserCustomerProps<T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES> = {
    control?: Control<T>;
    customerUsers?: CreateCustomerUserDto[] | EditCustomerUserDto[];
    customers: CustomerDto[];
    index: number;
    remove: UseFieldArrayRemove;
    setValue: UseFormSetValue<CREATE_USER_FORM_VALUES> | UseFormSetValue<EDIT_USER_FORM_VALUES>;
    update:
        | UseFieldArrayUpdate<CREATE_USER_FORM_VALUES, 'user.customerUsers'>
        | UseFieldArrayUpdate<EDIT_USER_FORM_VALUES, 'user.customerUsers'>;
    user?: UserDto;
};

const UserCustomer = <T extends CREATE_USER_FORM_VALUES | EDIT_USER_FORM_VALUES>({
    control,
    customerUsers = [],
    customers,
    index,
    remove,
    setValue,
    update,
    user,
}: UserCustomerProps<T>) => {
    const customerUser = customerUsers[index];

    const customerOptions = useMemo(() => {
        return customers.filter((customer) => customer.id === customerUser.customer.id || customer.deletedAt === null);
    }, [customerUser.customer.id, customers]);

    return (
        <Accordion
            defaultExpanded
            name={`user-customer-${index}`}
            renderSummary={() => (
                <UserCustomerHeader
                    customer={customerUser.customer}
                    customers={customers}
                    index={index}
                    remove={remove}
                />
            )}
        >
            <Box component="div" sx={{ padding: '6px 24px 0 6px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore TODO check form typings
                            name={`user.customerUsers.${index}.customer`}
                            render={({ field: { onChange } }) => {
                                return (
                                    <FormProviderWrapper>
                                        <CustomerInput
                                            defaultValue={customerUser.customer}
                                            fullWidth
                                            loading={false}
                                            name={`customer-select-${index}`}
                                            onChangeCallback={(customer) => {
                                                onChange(customer);
                                                update(index, {
                                                    ...customerUser,
                                                    customer: customer || PLACEHOLDER_CUSTOMER,
                                                });
                                            }}
                                            options={customerOptions}
                                            required
                                        />
                                    </FormProviderWrapper>
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <UserApplications
                            customers={customers}
                            customerUsers={customerUsers}
                            index={index}
                            setValue={setValue}
                            user={user}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Accordion>
    );
};

export default UserCustomer;
