import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { Image } from '@zetadisplay/zeta-ui-components';

import image from './assets/connection-error.jpg';

const InternalServerError = () => {
    return (
        <Box sx={{ mt: 14 }}>
            <Box sx={{ margin: '0 auto', width: '600px' }}>
                <Typography variant="h1" sx={{ mb: 2 }}>
                    Connection error
                </Typography>

                <Typography sx={{ mb: 3 }}>
                    AdminPanel is facing some technical challenges, issue has been automatically reported for further
                    investigation. Please try again later.
                </Typography>

                <Link href="/" to="/" component={RouterLink} sx={{ color: '#333', textDecoration: 'underline' }}>
                    Go to Dashboard
                </Link>
            </Box>

            <Box sx={{ margin: '0 auto', width: '600px' }}>
                <Image alt="Internal server error" src={image} />
            </Box>
        </Box>
    );
};

export default InternalServerError;
