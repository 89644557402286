import React from 'react';
import { UserDto } from '@zetadisplay/connect-adminpanel-api-client';
import { Loader } from '@zetadisplay/zeta-ui-components';

import useCurrentUser from '../../users/hooks/use-current-user';
import { AbilityProvider } from './ability-provider';
import AuthorizationContext from './authorization-context';

export type AuthorizationProviderProps = React.PropsWithChildren<{
    defaultUser?: UserDto;
}>;

export const AuthorizationProvider = ({ children, defaultUser }: AuthorizationProviderProps) => {
    const { result: currentUser } = useCurrentUser(defaultUser);

    if (!currentUser) {
        return <Loader />;
    }

    return (
        <AuthorizationContext.Provider value={currentUser}>
            <AbilityProvider currentUser={currentUser}>{children}</AbilityProvider>
        </AuthorizationContext.Provider>
    );
};
